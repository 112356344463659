import { SystemLoadcase } from '../../cae-model/system-loadcase';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';

export class SystemLoadcaseMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_SYSTEM_LOADCASE'];
    }

    protected createModelElement(): ModelElement {
        return new SystemLoadcase();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'deadWeight', right: 'IDSLC_CALCULATION_WITH_OWN_WEIGHT' },
            { left: 'acceleration', right: 'IDSLC_ACCELERATION' },
            { left: 'epsx', right: 'IDSLC_EPSILON_X' },
            { left: 'epsy', right: 'IDSLC_EPSILON_Y' },
            { left: 'epsz', right: 'IDSLC_EPSILON_Z' },
        ];
        this.addBinder(binder);
    }
}
