// flag showing that this element shouldn't be scaled (e.g. simmetry line, coordinate systems)
export const FIXED_SCALE_ATTRIBUTE = 'fixedScale';
export const FIXED_SCALE_VALUE_ATTRIBUTE = 'fixedScaleValue';
export const FIXED_SCALED_VALUE_ZOOM_TO_FIT = 'fixedScaleValueZoomToFit';
export const DEFAULT_FIXED_SCALE_VALUE = 1;
export const FIXED_SCALE_VALUE = 1.5;

// flag showing that this element shouldn't be considered by the calculation of
// bounding box of stage (needed for scale for fit of the model)
export const NOT_IN_STAGE_BOUNDING_BOX = 'notInStageBoundingBox';
export const BEARING_VIEW_2D_GROUP = 'bearing-view-2d-group';

export const TINY_DISTANCE = 1e-8;

export const Y_2D_VIEW = 100;
export const SEGMENT_COLOR_2D = '#a0a0a0';
export const BACKGROUND_COLOR_2D = '#f6f7f8';
export const STROKE_COLOR_2D = 'black';
export const STROKE_WIDTH = 1;
export const STROKE_WIDTH_COORDINATE_2D = 1;
export const SCALE_SPEED = 1.1;
export const LINE_CAP_2D = 'square';
export const LINE_JOIN_2D = 'bevel';
export const CIRCLE_NAME = 'circle';
export const CIRCLE_RADIUS_COORDINATE_2D = 6;
export const OPACITY_SHAFT = 1;
export const OPACITY_SHAFT_CS = 1;
export const COORDINATE_SYSTEM_VECTOR_X = 'vector-x';
export const COORDINATE_SYSTEM_VECTOR_Y = 'vector-y';
export const COORDINATE_SYSTEM_VECTOR_Z = 'vector-z';
export const FIRST_CROSS_LINE = 'first-cross';
export const SECOND_CROSS_LINE = 'second-cross';

export const NO_DASH = [0, 0];
export const FONT_FAMILY_2D = 'Roboto';
export const TEXT_FONT_SIZE = 12;
/*
 * line segments with a length of 5px with a gap
 * of 5px followed by a line segment of 1px (a dot)
 * followed by a gap of 5px
 */
export const SYMMETRY_LINE_NAME = 'symmetry-line-group';
export const DASH_STROKE_SYMMETRY_LINE = [5, 5, 1, 5];
export const VECTOR_LENGTH_2D = 30;
export const VECTOR_LENGTH_FORCE_2D = 30;
export const VECTOR_LENGTH_TORQUE_2D = 32.5;
export const TEXT_WIDTH_2D = 16;
export const TEXT_HEIGHT_2D = 16;
export const SYMMETRY_LINE_LENGTH = 1e6;
export const ARROW_NAME = 'arrow';
export const ARROW_BODY_NAME = 'arrow-body';
export const ARROW_HEAD_NAME = 'arrow-head';
export const ARROW_HEAD_LENGTH_2D = 6;
export const ARROW_HEAD_WIDTH_2D = 3;
export const COORDINATE_SYSTEM_NAME = 'coordinate_group';
export const ARROW_DEFAULT_COLOR = '#000000';
export const COORDINATE_SYSTEM_STROKE_COLOR_2D = 'black';
export const OPACITY_SHAFT_SYSTEM_CS = 0.91;
export const VIEW_WITHOUT_CHILDREN_SUFFIX = ':viewWithoutChildren';
export const ANCHOR_SIZE_2D = 2;
export const ANCHOR_COLOR = 'black';
export const DASH_STROKE_TRANSFORMER = [1, 5, 1, 1];
export const DASH_STROKE_TRANSLATIONAL_TRANSFORMER = [3];
export const TRANSFORMER_GROUP_NAME = 'transformer-group-name';
export const TRANSFORMER_BOUNDING = 'transformer_bounding';
export const LOAD_STROKE_COLOR_2D = '#eb0000';
export const LOAD_FILL_COLOR_2D = '#eb0000';
export const UNBALANCE_CIRCLE_RADIUS = 5; // OutOfBalanceCircleRadius in bearinx

export const STAGE_BORDER = 10;

/*
 * line segments with a length of 1.5px with a gap
 * of 1.5px followed by a line segment of 1.5px (a dot)
 * followed by a gap of 1.5px
 */
export const DASH_STROKE_POINT_LOAD = [1.5 * 3, 1.5 * 3, 1.5 * 3, 1.5 * 3];
export const DOT_RADIUS = 1;

export const CLICK_DISPLACEMENT = 1;

// MOCK-UP spring view
export const SPRING_STROKE = '#66ff00';
export const CENTER_SPRING_HEIGHT = 40;
export const UP_DOWN_SPRING_HEIGHT = 15;
export const SPRING_WIDTH = 15;
export const CURVE_HEIGHT = 7;
export const SPRING_TOTAL_COILS = 7;

// Bearing view
export const BEARING_STROKE_WIDTH_DEFAULT = 1;
export const PRELOAD_SPRING_NAME = 'preloadSpringName';

// ********************************* Colors *********************************
export const DISPLAYGREEN = 'green';
export const DISPLAYBRIGHTGREEN = '#66ff00';
export const DISPLAYRED = 'red';
export const DISPLAYBLACK = 'black';

// Rigid view
export const RIGID_STROKE = '#179f20';

// Notches  view
export const DASH_STROKE_NOTCH = [1.5, 1.5, 1.5, 1.5];

// Transmission element view
export const DASH_STROKE_TRANSMISSION_ELEMENT = [5, 5, 1, 5];

// Transverse bore view
export const DASH_STROKE_TRANSVERSE_BORE = [1, 1, 1, 1];
export const DASH_STROKE_TRANSVERSE_BORE_CENTER = [3, 2, 1, 2];

export const TRANSPARENT = 'transparent';

// flag showing that this element is an element in transformer.
export const CUSTOM_TRANSFORMER_ATTRIBUTE = 'custom-transformer-attribute';

// Ruler
export const RULER_GROUP_NAME = 'ruler-group';
export const RULER_HEIGHT = 20;
export const RULER_BACKGROUND_COLOR = '#f0f0f0';
export const RULER_STROKE_COLOR = 'black';
export const RULER_STROKE_WIDTH = 1;
export const RULER_TRIANGLE_COLOR = 'black';
export const RULER_BACKGROUND_NAME = 'ruler-backgroud-name';
export const RULER_MARKER_X = 'marker-ruler-x';
export const RULER_MARKER_Y = 'marker-ruler-y';
export const RULER_UNIT_X = 'unit-ruler-x';
export const RULER_UNIT_Y = 'unit-ruler-y';
export const RULER_HORIZONTAL_TRIANGLE_NAME = 'ruler-horizontal-triangle';
export const RULER_VERTICAL_TRIANGLE_NAME = 'ruler-vertical-triangle';

// Container class name
export const CONTAINER_CLASS_NAME = '.container2D';

// Zoom to fit
export const ZOOM_OFFSET = 0.8;
export const RULER_REFERENCE_STEPS = [1, 2, 3, 4, 5, 8];

// Dimensions
export const DIMENSIONS_WRAPPER_NAME = 'dimensions-wrapper';
export const DIMENSION_TEXT_COLOR = 'black';
export const DIMENSIONS_LINE_GAP = 20;
export const HORIZONTAL_DIMENSION_LINE_NAME = 'horizontal-dimension-line';
export const VERTICAL_LEFT_DIMENSION = 'vertical-left-dimension';
export const VERTICAL_RIGHT_DIMENSION = 'vertical-right-dimension';
export const ARROW_GAP = 5;
export const COMMON_PROPERTIES = {
    stroke: STROKE_COLOR_2D,
    strokeWidth: STROKE_WIDTH,
    strokeScaleEnabled: false,
};
export const ARROW_COMMON_PROPERTIES = {
    fill: STROKE_COLOR_2D,
    pointerLength: ARROW_HEAD_LENGTH_2D,
    pointerWidth: ARROW_HEAD_WIDTH_2D,
    pointerAtBeginning: true,
};
export const HORIZONTAL_DIMENSIONS_GROUP_NAME = 'horizontal-dimensions-group';
export const VERTICAL_DIMENSIONS_GROUP_NAME = 'vertical-dimensions-group';
export const LINE_CLASS_NAME = 'Line';
export const ARROW_CLASS_NAME = 'Arrow';
export const TEXT_CLASS_NAME = 'Text';
export const SI_UNIT_FRACTION_DIGITS = 3;
export const FPS_UNIT_FRACTION_DIGITS = 5;

// Bounding box
export const BOUNDING_BOX = 'bounding-box';

// Distributed Load
export const DISTRIBUTED_LOAD_VIEW_2D_NAME = 'distributed-load-view-2d-group';
export const DISTRIBUTED_LOAD_FORCE_NAME = 'distributed_load_force_group';
export const DISTRIBUTED_LOAD_TORQUE_NAME = 'distributed_load_torque_group';
export const CONNECT_LINE_NAME = 'connect-line';

// Point Load
export const POINT_LOAD_FORCE_NAME = 'point_load_force_group';
export const POINT_LOAD_TORQUE_NAME = 'point_load_torque_group';

// Circle Drawing
export const AXIS_DOT_NAME = 'axis-dot';
export const CIRCLE_DOT_NAME = 'circle-dot-group';
export const CIRCLE_TIMES_NAME = 'circle-times-group';
export const TORQUE_CIRCLE_DOT_NAME = 'torque-circle-dot-group';
export const TORQUE_CIRCLE_TIMES_NAME = 'torque-circle-times-group';

// Chart
export const CHART_MARGIN = 200;

// Unbalance Min radial displacement
export const UNBALANCE_MIN_RADIAL_DISP = 5;
