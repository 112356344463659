import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { BearinxModule } from './app/bearinx/bearinx.module';
import { AppInitializationError } from './modules/auth/auth.module';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(BearinxModule)
    .catch(err => {
        if (err instanceof AppInitializationError) {
            const msgElement = document.querySelector('#error-message');
            if (msgElement) {
                msgElement.innerHTML = err.message;
            }
            document.querySelector('#error-display')?.classList.remove('hide');
        }
    });
