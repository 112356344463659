import { Observable } from 'rxjs';
import { Inject, InjectionToken } from '@angular/core';
import { ExportOptions } from './export.options';

export const DATA_PROVIDERS = new InjectionToken<DataProvider[]>('DATA_PROVIDERS');

export interface DataProvider {
    outputFormat: string;
    inputFormats: string[];

    /**
     * Converts the given data to the target format
     * @param input Input data to be converted
     * @param options Optional options for converting
     * @returns Output data or null if not supported
     */
    convert(input: any, options?: ExportOptions): Observable<any>;
}
