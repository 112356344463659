import { Notch } from './notch';

// KeySeat class, analog to KeySeatData.
// (in java version it's always 0)
export class KeySeat extends Notch {
    width = 0;
    height = 0;
    depth = 0;

    protected dependsOnSegmentSide(): boolean {
        return false;
    }
}
