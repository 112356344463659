import { RadialRoller } from '../../../../cae-model/supports/bearings/radial-roller';
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { BearingView2D } from './bearing-view-2d';
import { WMFImage } from './wmf-images/wmf-image';
import { WMF_RadialRollerBearing } from './wmf-images/wmf-radial-roller-bearing';

export class RadialRollerView2D extends BearingView2D {
    constructor(radialRoller: RadialRoller) {
        super(radialRoller);
    }

    private get _radialRoller(): RadialRoller {
        return this.modelElement as RadialRoller;
    }

    // Create image manually. Overrides basic property.
    protected createWMFImage(_geometry: SupportGeometry): WMFImage {
        const image = new WMF_RadialRollerBearing();
        image.shoulderArrangement_ = this._radialRoller.shoulderArrangement;
        image.numberOfRows_ = this._radialRoller.nbRows;
        return image;
    }
}
