export enum Roles {
    DescriptionsRead = 'descriptions:read',
    DialogDescriptionsRead = 'dialogdescriptions:read',
    ModelCalculate = 'model:calculate',
    ModelDelete = 'model:delete',
    ModelExportVg2 = 'model:export:vg2',
    ModelExportVg2json = 'model:export:vg2json',
    ModelList = 'model:list',
    ModelRead = 'model:read',
    ModelWrite = 'model:write',
    ProductsRead = 'products:read',
    ProjectDelete = 'project:delete',
    ProjectList = 'project:list',
    ProjectRead = 'project:read',
    ProjectWrite = 'project:write',
    ProjectMove = 'project:move',
}
