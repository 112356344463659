import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { ErrorHandlerBaseService, ErrorMessagesService, HttpStatusCode } from '../../error-handling';

@Injectable({
    providedIn: 'root',
})
export class SettingsErrorHandlerService extends ErrorHandlerBaseService {
    constructor(private readonly _errorMessagesService: ErrorMessagesService) {
        super();
    }

    protected _handleServerSideError(error: HttpErrorResponse): Observable<any> {
        switch (error.status) {
            case HttpStatusCode.MethodNotAllowed:
            case HttpStatusCode.InternalServerError:
                this._errorMessagesService.displayErrorMessage('ERRORS.APP_SETTINGS_500');
                return of({});

            case HttpStatusCode.Unauthorized:
            case HttpStatusCode.NotFound:
                return of({});
        }
        return throwError(error);
    }
}
