import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { BasicMediator } from '../basic-mediator';

// mediator, used for the convergence of notches (analog to NotchData in bearinx-online)
export abstract class NotchMediator extends BasicMediator {
    constructor() {
        super();
        this._createNotchBinder();
    }

    private _createNotchBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'xInShaftCS', right: 'IDCO_X' }];
        this.addBinder(binder);
    }
}
