import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TitleBarModule } from '../title-bar/title-bar.module';
import { ContentDialogComponent } from './components/content-dialog/content-dialog.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';

@NgModule({
    imports: [CommonModule, TranslateModule, OverlayModule, TitleBarModule],
    declarations: [ModalContainerComponent, ContentDialogComponent],
})
export class BearinxModalModule {}
