import Konva from 'konva';
import {
    BOUNDING_BOX,
    LOAD_FILL_COLOR_2D,
    LOAD_STROKE_COLOR_2D,
    STROKE_WIDTH,
    TRANSPARENT,
    UNBALANCE_CIRCLE_RADIUS,
} from '../view-2d-constants';
import { ElementView2D } from '../element-view-2d';
import { createLoadDashHalfPart } from '../../functions/loads-utils';
import { Unbalance } from '../../../cae-model/loads/unbalance';
import { getDistalCircleYCoordinate } from '../../functions/unbalance-utils';

export class UnbalanceView2D extends ElementView2D {
    private _group: Konva.Group;

    constructor(private _unbalance: Unbalance) {
        super(_unbalance);
    }

    protected get draggable() {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    private _createCircle(): Konva.Circle {
        const circle = new Konva.Circle();
        circle.fill(LOAD_FILL_COLOR_2D);
        circle.strokeWidth(STROKE_WIDTH);
        circle.strokeScaleEnabled(false);
        circle.radius(UNBALANCE_CIRCLE_RADIUS);
        return circle;
    }

    private _createDistalCircle(xCoord: number, yCoord: number): Konva.Circle {
        const distalCircle = this._createCircle();
        distalCircle.y(yCoord);
        distalCircle.x(xCoord);
        return distalCircle;
    }

    private _createConnectingLine(points: number[]): Konva.Line {
        const output = new Konva.Line();
        output.points(points);
        output.strokeWidth(STROKE_WIDTH);
        output.stroke(LOAD_STROKE_COLOR_2D);
        output.strokeScaleEnabled(true);
        output.opacity(1);
        return output;
    }

    private _createDashHalfLine(unbalance: Unbalance, isUpper: boolean): Konva.Line {
        const currentX = unbalance.x;
        const currentRadius = unbalance.getCurrentDiameter(currentX) / 2;
        return createLoadDashHalfPart(currentRadius, isUpper);
    }

    private _createShapes(unbalance: Unbalance): Konva.Group {
        const group = new Konva.Group({
            name: 'unbalance-view-2d-group',
            transformable: true,
        });

        const distalCircleYCoor = getDistalCircleYCoordinate(unbalance);

        const dashHalfLine = this._createDashHalfLine(unbalance, distalCircleYCoor > 0);
        group.add(dashHalfLine);

        const proximalCirlce = this._createCircle();
        group.add(proximalCirlce);
        const distalCircle = this._createDistalCircle(unbalance.axialMissalignment, distalCircleYCoor);
        group.add(distalCircle);

        const connectingLine = this._createConnectingLine([proximalCirlce.x(), proximalCirlce.y(), distalCircle.x(), distalCircle.y()]);
        group.add(connectingLine);

        return group;
    }

    protected updateGroupWithoutChildren(): void {
        // override basis class

        this._group = this._createShapes(this._unbalance);

        const boundingRectForUTSelection = new Konva.Rect({
            ...this._group.getClientRect({
                skipStroke: true,
            }),
            fill: TRANSPARENT,
            name: BOUNDING_BOX,
        });
        this._group.add(boundingRectForUTSelection);

        this.groupWithoutChildren.add(this._group);
    }
}
