import { NgModule } from '@angular/core';
import { StageScalerService } from './services/stage-scaler.service';
import { View2DComponent } from './view2D.component';
import { View2dService } from './services/view2d.service';
import { View2DFactoryService } from './services/view2DFactory.service';
import { ModelElementFinder2DService } from './services/model-element-finder2D.service';
import { StaticFileLoadersService } from './services/static-file-loaders.service';
import { ZoomToFitService } from './services/zoom-to-fit-service';
import { MousePositionService } from '../views-foundation/services/mouse-position.service';
import { MousePositionComponent } from './mouse-position/mouse-position.component';
import { CommonModule } from '@angular/common';
import { RulerService } from './services/ruler-service';
import { DimensionVisualizerService } from './dimensions/dimensions-visualizer.service';
import { View2DSettingsService } from './services/view-2d-settings.service';

@NgModule({
    declarations: [View2DComponent, MousePositionComponent],
    exports: [View2DComponent],
    imports: [CommonModule],
    providers: [
        StageScalerService,
        View2DFactoryService,
        View2dService,
        ModelElementFinder2DService,
        StaticFileLoadersService,
        ZoomToFitService,
        MousePositionService,
        RulerService,
        DimensionVisualizerService,
        View2DSettingsService,
    ],
})
export class View2DModule {}
