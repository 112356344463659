/* eslint-disable no-shadow */
import Konva from 'konva';
import { ANCHOR_SIZE_2D, TRANSFORMER_BOUNDING } from '../elements-view/view-2d-constants';
import { ANCHORS_NAMES, TransformerBase } from './transformer-base';

export interface AnchorHeightPosition {
    topLeft: number;
    topCenter: number;
    topRight: number;
    middleRight: number;
    middleLeft: number;
    bottomLeft: number;
    bottomCenter: number;
    bottomRight: number;
}
export class ConeTransformer extends TransformerBase {
    constructor(private _line: Konva.Line, private _heightLeft: number, private _heightRight: number, _parent: Konva.Group) {
        super(_line, _parent);
    }

    protected updateElements() {
        const width = this._line.width();
        const { topLeft, topCenter, topRight, middleRight, middleLeft, bottomLeft, bottomCenter, bottomRight } = this._getHeightPosition();

        const resizeEnabled = true;
        const padding = ANCHOR_SIZE_2D / 2;
        const anchorSize = ANCHOR_SIZE_2D;

        this.batchChangeChild(`.${ANCHORS_NAMES.top_left}`, {
            x: -width / 2,
            y: topLeft,
            offsetX: anchorSize / 2 + padding,
            offsetY: anchorSize / 2 + padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.top_center}`, {
            x: 0,
            y: topCenter,
            offsetX: anchorSize / 2,
            offsetY: anchorSize / 2 + padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.top_right}`, {
            x: width / 2,
            y: topRight,
            offsetX: anchorSize / 2 - padding,
            offsetY: anchorSize / 2 + padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.middle_left}`, {
            x: -width / 2,
            y: middleLeft,
            offsetX: anchorSize / 2 + padding,
            offsetY: anchorSize / 2,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.middle_right}`, {
            x: width / 2,
            y: middleRight,
            offsetX: anchorSize / 2 - padding,
            offsetY: anchorSize / 2,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.bottom_left}`, {
            x: -width / 2,
            y: bottomLeft,
            offsetX: anchorSize / 2 + padding,
            offsetY: anchorSize / 2 - padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.bottom_center}`, {
            x: 0,
            y: bottomCenter,
            offsetX: anchorSize / 2,
            offsetY: anchorSize / 2 - padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${ANCHORS_NAMES.bottom_right}`, {
            x: width / 2,
            y: bottomRight,
            offsetX: anchorSize / 2 - padding,
            offsetY: anchorSize / 2 - padding,
            visible: resizeEnabled,
        });
        this.batchChangeChild(`.${TRANSFORMER_BOUNDING}`, {
            sceneFunc: (
                context: {
                    beginPath: () => void;
                    moveTo: (arg0: number, arg1: number) => void;
                    lineTo: (arg0: number, arg1: number) => void;
                    closePath: () => void;
                    fillStrokeShape: (arg0: any) => void;
                },
                shape: any,
            ) => {
                context.beginPath();

                context.moveTo(-width / 2, topLeft);
                context.lineTo(width / 2, topRight);
                context.lineTo(width / 2, bottomRight);
                context.lineTo(-width / 2, bottomLeft);

                context.closePath();

                context.fillStrokeShape(shape);
            },
            visible: resizeEnabled,
        });
    }

    private _getHeightPosition(): AnchorHeightPosition {
        const isHeightLeftBigerThanHeightRight = this._heightLeft > this._heightRight ? true : false;
        const offsetHeight = isHeightLeftBigerThanHeightRight
            ? (this._heightLeft - this._heightRight) / 2
            : (this._heightRight - this._heightLeft) / 2;

        return {
            topLeft: isHeightLeftBigerThanHeightRight ? -this._heightLeft : -offsetHeight - this._heightLeft,
            topCenter: isHeightLeftBigerThanHeightRight ? -this._heightLeft + offsetHeight / 2 : -this._heightRight + offsetHeight / 2,
            topRight: isHeightLeftBigerThanHeightRight ? -offsetHeight - this._heightRight : -this._heightRight,
            middleRight: isHeightLeftBigerThanHeightRight ? -offsetHeight - this._heightRight / 2 : -this._heightRight / 2,
            middleLeft: isHeightLeftBigerThanHeightRight ? -this._heightLeft / 2 : -offsetHeight - this._heightLeft / 2,
            bottomLeft: isHeightLeftBigerThanHeightRight ? 0 : -offsetHeight,
            bottomCenter: -offsetHeight / 2,
            bottomRight: isHeightLeftBigerThanHeightRight ? -offsetHeight : 0,
        };
    }
}
