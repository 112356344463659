import { BACKGROUND_COLOR_2D } from '../../../view-2d-constants';
import Konva from 'konva';

export class GraphicsContext {
    private _x0 = 0;
    private _y0 = 0;
    private _xCurrent = 0;
    private _yCurrent = 0;
    private _group: Konva.Group;

    constructor() {
        this._group = new Konva.Group();
    }

    get group(): Konva.Group {
        return this._group;
    }

    moveTo(x: number, y: number) {
        this._xCurrent = this._x0 + x;
        this._yCurrent = this._y0 + y;
    }

    lineTo(x: number, y: number) {
        const xTemp = this._xCurrent;
        const yTemp = this._yCurrent;

        this.moveTo(x, y);
        const line = new Konva.Line({
            points: [xTemp, yTemp, this._xCurrent, this._yCurrent],
        });

        this._group.add(line);
    }

    ellipse(x: number, y: number, width: number, height: number) {
        y -= height / 2;
        x += width / 2;

        const ellipse = new Konva.Ellipse();
        ellipse.radius({
            x: width / 2,
            y: height / 2,
        });
        ellipse.x(this._x0 + x);
        ellipse.y(this._y0 + y);
        ellipse.fill(BACKGROUND_COLOR_2D);
        this._group.add(ellipse);
    }

    arc(x: number, y: number, width: number, height: number, start: number, extent: number) {
        const arc = new Konva.Arc();
        const arcRadius: number = Math.max(width, height) / 2;
        arc.outerRadius(arcRadius);
        arc.innerRadius(arcRadius);
        arc.angle(extent);
        arc.rotation(-start - extent);

        const arcGroup = new Konva.Group();
        arcGroup.x(x + width / 2);
        arcGroup.y(y + height / 2);
        arcGroup.add(arc);
        if (height < width) {
            arcGroup.scaleY(height / width);
        } else if (height > width) {
            arcGroup.scaleX(width / height);
        }
        this._group.add(arcGroup);
    }
}
