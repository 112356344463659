import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    // TODO: implement logging to the server
    log(message: string) {
        console.log('Log to the server: ', message);
    }
}
