<div class="bx__notification-area" *ngIf="show">
    <header>
        <h1 translate>SHARED.NOTIFICATIONS.TITLE</h1>
        <button class="hide all" (click)="hideAll()" translate>SHARED.NOTIFICATIONS.HIDE_ALL</button>
    </header>

    <section class="bx__notification-group" *ngIf="import.length > 0">
        <header>
            <h1>Imports</h1>
            <button class="hide" (click)="hideImports()" translate>SHARED.NOTIFICATIONS.HIDE</button>
        </header>
        <bx-notification
            *ngFor="let notification of import"
            [notification]="notification"
            (action)="onAction(notification, $event)"
        ></bx-notification>
    </section>

    <section class="bx__notification-group" *ngIf="export.length > 0">
        <header>
            <h1>Exports</h1>
            <button class="hide" (click)="hideExports()" translate>SHARED.NOTIFICATIONS.HIDE</button>
        </header>
        <bx-notification
            *ngFor="let notification of export"
            [notification]="notification"
            (action)="onAction(notification, $event)"
        ></bx-notification>
    </section>

    <section class="bx__notification-group" *ngIf="system.length > 0">
        <header>
            <h1>System</h1>
            <button class="hide" (click)="hideSystem()" translate>SHARED.NOTIFICATIONS.HIDE</button>
        </header>
        <bx-notification
            *ngFor="let notification of system"
            [notification]="notification"
            (action)="onAction(notification, $event)"
        ></bx-notification>
    </section>
</div>
