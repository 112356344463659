import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { Model } from '../model.model';
import { ModelObject } from '../model-object.model';
import { DataProviderService } from './data.provider.service';
import { BaseExporter } from './base.exporter';
import { ExportConstants } from './export.constants';
import { ExportOptions } from './export.options';
import { ExportDataType } from './data-types/export.data.type';
import { ExportData } from './data-types/export.data';

@Injectable({
    providedIn: 'root',
    deps: [DataProviderService],
})
export class Vg2JsonModelExporter extends BaseExporter {
    /**
     *  C'tor
     * @param dataProviderService DataProviderService instance
     * @param datePipe Date pipe instance
     */
    constructor(dataProviderService: DataProviderService, datePipe: DatePipe) {
        super(dataProviderService, datePipe, ExportConstants.vg2Json, Vg2JsonModelExporter._createExportOptions());
    }

    private static _createExportOptions(): ExportOptions[] {
        return <ExportOptions[]>[];
    }

    /**
     * Returns the default extension of the uncompressed export file
     * @returns Default extension without dot
     */
    protected getExtension(): string {
        return 'vg2.json';
    }

    /**
     * Creates the export data from given data
     * @param data Data to be exported
     * @returns A single data object or a list of data objects with name to be stored in a ZIP file
     */
    protected createExportData(data: any): Observable<ExportDataType> {
        return new Observable<any>(observer => {
            const model = <Model<ModelObject>>data;
            const vg2Json = ['id', 'name', 'project', 'method'];
            if (model && vg2Json.every(attr => model.hasOwnProperty(attr))) {
                observer.next(<ExportData>{
                    fileName: model.name,
                    data: JSON.stringify(model, null, 2),
                    mimeType: 'application/json',
                });
                observer.complete();
            }

            // TODO: User error message
            observer.error('No data provided!');
            observer.complete();
        });
    }
}
