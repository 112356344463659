<bx-input-container #anchor [class.display]="display" [class.fill]="fill" [bxTitle]="selectedCaption" [referenceElement]="input">
    <input
        bxInput
        #input
        type="text"
        class="not-rounded"
        [class.filterable]="!readonly"
        [class.not-rounded]="notRounded"
        [class.no-background]="noBackground"
        [placeholder]="placeholder"
        (focus)="onFocus()"
        (blur)="onBlur(true)"
        (keydown.Tab)="onBlur()"
        (keydown.shift.Tab)="onBlur()"
        (input)="onInput($event.target.value)"
        [value]="selectedCaption"
        [readonly]="readonly"
        [disabled]="isDisabled"
        (keydown.arrowup)="onArrowUp()"
        (keydown.arrowdown)="onArrowDown()"
        (keydown.enter)="onEnter()"
    />
    <bx-input-addons (click)="input.focus()">
        <bx-icon icon="arrow_drop_down"></bx-icon>
    </bx-input-addons>
</bx-input-container>

<div *cdkPortal [@fade]="animationState$ | async" (@fade.done)="fadeDone($event)" class="option-list">
    <div *ngFor="let option of options" class="option" [class.selected]="option.id === selectedId" (click)="onSelect(option)">
        {{ option.caption }}
    </div>
</div>
