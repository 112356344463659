import { ModelElement } from '../../../cae-model/model-element';
import { InternalBevelGear } from '../../../cae-model/transmission-elements/internal-bevel-gear';
import { BasicMediator } from '../basic-mediator';
import { createBevelGearBaseBinder } from './transmission-mediators-utils';
export class InternalBevelGearMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_INTERNAL_BEVEL_GEAR'];
    }

    protected createModelElement(): ModelElement {
        return new InternalBevelGear();
    }

    private _createBinder() {
        const binder = createBevelGearBaseBinder();
        this.addBinder(binder);
    }
}
