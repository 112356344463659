import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Tenant } from '../tenant.model';
import { TenantService } from '../tenant.service';

@Component({
    selector: 'bx-tenant-selector',
    templateUrl: './tenant-selector.component.html',
    styleUrls: ['./tenant-selector.component.scss'],
})
export class TenantSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public disabled = false;
    @Output() public readonly tenantSelected = new EventEmitter<Tenant>();

    public readonly tenantCtrl = new FormControl();
    public readonly tenantItems$ = this._tenantService.tenants$.pipe(
        map(tenants => tenants.map(({ id, name: caption }) => ({ id, caption }))),
    );

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _tenantService: TenantService) {}

    public ngOnInit() {
        this.tenantCtrl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                map(tenantId => this._tenantService.getTenant(tenantId)),
            )
            .subscribe(tenant => {
                this._tenantService.setTenant(tenant);
                this.tenantSelected.emit(tenant);
            });

        this._tenantService.tenant$.pipe(takeUntil(this._destroy$)).subscribe(tenant => {
            this.tenantCtrl.setValue(tenant ? tenant.id : null, { emitEvent: false });
            this.tenantSelected.emit(tenant);
        });
    }

    public ngOnChanges(): void {
        if (this.disabled) {
            this.tenantCtrl.disable();
        } else {
            this.tenantCtrl.enable();
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
