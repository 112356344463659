import { ThrustBall } from '../../../../cae-model/supports/bearings/thrust-ball';
import { BearingMediator } from './bearing-mediator';
import { ModelElement } from '../../../../cae-model/model-element';

// mediator, used for the convergence of ThrustBall
export class ThrustBallMediator extends BearingMediator {
    get bearinxObjectTypes(): string[] {
        return ['IDO_THRUST_BALL_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new ThrustBall();
    }
}
