import { Load } from './load';

export enum UnbalanceType {
    Calculate = 'LB_CALCULATE_VALUE',
    Enter = 'LB_ENTER_VALUE',
}

export class Unbalance extends Load {
    radialMissalignment = 0; // ID_OUT_OF_BALANCE_INPUT_RADIAL_MISALIGNMENT
    axialMissalignment = 0; // // ID_OUT_OF_BALANCE_INPUT_AXIAL_MISALIGNMENT
    startAngleAboutX = 0; // in deg. ID_OUT_OF_BALANCE_INPUT_START_ANGLE epX in bearinx
    unbalanceType = UnbalanceType.Calculate;
}
