import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Group, Scene } from 'three';
import { View3DVisualizationService } from './view-3d-visualization.service';
import { createExportScene, generateCanvasElement } from '../functions/utils-3d';

import 'three/examples/jsm/exporters/GLTFExporter';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { View3DSettings } from '../settings';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { CAEModel } from '../../cae-model/cae-model';
import { View3DFactoryService } from '.';

/**
 *  3D View
 */

@Injectable()
export class GltfExporterService {
    constructor(private _view3DVisualizationService: View3DVisualizationService, private _viewFactory3D: View3DFactoryService) {}

    private _group: Group | null;
    private _canvas: HTMLCanvasElement;

    export(model: CAEModel, view3DSettings: View3DSettings): Observable<string> | null {
        const view = this._viewFactory3D.generateModelView(model, view3DSettings);
        const group = view?.groupWithChildren;
        this._group = group == null ? null : group;
        return this._exportGroup();
    }

    private _exportGroup(): Observable<string> | null {
        if (this._group == null) {
            return null;
        }
        const sceneFull = this._createFullScene();
        const scene = sceneFull ? createExportScene(sceneFull) : null;
        if (scene == null) {
            return null;
        }
        const exporter = new GLTFExporter();
        const gltfStringSubj = new Subject<string>();
        exporter.parse(
            scene,
            gltf => {
                const gltfString = JSON.stringify(gltf);
                document.body.removeChild(this._canvas);
                gltfStringSubj.next(gltfString);
                gltfStringSubj.complete();
            },
            {},
        );
        return gltfStringSubj;
    }

    // create scene as standard
    private _createFullScene(): Scene | null {
        this._view3DVisualizationService.initScene();
        this._canvas = generateCanvasElement(400, 200);
        this._view3DVisualizationService.init(this._canvas);
        this._view3DVisualizationService.setModelGroupToScene(this._group, null, UnitSet.SI);
        return this._view3DVisualizationService.scene;
    }
}
