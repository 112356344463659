<bx-toast
    *ngFor="let toast of toasts$ | async; trackBy: byGroupId"
    [icon]="toast.icon"
    [spinning]="toast.spinning"
    [label]="toast.label | translate"
    [closeIcon]="toast.closeIcon"
    [toastId]="toast.id"
    [groupId]="toast.groupId"
    [times]="toast.times"
    [error]="isErrorToast(toast)"
    (close)="close($event)"
    [link]="toast.link"
    [linkText]="toast.linkText | translate"
></bx-toast>
