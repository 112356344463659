import { BearingElement } from '../../../cae-model/bearing-elements/bearing-element';
import { ElementView3D } from '../element-view-3d';
import { Group } from 'three';
import { SupportGeometry } from '../../../views-foundation/geometries/support-geometry';

export abstract class BearingElementView3D extends ElementView3D {
    constructor(belement: BearingElement) {
        super(belement);
    }

    abstract get hasVisualization(): boolean;

    // function retunst SupportGeometry Object, describing the parent bearing
    getBearingViewGeometryFunc: () => Readonly<SupportGeometry>;

    get selectableGroup(): Group | null {
        return null;
    }
}
