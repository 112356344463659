<h3 class="title">{{ 'BEARINX.ABOUT.VERSIONS.TITLE' | translate }}</h3>

<table cdk-table class="bx__grid" [dataSource]="versions$ | async">
    <ng-container cdkColumnDef="name">
        <th cdk-header-cell *cdkHeaderCellDef>{{ 'BEARINX.ABOUT.VERSIONS.NAME' | translate }}</th>
        <td cdk-cell *cdkCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <ng-container cdkColumnDef="version">
        <th cdk-header-cell *cdkHeaderCellDef>{{ 'BEARINX.ABOUT.VERSIONS.VERSION' | translate }}</th>
        <td cdk-cell *cdkCellDef="let row">{{ row.version }}</td>
    </ng-container>
    <tr cdk-header-row *cdkHeaderRowDef="columns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: columns"></tr>
</table>

<h3 class="third-party">{{ 'BEARINX.ABOUT.THIRD_PARTY_LICENSES.TITLE' | translate }}</h3>

<div class="third-party" *ngIf="thirdPartyLicenses$ | async as thirdPartyLicenses">{{ thirdPartyLicenses }}</div>
