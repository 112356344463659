import { Component } from '@angular/core';
import { ToastService } from '../toast.service';
import { ToastData, ToastIconType } from '../toast.model';

@Component({
    selector: 'bx-toast-container',
    templateUrl: 'toast-container.component.html',
    styleUrls: ['toast-container.component.scss'],
})
export class ToastContainerComponent {
    public readonly toasts$ = this._toastService.groupedToasts$;

    constructor(private readonly _toastService: ToastService) {}

    public isErrorToast(toastData: ToastData) {
        return toastData.icon === ToastIconType.Error;
    }

    public close(itemId: string): void {
        this._toastService.hide(itemId);
    }

    public byGroupId(index: number, toast: ToastData): string | undefined {
        return `${index}.${toast.groupId}`;
    }
}
