import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LockTokenRevocationService {
    private readonly _revoke = new Subject();

    public readonly revoked$ = this._revoke.asObservable();

    public revoke(): void {
        this._revoke.next();
    }
}
