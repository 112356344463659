import { CameraType } from '@caeonline/viewer';
import { ClippingPlaneType } from './clipping-plane-type.model';
import { ExampleSettings } from './example-settings.model';
import { Scene3DSettings } from './scene-3d-settings.model';
import { WarningSettings } from './warning-settings.model';

export interface Settings {
    language: string;
    unitSet: string;
    locale: string;
    warnings: Partial<WarningSettings>;
    scene3d: Partial<Scene3DSettings>;
    examples?: ExampleSettings;
}

export const DEFAULT_SETTINGS_VALUE: Settings = {
    language: 'LANGUAGE_ENGLISH',
    unitSet: 'ID_UNIT_SET_SI',
    locale: 'en-US',
    warnings: {
        delLoadCases: false,
        delShafts: false,
        delSystems: false,
        delLinAxes: false,
    },
    scene3d: {
        rotateAroundSelection: true,
        zoomToMousePosition: false,
        clippingPlane: ClippingPlaneType.XY,
        flipClippingPlane: false,
        textured: false,
        cameraType: CameraType.Perspective,
    },
};
