import { Injectable } from '@angular/core';
import {
    BEARING_VIEW_2D_GROUP,
    CHART_MARGIN,
    COORDINATE_SYSTEM_NAME,
    DIMENSIONS_WRAPPER_NAME,
    FIXED_SCALE_VALUE_ATTRIBUTE,
    RULER_HEIGHT,
    STAGE_BORDER,
    TINY_DISTANCE,
    TRANSFORMER_GROUP_NAME,
    ZOOM_OFFSET,
} from '../elements-view/view-2d-constants';
import { StageScalerService } from './stage-scaler.service';
import { getBoundingBoxSize, getBoundingBoxWithoutName } from '../functions/utils-2d';
import { fixedScaleValueZoomToFit } from '../dimensions/dimensions-utils';
import { View2DSettingsService } from './view-2d-settings.service';
import { View2DSettings } from '../settings/view-2d-settings';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { FPS_UNIT_SCALE, SI_UNIT_SCALE } from '../../views-foundation/views-foundation-constants';
import { Group } from 'konva/lib/Group';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum SceneViewMode {
    TwoD = 'TWO_D',
    ThreeD = 'THREE_D',
    Parallel = 'PARALLEL',
    ViewWindow = 'VIEW_WINDOW',
    TwoDNChart = 'TWO_D_AND_CHART',
    ThreeDNaturalModes = 'THREE_D_NATURAL_MODES',
}

@Injectable()
export class ZoomToFitService {
    private _scale = 1;
    private _settings: View2DSettings;
    private _expectedModelWidth: number;
    private _chartPositionX: number;
    private _sceneViewMode: SceneViewMode;
    private _destroying$: Subject<void> = new Subject();

    constructor(private _stageScaler: StageScalerService, private _view2DSettingsService: View2DSettingsService) {
        this._view2DSettingsService
            .getSettings()
            .pipe(takeUntil(this._destroying$))
            .subscribe(settings => {
                this._settings = settings;
            });
    }

    get scale(): number {
        return this._scale;
    }

    set expectedModelWidth(width: number) {
        this._expectedModelWidth = width;
    }

    get expectedModelWidth(): number {
        return this._expectedModelWidth;
    }

    set sceneViewMode(mode: SceneViewMode) {
        this._sceneViewMode = mode;
    }

    get sceneViewMode(): SceneViewMode {
        return this._sceneViewMode;
    }

    set chartPositionX(x: number) {
        this._chartPositionX = x;
    }

    get chartPositionX(): number {
        return this._chartPositionX;
    }

    zoomToFit(): void {
        const stage = this._stageScaler.stage;
        if (stage === null) {
            return;
        }

        let boundingRect = getBoundingBoxSize(stage);
        if (boundingRect.width < TINY_DISTANCE || boundingRect.height < TINY_DISTANCE) {
            return;
        }
        const scaleWidthInv = boundingRect.width / (stage.width() - STAGE_BORDER * 2 - RULER_HEIGHT);
        const scaleHeightInv = boundingRect.height / (stage.height() - STAGE_BORDER * 2 - RULER_HEIGHT);
        const scaleInv = Math.max(scaleWidthInv, scaleHeightInv);

        this._scale = (1 / scaleInv) * ZOOM_OFFSET;

        const coordinates = stage.find(`.${COORDINATE_SYSTEM_NAME}`);
        coordinates.forEach((coordinate: Group) => {
            coordinate.setAttr(FIXED_SCALE_VALUE_ATTRIBUTE, this._scale);
        });
        fixedScaleValueZoomToFit(stage, this._scale / this._getUnitScale(this._settings.unitSet));

        const halfStageWidth = stage.width() / 2;
        const halfStageHeight = stage.height() / 2;
        let xDisp = halfStageWidth - (boundingRect.x + boundingRect.width / 2) * this._scale;

        if (this.sceneViewMode === SceneViewMode.TwoDNChart && this.expectedModelWidth != null && this.chartPositionX != null) {
            boundingRect = getBoundingBoxWithoutName(stage, [
                COORDINATE_SYSTEM_NAME,
                BEARING_VIEW_2D_GROUP,
                DIMENSIONS_WRAPPER_NAME,
                TRANSFORMER_GROUP_NAME,
            ]);
            if (boundingRect.width !== this.expectedModelWidth) {
                this._scale = this.expectedModelWidth / boundingRect.width;
                const marginLeft = halfStageWidth - CHART_MARGIN - this.chartPositionX;
                xDisp = halfStageWidth - boundingRect.x * this._scale - marginLeft;
            }
        }
        const yDisp = halfStageHeight - (boundingRect.y + boundingRect.height / 2) * this._scale;

        stage.x(xDisp);
        stage.y(yDisp);
        stage.scale({ x: this._scale, y: this._scale });
        this._stageScaler.resetDefaultStageScale();
    }

    private _getUnitScale(unitSet: UnitSet): number {
        if (unitSet === UnitSet.FPS) {
            return FPS_UNIT_SCALE;
        } else {
            return SI_UNIT_SCALE;
        }
    }
}
