import { Notch } from './notch';

export enum CutType {
    IDN_STANDARD = 'IDN_STANDARD',
    IDN_USER_DEFINED = 'IDN_USER_DEFINED',
}

export class UnderCut extends Notch {
    radius = 0;
    depth = 0;
    width = 0;
    cutType = '';

    protected dependsOnSegmentSide(): boolean {
        return true;
    }
}
