import { Pipe, PipeTransform } from '@angular/core';

import { User } from './user/user.model';

@Pipe({
    name: 'userFullName',
    pure: true,
})
export class UserFullNamePipe implements PipeTransform {
    transform(value: User | null, defaultValue = 'N/A'): string {
        if (!value || !value.firstName || !value.lastName) {
            return defaultValue;
        }
        return `${value.firstName} ${value.lastName}`;
    }
}
