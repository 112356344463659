import { RigidSupport } from '../../../cae-model/supports/rigid-support';
import { ModelElement } from '../../../cae-model/model-element';
import { SupportMediator } from './support-mediator';
import { createDirectionalSupportBinder } from './support-mediators-utils';

export class RigidSupportMediator extends SupportMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_RIGID_SUPPORT'];
    }

    protected createModelElement(): ModelElement {
        return new RigidSupport();
    }

    private _createBinder() {
        const binder = createDirectionalSupportBinder();
        this.addBinder(binder);
    }
}
