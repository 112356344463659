import { WMFImage } from '../bearing/wmf-images/wmf-image';
import { WMF_CylindricalPlainBush } from '../bearing/wmf-images/wmf-cylindrical-plain-bush';
import { CylindricalPlainBush } from '../../../../cae-model/supports/sliding-bearings/cylindrical-plain-bush';
import { SlidingBearingView2D } from './sliding-bearing-view-2d';

export class CylindricalPlainBushView2D extends SlidingBearingView2D {
    constructor(cylindricalPlainBush: CylindricalPlainBush) {
        super(cylindricalPlainBush);
    }

    // Create image manually. Overrides basic property.
    protected createWMFImage(): WMFImage {
        return new WMF_CylindricalPlainBush();
    }
}
