import { BearingElement } from '../../bearing-elements/bearing-element';
import { BearingRow } from '../../bearing-elements/bearing-row';
import { BearingGeometryInterface } from '../../interfaces/bearing-geometry-interface';
/* eslint-disable no-shadow */
import { Support } from '../support';

export enum INSTALLATION_CODE {
    IC_FREE = 'IC_FREE',
    IC_FIXED = 'IC_FIXED',
}

export enum INSTALLATION_DIRECTION {
    IC_REGULAR_DIRECTION = 'IC_REGULAR_DIRECTION',
    IC_REVERSE_DIRECTION = 'IC_REVERSE_DIRECTION',
}

/**
 * Bearing
 */
export class Bearing extends Support {
    constructionType = ''; // IDL_CONSTRUCTIONTYPE

    sAxPos = ''; // IDL_AXIAL_POSITIVE_INSTALLATION_CODE
    sAxNeg = ''; // IDL_AXIAL_NEGATIVE_INSTALLATION_CODE
    sRad = ''; // IDL_RADIAL_INSTALLATION_CODE_ELASTIC
    sRot = ''; // IDL_ROTATIVE_INSTALLATION_CODE
    iDir = ''; // IDL_INSTALLATION_DIRECTION

    bearinxType = ''; // type of bearinx object

    private _geometryInterface: BearingGeometryInterface;

    constructor() {
        super();
        this._geometryInterface = {
            outerDiameter: 0,
            innerDiameter: 0,
            width: 0,
        };
    }

    get outerDiameter(): number {
        // IDSU_DA
        return this._geometryInterface.outerDiameter;
    }

    set outerDiameter(value: number) {
        // IDSU_DA
        this._geometryInterface.outerDiameter = value;
    }

    get innerDiameter(): number {
        // IDSU_DI
        return this._geometryInterface.innerDiameter;
    }

    set innerDiameter(value: number) {
        // IDSU_DI
        this._geometryInterface.innerDiameter = value;
    }

    get width(): number {
        // IDSU_B
        return this._geometryInterface.width;
    }

    set width(value: number) {
        // IDSU_B
        this._geometryInterface.width = value;
    }

    /// count of rows
    get nbRows(): number {
        const rows = this.children.filter(x => x instanceof BearingRow);
        return rows.length;
    }

    protected doAdd(child: BearingElement) {
        if (child instanceof BearingElement) {
            this._addBearingElement(child);
        }
    }

    get geometry(): Readonly<BearingGeometryInterface> {
        return this._geometryInterface;
    }

    private _addBearingElement(element: BearingElement): void {
        element.getBearingGeometryFunc = () => this._geometryInterface;
    }
}
