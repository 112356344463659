import { CounterMoment } from '../../cae-model/transmission-elements/counter-moment';
import { DEFAULT_SEGMENT_DIAMETER, TINY_DIAMETER } from '../views-foundation-constants';

export class CounterMomentGeometry {
    private _tDO = 0;
    private _tDI = 0;
    private _tB = 0;
    private _isOnShaft = false;

    public get tDO(): number {
        return this._tDO;
    }

    public get tDI(): number {
        return this._tDI;
    }

    public get tB(): number {
        return this._tB;
    }

    public get isOnShaft(): boolean {
        return this._isOnShaft;
    }

    constructor(private _counterMoment: CounterMoment, private _unitScale: number) {
        this._setCounterMomentGeometry();
    }

    private _setCounterMomentGeometry(): void {
        let { innerDiameter: tDI, outerDiameter: tDO, width: tB } = this._counterMoment;
        let outerDiameter = this._counterMoment.getShaftCurrentOuterDiameter(this._counterMoment.x);
        let isOnShaft = false;

        const tinyDiameter = TINY_DIAMETER / this._unitScale;
        if (outerDiameter > tinyDiameter) {
            isOnShaft = true;
        } else {
            outerDiameter = DEFAULT_SEGMENT_DIAMETER / this._unitScale;
        }

        if (tDI < tinyDiameter) {
            tDI = outerDiameter;
        }

        if (tDO < tinyDiameter) {
            tDO = tDI * 2.0;
        } else if (tDO < tDI) {
            const tmp = tDO;
            tDO = tDI;
            tDI = tmp;
        }

        if (tB < tinyDiameter) {
            tB = outerDiameter / 2.0;
        }

        this._tDO = tDO;
        this._tDI = tDI;
        this._tB = tB;
        this._isOnShaft = isOnShaft;
    }
}
