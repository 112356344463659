import { Component, Inject } from '@angular/core';
import { BasePrompt } from './base.prompt';
import { PromptResult } from './prompt.model';
import { PROMPT_DATA } from './prompt.service';

interface PromptButton {
    result: PromptResult;
    captionKey: string;
    position: 'left' | 'right';
    color: 'primary' | 'secondary';
}

export interface TextPromptData {
    titleKey: string;
    titleData?: string;

    textKey: string;
    textData?: string;

    buttons: PromptButton[];
}

@Component({
    templateUrl: 'text.prompt.html',
})
export class TextPrompt extends BasePrompt {
    readonly buttons: PromptButton[] = this.data.buttons.sort((a, b) => a.position.toString().localeCompare(b.position.toString()));

    constructor(@Inject(PROMPT_DATA) readonly data: TextPromptData) {
        super();
    }
}
