import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModelObject } from './model-object.model';
import { Model } from './model.model';

@Injectable({
    providedIn: 'root',
})
export class ModelService {
    public readonly model$ = new BehaviorSubject<Model<ModelObject> | undefined>(undefined);

    private readonly _objectMap = new Map<string, ModelObject>();

    public get model(): Model<ModelObject> | undefined {
        return this.model$.value;
    }

    public set model(value: Model<ModelObject> | undefined) {
        this._objectMap.clear();

        if (value) {
            this._fillObjectMap(value.rootObject);
        } else {
            this._objectMap.clear();
        }

        this.model$.next(value);
    }

    public isChildOf(parentId: string, childId: string): boolean {
        const parent = this.getObject(parentId);
        const children = parent?.children ?? [];
        const isDirectChild = !!children.find(child => child.id === childId);
        return isDirectChild || children.some(child => this.isChildOf(child.id, childId));
    }

    public getObject(id: string): ModelObject | undefined {
        return this._objectMap.get(id);
    }

    public unset(): void {
        this.model = undefined;
    }

    public getTypedObject(id: string, type: string): ModelObject | undefined {
        const obj = this.getObject(id);
        if (!obj) {
            return void 0;
        }

        if (obj.type === type) {
            return obj;
        }

        const objs = Array.from(this._objectMap.values()).filter(obj => obj.type === type && this.isChildOf(obj.id, id));
        return !!objs && objs.length ? objs[0] : void 0;
    }

    private _fillObjectMap(root: ModelObject): void {
        this._objectMap.set(root.id, root);
        root.children.forEach(object => this._fillObjectMap(object));
    }
}
