import { ModelElementFinder } from './services/cae-element-finder.service';
import { NgModule } from '@angular/core';
import { CaeModelProviderService } from './services/cae-model-provider.service';
import { CurrentObjectProviderService } from './services/current-object-provider.service';
import { EditPermissionService } from './services/edit-permission.service';
import { SelectedElementService } from './services/selected-element.service';
import { SupportGeometryFactory } from './services/support-geometry-factory';

@NgModule({
    declarations: [],
    exports: [],
    imports: [],
    providers: [
        SelectedElementService,
        CaeModelProviderService,
        CurrentObjectProviderService,
        EditPermissionService,
        ModelElementFinder,
        SupportGeometryFactory,
    ],
})
export class ViewsFoundationModule {}
