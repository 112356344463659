import Konva from 'konva';
import { ElementView2D } from '../element-view-2d';
import { createLoadDashLineFullLength } from '../../functions/loads-utils';
import { DistributedLoad } from '../../../cae-model/loads/distributed-load';
import { DistributedLoadForce } from '../../primitives/distributed-load-force';
import { DistributedLoadTorque } from '../../primitives/distributed-load-torque';
import {
    BOUNDING_BOX,
    DISTRIBUTED_LOAD_VIEW_2D_NAME,
    DOT_RADIUS,
    LOAD_STROKE_COLOR_2D,
    STROKE_WIDTH,
    TRANSPARENT,
} from '../view-2d-constants';

export class DistributedLoadView2D extends ElementView2D {
    private _group: Konva.Group;

    constructor(private _distributedLoad: DistributedLoad) {
        super(_distributedLoad);
    }

    protected get draggable() {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    protected updateGroupWithoutChildren(): void {
        // override basis class
        // TODO: we should updateShape here

        this._group = this._createShapes(this._distributedLoad);

        const boundingRectForUTSelection = new Konva.Rect({
            ...this._group.getClientRect({
                skipStroke: true,
            }),
            fill: TRANSPARENT,
            name: BOUNDING_BOX,
        });
        this._group.add(boundingRectForUTSelection);

        this.groupWithoutChildren.add(this._group);
    }

    private _createShapes(distributedLoad: DistributedLoad): Konva.Group {
        const group = new Konva.Group({
            name: DISTRIBUTED_LOAD_VIEW_2D_NAME,
            transformable: true,
        });
        const currentX = distributedLoad.x;
        const currentRadius = distributedLoad.getCurrentDiameter(currentX) / 2;
        const endPosition = distributedLoad.xEnd - distributedLoad.x;
        group.add(createLoadDashLineFullLength(currentRadius));
        group.add(createLoadDashLineFullLength(currentRadius, endPosition));

        if (!this._isForceExist(distributedLoad) && !this._isTorqueExist(distributedLoad)) {
            const middlePosition = endPosition / 2;
            [0, middlePosition, endPosition].forEach(positionX => {
                group.add(
                    new Konva.Circle({
                        radius: DOT_RADIUS / this.input.unitScaleFactor,
                        fill: LOAD_STROKE_COLOR_2D,
                        stroke: LOAD_STROKE_COLOR_2D,
                        strokeWidth: STROKE_WIDTH,
                        strokeScaleEnabled: false,
                        x: positionX,
                    }),
                );
            });
        } else {
            if (this._isForceExist(distributedLoad)) {
                group.add(new DistributedLoadForce(distributedLoad, this.input.unitScaleFactor).group);
            }
            if (this._isTorqueExist(distributedLoad)) {
                group.add(new DistributedLoadTorque(distributedLoad, this.input.unitScaleFactor).group);
            }
        }

        return group;
    }

    private _isForceExist(load: DistributedLoad): boolean {
        return (
            load.forceXStart !== 0 ||
            load.forceXEnd !== 0 ||
            load.forceYStart !== 0 ||
            load.forceYEnd !== 0 ||
            load.forceZStart !== 0 ||
            load.forceZEnd !== 0
        );
    }

    private _isTorqueExist(load: DistributedLoad): boolean {
        return (
            load.torqueXStart !== 0 ||
            load.torqueXEnd !== 0 ||
            load.torqueYStart !== 0 ||
            load.torqueYEnd !== 0 ||
            load.torqueZStart !== 0 ||
            load.torqueZEnd !== 0
        );
    }
}
