import { WMFImage } from '../bearing/wmf-images/wmf-image';
import { SlidingBearingView2D } from './sliding-bearing-view-2d';
import { WMF_AngularSphericalPlainBearing } from '../bearing/wmf-images/wmf-angular-spherical-plain-bearing';
import { AngularSphericalPlain } from '../../../../cae-model/supports/sliding-bearings/angular-spherical-plain';

export class AngularSphericalPlainView2D extends SlidingBearingView2D {
    constructor(angularSphericalPlain: AngularSphericalPlain) {
        super(angularSphericalPlain);
    }

    // Create image manually. Overrides basic property.
    protected createWMFImage(): WMFImage {
        return new WMF_AngularSphericalPlainBearing();
    }
}
