import { DistributedLoad } from '../../cae-model/loads/distributed-load';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxLoadDependentBinder } from '../binders/bearinx-load-dependent-binder';
import { createLineLoadStandardBinder } from '../functions/line-loads-utils';

export class LinearLineLoadMediator extends BasicMediator {
    constructor() {
        super();
        this._createLoadDependentBinder();
        this.addBinder(createLineLoadStandardBinder());
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_LOAD_CASE_OVERLAPPING_LINEAR_LINE_LOAD'];
    }

    protected createModelElement(): ModelElement {
        return new DistributedLoad();
    }

    private _createLoadDependentBinder(): void {
        const binder = new BearinxLoadDependentBinder();
        binder.properties = [
            { left: 'forceXStart', right: 'IDLD_FX_0' },
            { left: 'forceYStart', right: 'IDLD_FY_0' },
            { left: 'forceZStart', right: 'IDLD_FZ_0' },

            { left: 'torqueXStart', right: 'IDLD_MX_0' },
            { left: 'torqueYStart', right: 'IDLD_MY_0' },
            { left: 'torqueZStart', right: 'IDLD_MZ_0' },

            { left: 'forceXEnd', right: 'IDLD_FX_MAX' },
            { left: 'forceYEnd', right: 'IDLD_FY_MAX' },
            { left: 'forceZEnd', right: 'IDLD_FZ_MAX' },

            { left: 'torqueXEnd', right: 'IDLD_MX_MAX' },
            { left: 'torqueYEnd', right: 'IDLD_MY_MAX' },
            { left: 'torqueZEnd', right: 'IDLD_MZ_MAX' },
        ];
        this.addBinder(binder);
    }
}
