import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SimpleTooltipComponent } from './simple-tooltip/simple-tooltip.component';

@Directive({
    selector: '[bxSimpleTooltip]',
})
export class SimpleTooltipDirective implements OnInit, OnChanges, OnDestroy {
    @Input('bxSimpleTooltip') text: string;
    @Input('bxSimpleTooltipSuppress') suppress = false;

    private _overlayRef: OverlayRef;
    private _componentRef?: ComponentRef<SimpleTooltipComponent>;

    constructor(
        private readonly _overlayPositionBuilder: OverlayPositionBuilder,
        private readonly _elementRef: ElementRef,
        private readonly _overlay: Overlay,
    ) {}

    ngOnInit() {
        const positionStrategy = this._overlayPositionBuilder
            .flexibleConnectedTo(this._elementRef)
            .withLockedPosition(true)
            .withPositions([
                {
                    originX: 'end',
                    originY: 'center',
                    overlayX: 'start',
                    overlayY: 'center',
                    offsetX: 15,
                    offsetY: 0,
                    panelClass: 'bottom-left',
                },
            ]);
        this._overlayRef = this._overlay.create({ positionStrategy });
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('text' in changes && this._componentRef) {
            this._componentRef.instance.text = changes.text.currentValue;
        }
    }

    ngOnDestroy() {
        this.hide();
    }

    @HostListener('mouseenter')
    show() {
        if (this.text && !this.suppress && !this._overlayRef.hasAttached()) {
            const tooltipPortal = new ComponentPortal(SimpleTooltipComponent);
            this._componentRef = this._overlayRef.attach(tooltipPortal);
            this._componentRef.instance.text = this.text;
        }
    }

    @HostListener('mouseleave')
    hide() {
        if (this._overlayRef.hasAttached()) {
            this._componentRef = undefined;
            this._overlayRef.detach();
        }
    }
}
