import { NgModule } from '@angular/core';
import { FormControlModule } from '../form-control/form-control.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TitleBarModule } from '../title-bar/title-bar.module';
import { TenantInterceptor } from './tenant.interceptor';
import { TenantSelectionComponent } from './tenant-selection/tenant-selection.component';
import { TenantSelectorComponent } from './tenant-selector/tenant-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from '../auth/auth.module';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, TitleBarModule, FormControlModule, AuthModule, TranslateModule],
    declarations: [TenantSelectorComponent, TenantSelectionComponent],
    providers: [{ provide: HTTP_INTERCEPTORS, multi: true, useClass: TenantInterceptor }],
    exports: [TenantSelectorComponent, TenantSelectionComponent],
})
export class TenantModule {}
