import { PointMass } from '../../cae-model/loads/point-mass';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';

export class PointMassMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_DYNAMIC_POINT_MASS'];
    }

    protected createModelElement(): ModelElement {
        return new PointMass();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'x', right: 'IDCO_X' }];
        this.addBinder(binder);
    }
}
