import Konva from 'konva';
import { AxisArrowType } from '../../bearinx-mediator/functions/loads-properties';
import {
    BACKGROUND_COLOR_2D,
    CIRCLE_RADIUS_COORDINATE_2D,
    FIXED_SCALE_ATTRIBUTE,
    VECTOR_LENGTH_2D,
    TEXT_WIDTH_2D,
    TEXT_HEIGHT_2D,
    ARROW_HEAD_LENGTH_2D,
    STROKE_WIDTH_COORDINATE_2D,
    FONT_FAMILY_2D,
    COORDINATE_SYSTEM_NAME,
    TEXT_FONT_SIZE,
    COORDINATE_SYSTEM_VECTOR_X,
    COORDINATE_SYSTEM_VECTOR_Y,
    COORDINATE_SYSTEM_VECTOR_Z,
    FIXED_SCALED_VALUE_ZOOM_TO_FIT,
} from '../elements-view/view-2d-constants';
import { Arrow } from './arrow';
import { ArrowInput2DInterface } from './arrow-input-2d-interface';
import { CircleTimes } from './circle-times';
import { CircleTimesInput2DInterface } from './circle-times-input-2d-interface';

export class CoordinateSystem {
    private _group: Konva.Group;
    private _vectorZGroup: Konva.Group;
    private _vectorZLabel: Konva.Text;
    private _vectorXGroup: Konva.Group;
    private _vectorYGroup: Konva.Group;

    constructor(stroke: string, private _opacity: number, private _unitScaleFactor: number) {
        this._createShapes(stroke);
    }

    get group(): Konva.Group {
        return this._group;
    }

    private _createShapes(stroke: string): void {
        this._group = new Konva.Group({
            name: COORDINATE_SYSTEM_NAME,
            transformable: true,
        });
        this._group.setAttr(FIXED_SCALE_ATTRIBUTE, true);
        this._vectorZGroup = this._createVectorZ(stroke);
        this._vectorZLabel = this._createVectorZLabel(stroke);
        this._vectorXGroup = this._createVectorX(stroke);
        this._vectorYGroup = this._createVectorY(stroke);
        this._group.add(this._vectorZGroup);
        this._group.add(this._vectorXGroup);
        this._group.add(this._vectorYGroup);
        this._group.add(this._vectorZLabel);
    }

    private _createVectorZ(stroke: string): Konva.Group {
        const vectorZGroup = new Konva.Group();
        const circleTimesInput: CircleTimesInput2DInterface = {
            stroke: stroke,
            opacity: this._opacity,
            strokeWidth: STROKE_WIDTH_COORDINATE_2D,
            radius: CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor,
            name: COORDINATE_SYSTEM_VECTOR_Z,
        };
        const circleTimes = new CircleTimes(circleTimesInput);
        vectorZGroup.add(circleTimes.group);
        return vectorZGroup;
    }

    private _createVectorZLabel(stroke: string): Konva.Text {
        const zLabel = new Konva.Text({
            fontFamily: FONT_FAMILY_2D,
            fontSize: TEXT_FONT_SIZE / this._unitScaleFactor,
            text: 'Z',
            width: TEXT_WIDTH_2D / this._unitScaleFactor,
            height: TEXT_HEIGHT_2D / this._unitScaleFactor,
            x: (-CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor) * 2,
            y: (-CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor) * 2,
            fill: stroke,
            opacity: this._opacity,
        });
        zLabel.setAttr(FIXED_SCALED_VALUE_ZOOM_TO_FIT, true);
        return zLabel;
    }

    private _createVectorX(stroke: string): Konva.Group {
        const vectorXGroup = new Konva.Group();
        const startingPoint = [CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor, 0];
        const endingPoint = [(VECTOR_LENGTH_2D + CIRCLE_RADIUS_COORDINATE_2D) / this._unitScaleFactor, 0];
        const arrowInput: ArrowInput2DInterface = {
            startingPoint: startingPoint,
            endingPoint: endingPoint,
            stroke: stroke,
            strokeWidth: STROKE_WIDTH_COORDINATE_2D,
            fillColor: BACKGROUND_COLOR_2D,
            axisArrowType: AxisArrowType.X,
            visibleArrowHead: true,
            text: 'X',
            textPositionX: (VECTOR_LENGTH_2D - TEXT_WIDTH_2D / 2 + ARROW_HEAD_LENGTH_2D / 2) / this._unitScaleFactor,
            textPositionY: (-CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor) * 2,
            opacity: this._opacity,
            unitScaleFactor: this._unitScaleFactor,
            isNegative: false,
            name: COORDINATE_SYSTEM_VECTOR_X,
        };
        const arrow = new Arrow(arrowInput);
        vectorXGroup.add(arrow.group);
        return vectorXGroup;
    }

    private _createVectorY(stroke: string): Konva.Group {
        const vectorYGroup = new Konva.Group();
        const startingPoint = [0, CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor];
        const endingPoint = [0, (VECTOR_LENGTH_2D + CIRCLE_RADIUS_COORDINATE_2D) / this._unitScaleFactor];
        const arrowInput: ArrowInput2DInterface = {
            startingPoint: startingPoint,
            endingPoint: endingPoint,
            stroke: stroke,
            strokeWidth: STROKE_WIDTH_COORDINATE_2D,
            fillColor: BACKGROUND_COLOR_2D,
            axisArrowType: AxisArrowType.Y,
            visibleArrowHead: true,
            text: 'Y',
            textPositionX: CIRCLE_RADIUS_COORDINATE_2D / this._unitScaleFactor,
            textPositionY: (VECTOR_LENGTH_2D - TEXT_HEIGHT_2D / 2 + ARROW_HEAD_LENGTH_2D / 2) / this._unitScaleFactor,
            opacity: this._opacity,
            unitScaleFactor: this._unitScaleFactor,
            isNegative: false,
            name: COORDINATE_SYSTEM_VECTOR_Y,
        };
        const arrow = new Arrow(arrowInput);
        vectorYGroup.add(arrow.group);

        return vectorYGroup;
    }
}
