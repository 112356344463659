import { Shaft } from '../../cae-model/shaft';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { IDO_SHAFT } from '../bearinx-ids';

export class ShaftMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return [IDO_SHAFT];
    }

    protected createModelElement(): ModelElement {
        return new Shaft();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'y', right: 'IDCO_Y' },
            { left: 'z', right: 'IDCO_Z' },
        ];
        this.addBinder(binder);
    }
}
