import { NgModule } from '@angular/core';
import { AboutComponent } from './about.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, TranslateModule, CdkTableModule],
    declarations: [AboutComponent],
})
export class AboutModule {}
