import { WormGear } from '../../cae-model/transmission-elements/worm-gear';
import { DEFAULT_SEGMENT_DIAMETER, STANDARD_GEAR_WIDTH, TINY_DIAMETER } from '../views-foundation-constants';

export class WormGearGeometry {
    private _tDM = 0;
    private _tDO = 0;
    private _tDI = 0;
    private _tGR = 0;
    private _tB = 0;
    private _tBore = 0;
    private _gamma_m = 0;
    private _isOnShaft = false;

    public get tDM(): number {
        return this._tDM;
    }

    public get tDO(): number {
        return this._tDO;
    }

    public get tDI(): number {
        return this._tDI;
    }

    public get tGR(): number {
        return this._tGR;
    }

    public get tB(): number {
        return this._tB;
    }

    public get tBore(): number {
        return this._tBore;
    }

    public get gamma_m(): number {
        return this._gamma_m;
    }

    public get isOnShaft(): boolean {
        return this._isOnShaft;
    }

    constructor(private _wormGear: WormGear, private _unitScale: number) {
        this._setWormGearGeometry();
    }

    private _setWormGearGeometry(): void {
        const { x: tX, referenceDiameter: tDM, meanPitchAngle } = this._wormGear;
        let { width: tB, grooveRadius: tGR, outerDiameter: tDO } = this._wormGear;
        const outerDiameter = this._wormGear.getShaftCurrentOuterDiameter(tX);
        let tBore = DEFAULT_SEGMENT_DIAMETER;
        let isOnShaft = false;

        const tinyDiameter = TINY_DIAMETER / this._unitScale;
        const gearWidth = STANDARD_GEAR_WIDTH / this._unitScale;
        if (outerDiameter > tinyDiameter) {
            tBore = outerDiameter;
            isOnShaft = true;
        }

        if (tB < tinyDiameter) {
            if (tBore < gearWidth) {
                tB = tBore / 2.0;
            } else {
                tB = 0.2 * (tBore - gearWidth) + gearWidth / 2;
            }
        }

        const halfWidth = tB / 2.0;

        if (tGR > halfWidth || tGR < 0) {
            tGR = halfWidth / this._unitScale;
        } else {
            tGR = tGR / this._unitScale;
        }

        if (tDO < tinyDiameter) {
            const di = tDM > 0.1 ? tDM + 4.0 / this._unitScale : tBore + 10.0 / this._unitScale;
            tDO = 2.0 * halfWidth + di;
        }

        if (tDO < tBore) {
            tBore = 0.0;
        } else {
            tBore = outerDiameter;
        }

        const tDI = tBore;

        this._tDM = tDM;
        this._tDO = tDO;
        this._tDI = tDI;
        this._tGR = tGR;
        this._tB = tB;
        this._tBore = tBore;
        this._gamma_m = meanPitchAngle;
        this._isOnShaft = isOnShaft;
    }
}
