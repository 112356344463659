import { Plane, Vector3, Mesh, BufferGeometry, Float32BufferAttribute, Line3, Material } from 'three';
import { TINY_3D } from '../settings/view-3d-constants';

interface PlaneInterface {
    vertices: Vector3[];
    normals: Vector3[];
}

// original class is plane.clipping.ts in 3d-viz repository
class PlaneClipping {
    normal: Vector3;
    distance: number;
    obj3d: Mesh;

    private plane: Plane;
    private geometry: BufferGeometry;
    private material: Material;
    private mesh: Mesh;

    createMesh(): Mesh {
        this.plane = new Plane(this.normal, this.distance);
        const faces = this._getFaces();
        const newGeometry = this._doClipping(faces);
        this.geometry = newGeometry;

        // Create material.
        this.material = this.obj3d.material as Material;

        // Create mesh.
        this.mesh = new Mesh(this.geometry, this.material);
        return this.mesh;
    }

    private _getFaces(): PlaneInterface[] {
        const nonIndexedGeometry = this.obj3d.geometry.toNonIndexed();
        const posAttr = nonIndexedGeometry.getAttribute('position');
        const normAttr = nonIndexedGeometry.getAttribute('normal');

        const nPoints = posAttr.count;
        const nFaces = nPoints / 3;
        const faces = new Array<PlaneInterface>();
        for (let i = 0; i < nFaces; i += 1) {
            const points = new Array<Vector3>();
            const normals = new Array<Vector3>();
            for (let j = 0; j < 3; j += 1) {
                const x = posAttr.array[i * 9 + j * 3];
                const y = posAttr.array[i * 9 + j * 3 + 1];
                const z = posAttr.array[i * 9 + j * 3 + 2];
                const nx = normAttr.array[i * 9 + j * 3];
                const ny = normAttr.array[i * 9 + j * 3 + 1];
                const nz = normAttr.array[i * 9 + j * 3 + 2];
                points.push(new Vector3(x, y, z));
                normals.push(new Vector3(nx, ny, nz));
            }
            faces.push({ vertices: points, normals: normals });
        }
        return faces;
    }

    private _doClipping(faces: PlaneInterface[]): BufferGeometry {
        const positions = new Array<number>();
        const normals = new Array<number>();
        for (let i = 0; i < faces.length; i += 1) {
            const verts = faces[i].vertices;
            const norm = faces[i].normals;
            const check = this._checkFace(verts);
            if (check.indexOf(false) === -1) {
                for (let j = 0; j < verts.length; j += 1) {
                    positions.push(verts[j].x, verts[j].y, verts[j].z);
                    normals.push(norm[j].x, norm[j].y, norm[j].z);
                }
            } else if (check.indexOf(true) === -1) {
            } else {
                const additionalFaces = this._getAdditionalFaces(check, verts, norm);
                for (let k = 0; k < additionalFaces.length; k += 1) {
                    const newFace = additionalFaces[k];
                    const v = newFace.vertices;
                    const n = newFace.normals;
                    for (let m = 0; m < 3; m += 1) {
                        positions.push(v[m].x, v[m].y, v[m].z);
                        normals.push(n[m].x, n[m].y, n[m].z);
                    }
                }
            }
        }

        const positionAttribute = new Float32BufferAttribute(positions, 3);
        const normalAttribute = new Float32BufferAttribute(normals, 3);
        const geometry = new BufferGeometry();
        geometry.setAttribute('position', positionAttribute);
        geometry.setAttribute('normal', normalAttribute);
        return geometry;
    }

    private _checkFace(face: Vector3[]): boolean[] {
        const nPoints = face.length;
        const side = [];
        for (let i = 0; i < nPoints; i += 1) {
            const point = face[i];
            const distance = this.plane.distanceToPoint(point);
            side.push(distance < 0.0);
        }
        return side;
    }

    private _getAdditionalFaces(check: boolean[], verts: Vector3[], norm: Vector3[]): PlaneInterface[] {
        let count = 0;
        let pointOnPlane: Vector3;
        pointOnPlane = new Vector3();
        for (let n = 0; n < check.length; n += 1) {
            if (check[n]) {
                count += 1;
            }
        }

        const face: PlaneInterface = { normals: norm, vertices: new Array<Vector3>() };
        if (count === 1) {
            const trueIdx = check.indexOf(true);
            const start = verts[trueIdx];
            for (let i = 0; i < check.length; i += 1) {
                if (!check[i]) {
                    const line = new Line3(start, verts[i]);
                    pointOnPlane = this.plane.intersectLine(line, new Vector3())!;
                    face.vertices.push(pointOnPlane);
                } else {
                    face.vertices.push(verts[i]);
                }
            }
            return [face];
        } else if (count === 2) {
            const falseIdx = check.indexOf(false);
            const start = verts[falseIdx];
            const sortedVerts = [];
            for (let i = 0; i < verts.length; i += 1) {
                const idx = (falseIdx + i) % verts.length;
                sortedVerts.push(verts[idx]);
            }

            // face A
            const lineA = new Line3(start, sortedVerts[1]);
            pointOnPlane = this.plane.intersectLine(lineA, new Vector3())!;
            const faceA: PlaneInterface = { normals: norm, vertices: new Array<Vector3>() };
            faceA.vertices.push(pointOnPlane);
            faceA.vertices.push(sortedVerts[1]);
            faceA.vertices.push(sortedVerts[2]);

            // face B
            const lineB = new Line3(start, sortedVerts[2]);
            pointOnPlane = this.plane.intersectLine(lineB, new Vector3())!;
            const faceB: PlaneInterface = { normals: norm, vertices: new Array<Vector3>() };
            faceB.vertices.push(pointOnPlane);
            faceB.vertices.push(faceA.vertices[0]);
            faceB.vertices.push(sortedVerts[2]);
            return [faceA, faceB];
        }
        return [];
    }
}

export function clipMeshOnPlane(mesh: THREE.Mesh, clippingDirection: Vector3): Mesh {
    const planeClipping = new PlaneClipping();

    planeClipping.distance = -TINY_3D;
    planeClipping.obj3d = mesh;
    planeClipping.normal = clippingDirection;
    return planeClipping.createMesh();
}
