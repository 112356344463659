import { BufferGeometry, Matrix4, Mesh, Vector3 } from 'three';
import { ElementView3D } from './element-view-3d';
import { SystemLoadcase } from '../../cae-model/system-loadcase';
import { createDeadWeightMaterial } from '../settings/default-materials';
import { LoadViewInterface, createLoadView } from '../functions/create-load-view';

export class SystemLoadcaseView3D extends ElementView3D {
    constructor(systemLoadcase: SystemLoadcase) {
        super(systemLoadcase);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    protected updateGroupWithoutChildren(): void {
        const geometry = this._createGeometry();
        this.mesh.geometry = geometry;
    }

    private _createGeometry(): BufferGeometry {
        if (this._systemLoadcase.deadWeight !== 'CO_CALCULATION_WITH_OWN_WEIGHT') {
            return new BufferGeometry();
        }

        const outerDiameter = this._systemLoadcase.getCurrentDiameter(0);
        const loadViewInterfaceForce: LoadViewInterface = {
            outerDiameter: outerDiameter,
            direction: new Vector3(0, 1, 0),
            arrowLength: outerDiameter * 1.1,
            isTorque: false,
        };
        return createLoadView(loadViewInterfaceForce);
    }

    private get _systemLoadcase(): SystemLoadcase {
        return this.modelElement as SystemLoadcase;
    }

    private _createMesh(): Mesh {
        const geometry = this._createGeometry();
        const material = createDeadWeightMaterial();
        this.mesh = new Mesh(geometry, material);

        // Assuming you have a vector with three angles representing rotations around X, Y, and Z axes
        // transform angles from degree to radians
        const rotationAngleInRadiansX = this._systemLoadcase.epsx * (Math.PI / -180);
        const rotationAngleInRadiansY = this._systemLoadcase.epsy * (Math.PI / -180);
        const rotationAngleInRadiansZ = this._systemLoadcase.epsz * (Math.PI / -180);

        // Create an identity matrix
        const rotationMatrix = new Matrix4();

        // Apply rotation using makeRotationAxis
        rotationMatrix.makeRotationAxis(new Vector3(1, 0, 0), rotationAngleInRadiansX); // Rotate around X-axis
        this.mesh.applyMatrix4(rotationMatrix);

        rotationMatrix.makeRotationAxis(new Vector3(0, 1, 0), rotationAngleInRadiansY); // Rotate around Y-axis
        this.mesh.applyMatrix4(rotationMatrix);

        rotationMatrix.makeRotationAxis(new Vector3(0, 0, 1), rotationAngleInRadiansZ); // Rotate around Z-axis
        this.mesh.applyMatrix4(rotationMatrix);

        return this.mesh;
    }
}
