import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CAEModel } from '../../cae-model/cae-model';
import { ModelElement } from '../../cae-model/model-element';

@Injectable()
export class CaeModelProviderService implements OnDestroy {
    private _modelSubject: BehaviorSubject<CAEModel | null> = new BehaviorSubject<CAEModel | null>(null);
    private readonly _destroy$ = new Subject<void>();

    constructor() {}

    getModel$(): Observable<CAEModel | null> {
        return this._modelSubject.pipe(takeUntil(this._destroy$));
    }

    getModelValue(): CAEModel | null {
        return this._modelSubject.value;
    }

    set model(value: CAEModel) {
        this._destroyChildrenSubcriptions(this._modelSubject.value!);
        this._modelSubject.next(value);
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
        this._destroyChildrenSubcriptions(this._modelSubject.value!);
        this._modelSubject = new BehaviorSubject<CAEModel | null>(null);
    }

    private _destroyChildrenSubcriptions(modelElement: ModelElement): void {
        if (modelElement instanceof ModelElement) {
            modelElement.onDestroy();
        }
    }
}
