import { SI_UNIT_SCALE } from '../../views-foundation/views-foundation-constants';
import { Notch } from './notch';

export class VNotch extends Notch {
    depth = 0;
    unitScale = SI_UNIT_SCALE;

    protected dependsOnSegmentSide(): boolean {
        return false;
    }

    public get width(): number {
        const scaledDepth = this.depth * this.unitScale;
        const width = scaledDepth / Math.sqrt(scaledDepth);
        return width / this.unitScale;
    }
}
