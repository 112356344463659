import { NgModule } from '@angular/core';
import { BearinxMediatorService as BearinxxMediatorService } from './services/bearinx-mediator.service';
import { BearinxConverterService } from './services/bearinx-converter.service';
import { BearinxModelProviderService } from './services/bearinx-model-provider.service';

@NgModule({
    declarations: [],
    exports: [],
    imports: [],
    providers: [BearinxxMediatorService, BearinxModelProviderService, BearinxConverterService],
})
export class BearinxMediatorModule {}
