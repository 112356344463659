import { BufferGeometry, DoubleSide, Group, Line, LineBasicMaterial, Mesh, Object3D, TorusGeometry, Vector3 } from 'three';
import { NaturalMode } from '../interfaces/natural-mode.interface';
import {
    COLOR__COUNTERROTATIONAL_CIRCLE,
    COLOR__COROTATIONAL_CIRCLE,
    RADII_COLOR,
    RADII_CONNECTING_LINE_COLOR,
    TORUS_TUBE,
    TORUS_RADIAL_SEGMENTS,
    TORUS_TUBULAR_SEGMENTS,
} from '../settings/natural-mode-constants';
import { Injectable, OnDestroy } from '@angular/core';
import { AnimationService } from './animation.service';
import { NaturalModeViewConfigInterface } from '../interfaces/natural-mode-view-config.interface';
import { degToRad } from 'three/src/math/MathUtils';
import { View3DService } from '../../view-3d/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class NaturalModeView3DService implements OnDestroy {
    private _globalGroup: Group;
    private _naturalFormsGroup: Group;
    private _clockwise: boolean;
    private _naturalModeView: Object3D;
    private readonly _destroy$ = new Subject<void>();

    constructor(private _animationService: AnimationService, private _view3dService: View3DService) {
        this._animationService.updateAnimation().subscribe(timeDelta => this.animate(timeDelta));
        this._view3dService
            .getModelGroup()
            .pipe(takeUntil(this._destroy$))
            .subscribe(group => {
                if (group && group.children) {
                    this._globalGroup = group;
                    if (group.children.length < 3 && this._naturalFormsGroup && this._naturalFormsGroup.children) {
                        this._globalGroup.add(this._naturalFormsGroup);
                        this._view3dService.updateModelGroup(this._globalGroup);
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    visualize(naturalModes: NaturalMode[], viewConfig: NaturalModeViewConfigInterface) {
        this._naturalModeView = new Object3D();
        this._naturalFormsGroup = new Group();

        this._naturalFormsGroup.name = 'Natural Forms';
        naturalModes.forEach(naturalMode => {
            this.createNaturalFrequencyObject(naturalMode, viewConfig);
            this._clockwise = naturalMode.clockwise;
        });
        const natFreq = this._globalGroup.getObjectByName('Natural Forms');
        if (natFreq) {
            natFreq.removeFromParent();
        }
        this._naturalFormsGroup.add(this._naturalModeView);
        this._globalGroup.add(this._naturalFormsGroup);
        this._view3dService.updateModelGroup(this._globalGroup);
    }

    removeNaturalModes() {
        this._naturalFormsGroup.clear();
    }

    private createNaturalFrequencyObject(naturalMode: NaturalMode, viewConfig: NaturalModeViewConfigInterface) {
        const circles = this.createCircles(naturalMode, viewConfig);
        circles.forEach(item => this._naturalModeView.add(item));

        const torsionalLines = this.createTorsionalLines(naturalMode, viewConfig);
        torsionalLines.forEach(item => this._naturalModeView.add(item));

        const radii = this.createRadii(naturalMode, viewConfig);
        radii.forEach(item => this._naturalModeView.add(item));

        const connectingLines = this.createRadiiConnectingLines(naturalMode, viewConfig);
        connectingLines.forEach(item => this._naturalModeView.add(item));
    }

    private createCircles(naturalMode: NaturalMode, viewConfig: NaturalModeViewConfigInterface): Mesh[] {
        const circles: Mesh[] = [];
        const circlesCount = naturalMode.deformations.length;
        for (let i = 0; i < circlesCount; i++) {
            const currentVector = naturalMode.deformations[i];
            const coRotational = naturalMode.deformations[i].z < 0 ? false : true;
            const y = this._parseVectorValue(currentVector.y);
            const x = this._parseVectorValue(currentVector.x);
            const torusRadius = y * viewConfig.radialScaleFactor;
            const circleGeometry = new TorusGeometry(torusRadius, TORUS_TUBE, TORUS_RADIAL_SEGMENTS, TORUS_TUBULAR_SEGMENTS);
            const circleMaterial = new LineBasicMaterial({
                color: coRotational ? COLOR__COROTATIONAL_CIRCLE : COLOR__COUNTERROTATIONAL_CIRCLE,
                side: DoubleSide,
            });
            const circle = new Mesh(circleGeometry, circleMaterial);

            circle.position.x = x;
            circle.rotateY(Math.PI / 2); // rotate circle 90 degree

            circles.push(circle);
        }
        return circles;
    }

    private createTorsionalLines(naturalMode: NaturalMode, viewConfig: NaturalModeViewConfigInterface): Line[] {
        const torsionalLines: Line[] = [];
        const circlesCount = naturalMode.deformations.length;
        for (let i = 0; i < circlesCount; i++) {
            const currentVector = naturalMode.deformations[i];
            const y = this._parseVectorValue(currentVector.y);
            const x = this._parseVectorValue(currentVector.x);
            const radius = y * viewConfig.radialScaleFactor;

            const linePoints = [];
            const lineLenght = viewConfig.torsionLineScaleFactor;
            linePoints.push(new Vector3(0, lineLenght, 0));
            linePoints.push(new Vector3(0, -lineLenght, 0));

            const lineGeometry = new BufferGeometry().setFromPoints(linePoints);
            const lineMaterial = new LineBasicMaterial({ color: RADII_CONNECTING_LINE_COLOR, side: DoubleSide });
            const torsionalLine = new Line(lineGeometry, lineMaterial);

            torsionalLine.position.x = x;
            torsionalLine.position.y = radius;
            torsionalLine.rotateX(degToRad(90));

            torsionalLines.push(torsionalLine);
        }
        return torsionalLines;
    }

    private createRadii(naturalMode: NaturalMode, viewConfig: NaturalModeViewConfigInterface): Line[] {
        const radii: Line[] = [];
        const circlesCount = naturalMode.deformations.length;
        for (let i = 0; i < circlesCount; i++) {
            const currentVector = naturalMode.deformations[i];
            const y = this._parseVectorValue(currentVector.y);
            const x = this._parseVectorValue(currentVector.x);
            const outerRadius = y * viewConfig.radialScaleFactor;

            const linePoints = [];
            linePoints.push(new Vector3(0, 0, 0));
            linePoints.push(new Vector3(0, outerRadius, 0));

            const radiusGeometry = new BufferGeometry().setFromPoints(linePoints);
            const radiusMaterial = new LineBasicMaterial({ color: RADII_COLOR, side: DoubleSide });
            const line = new Line(radiusGeometry, radiusMaterial);

            line.position.x = x;

            radii.push(line);
        }
        return radii;
    }

    private createRadiiConnectingLines(naturalMode: NaturalMode, viewConfig: NaturalModeViewConfigInterface): Line[] {
        const radiiConnectingLine: Line[] = [];
        const circlesCount = naturalMode.deformations.length;
        for (let i = 0; i < circlesCount - 1; i++) {
            const linePoints = [];
            const currentVector = naturalMode.deformations[i];
            const nextVector = naturalMode.deformations[i + 1];
            const cy = this._parseVectorValue(currentVector.y);
            const cx = this._parseVectorValue(currentVector.x);
            const nx = this._parseVectorValue(nextVector.x);
            const ny = this._parseVectorValue(nextVector.y);
            const y1 = cy * viewConfig.radialScaleFactor;
            const x2 = nx - cx;
            const y2 = ny * viewConfig.radialScaleFactor;

            linePoints.push(new Vector3(0, y1, 0)); // left side start
            linePoints.push(new Vector3(x2, y2, 0)); // right side start

            const lineGeometry = new BufferGeometry().setFromPoints(linePoints);
            const lineMaterial = new LineBasicMaterial({ color: RADII_CONNECTING_LINE_COLOR, side: DoubleSide });
            const line = new Line(lineGeometry, lineMaterial);

            line.position.x = cx;

            radiiConnectingLine.push(line);
        }
        return radiiConnectingLine;
    }

    private _parseVectorValue(vector: number): number {
        let vectorValue = 0;
        const parsedValue = parseFloat(vector.toString());
        vectorValue = parsedValue === Infinity || parsedValue === -Infinity || Number.isNaN(parsedValue) ? 0 : parsedValue;
        return vectorValue;
    }

    private animate(timeDelta: number | null) {
        if (this._naturalModeView && timeDelta) {
            const rotationalDirection = this._clockwise ? 1 : -1;
            const angle = rotationalDirection * timeDelta;
            this._naturalModeView.rotation.x -= angle;
        }
    }
}
