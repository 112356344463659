import { BearingMediator } from './bearing-mediator';
import { ModelElement } from '../../../../cae-model/model-element';
import { BearinxStandardBinder } from '../../../binders/bearinx-standard-binder';
import { RadialTaperedRoller } from '../../../../cae-model/supports/bearings/radial-tapered-roller';

// mediator, used for the convergence of RadialTaperedRoller (analog to RadialTaperedRollerData in bearinx-online)
export class RadialTaperedRollerMediator extends BearingMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_RADIAL_TAPERED_ROLLER_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new RadialTaperedRoller();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'positionReference', right: 'IDL_BEARING_POSITION_REFERENCE' },
            { left: 'xLockingSurface', right: 'IDL_X_LOCATION_LOCKING_SURFACE' },
        ];
        this.addBinder(binder);
    }
}
