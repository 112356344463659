import Konva from 'konva';
import { RIGID_STROKE } from '../../view-2d-constants';
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { SupportView2D } from '../support-view-2d';
import { RigidSupport } from '../../../../cae-model/supports/rigid-support';
import { PartType } from '../../element-view-2d';

export class RigidSupportView2D extends SupportView2D {
    public scaleX = 1;
    public scaleY = 1;
    public basePoints: number[] = [0, 176, 412, 1000, 588, 1000, 1000, 176, 1000, 0, 824, 0, 500, 648, 176, 0, 0, 0, 0, 176];

    public get transformedPoints(): number[] {
        return this.basePoints.map((point: number, index: number) => (index % 2 === 0 ? point * this.scaleX : point * this.scaleY));
    }

    private _group: Konva.Group | null = null;

    constructor(rigidSupport: RigidSupport) {
        super(rigidSupport);
    }

    protected paintElement(group: Konva.Group, geometry: SupportGeometry): void {
        this._updateRigid(group, geometry);
    }

    protected getName(): string {
        return 'rigid-view-2d-group';
    }

    private _updateRigid(group: Konva.Group, geometry: SupportGeometry): void {
        const { sB, sDO, sDI } = geometry;
        const height = (sDO - sDI) / 2.0;
        this.scaleX = sB / 1000;
        this.scaleY = height / 1000;

        const konvaLine = new Konva.Line({
            points: this.transformedPoints,
            fill: RIGID_STROKE,
            closed: true,
            strokeScaleEnabled: false,
        });

        [PartType.Up, PartType.Down].forEach(viewPartType => {
            const y = this._isViewPartUp(viewPartType) ? -sDO / 2 : sDI / 2 + height;
            const line = this._isViewPartUp(viewPartType) ? konvaLine : konvaLine.clone();
            line.y(y);
            if (!this._isViewPartUp(viewPartType)) {
                line.scaleY(-1);
            }
            group.add(line);
        });
    }

    private _isViewPartUp(viewPartType: PartType): boolean {
        return viewPartType === PartType.Up;
    }
}
