import { Mesh, BufferGeometry, Material } from 'three';
import { GearAbstract } from '../../../cae-model/transmission-elements/gear-abstract';
import { GearAbstractGeometry } from '../../../views-foundation/geometries/gear-abstract-geometry';
import { createPlaceholderGeometry, getUnitSetScaleValue } from '../../functions/utils-3d';
import { TransmissionElementView3D } from './transmission-element-view-3d';

export class GearAbstractView3D extends TransmissionElementView3D {
    constructor(private _gearAbstract: GearAbstract) {
        super(_gearAbstract);
    }

    protected setMesh(geometry: BufferGeometry, material: Material): Mesh {
        return new Mesh(geometry, material);
    }

    protected setBufferGeometry(): BufferGeometry {
        const geometry = new GearAbstractGeometry(this._gearAbstract, getUnitSetScaleValue(this.input.settings.unitSet));
        this.isOnShaft = geometry.isOnShaft;

        const { tDO, tDI, tB } = geometry;
        return createPlaceholderGeometry(this.input.settings, tDI, tDO, tB);
    }
}
