import { Mesh } from 'three';
import { Spring } from '../../../cae-model/supports/spring';
import { createRingMaterial } from '../../settings/default-materials';
import { SupportView3D } from './support-view-3d';

export class SpringView3D extends SupportView3D {
    constructor(spring: Spring) {
        super(spring);
    }

    protected setMesh(): Mesh {
        const material = createRingMaterial();
        return new Mesh(undefined, material);
    }
}
