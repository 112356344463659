import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '../../modules/form-control/form-control.module';
import { PromptModule } from '../../modules/prompt/prompt.module';
import { TitleBarModule } from '../../modules/title-bar/title-bar.module';
import { ProjectDeletionPromptComponent } from './project-deletion-prompt/project-deletion-prompt.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectFormComponent } from './project-form/project-form.component';
import { ProjectRoutingModule } from './project.routing';
import { ProjectRenameComponent } from './project-rename/project-rename.component';
import { ProjectMovePromptComponent } from './project-move-prompt/project-move-prompt.component';

@NgModule({
    imports: [CommonModule, TranslateModule, ProjectRoutingModule, PromptModule, TitleBarModule, ReactiveFormsModule, FormControlModule],
    declarations: [
        ProjectCreateComponent,
        ProjectFormComponent,
        ProjectDeletionPromptComponent,
        ProjectRenameComponent,
        ProjectMovePromptComponent,
    ],
    exports: [ProjectFormComponent],
})
export class ProjectModule {}
