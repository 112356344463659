import { Directive, Renderer2, ElementRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'input[bxInput]',
})
export class InputDirective {
    constructor(private readonly _renderer: Renderer2, public readonly element: ElementRef) {}

    public set paddingRight(padding: string) {
        this._renderer.setStyle(this.element.nativeElement, 'padding-right', padding);
    }
}
