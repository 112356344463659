import Konva from 'konva';
import { BOUNDING_BOX, LOAD_STROKE_COLOR_2D, STROKE_WIDTH, TRANSPARENT } from '../view-2d-constants';
import { ElementView2D } from '../element-view-2d';
import { PointMass } from '../../../cae-model/loads/point-mass';
import { createLoadDashLineFullLength } from '../../functions/loads-utils';

export class PointMassView2D extends ElementView2D {
    private _group: Konva.Group;

    constructor(private _pointMass: PointMass) {
        super(_pointMass);
    }

    protected get draggable() {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    private _createShapes(pointMass: PointMass): Konva.Group {
        const group = new Konva.Group({
            name: 'point-mass-view-2d-group',
            transformable: true,
        });

        const currentX = pointMass.x;
        const currentRadius = pointMass.getCurrentDiameter(currentX) / 2;
        const width = Math.max(currentRadius / 8.0, 1) / this.input.unitScaleFactor;
        const height = Math.max(currentRadius / 3.0, 1) / this.input.unitScaleFactor;

        group.add(createLoadDashLineFullLength(currentRadius));
        group.add(
            new Konva.Rect({
                stroke: LOAD_STROKE_COLOR_2D,
                strokeWidth: STROKE_WIDTH / this.input.unitScaleFactor,
                strokeScaleEnabled: false,
                width: width,
                height: height,
                fill: LOAD_STROKE_COLOR_2D,
                x: -width / 2,
                y: -height / 2,
            }),
        );

        return group;
    }

    protected updateGroupWithoutChildren(): void {
        // override basis class

        this._group = this._createShapes(this._pointMass);

        const boundingRectForUTSelection = new Konva.Rect({
            ...this._group.getClientRect({
                skipStroke: true,
            }),
            fill: TRANSPARENT,
            name: BOUNDING_BOX,
        });
        this._group.add(boundingRectForUTSelection);

        this.groupWithoutChildren.add(this._group);
    }
}
