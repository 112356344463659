import { WormGear } from './../../../cae-model/transmission-elements/worm-gear';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { BasicMediator } from '../basic-mediator';
import { ModelElement } from '../../../cae-model/model-element';
export class WormGearMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_WORM_GEAR'];
    }

    protected createModelElement(): ModelElement {
        return new WormGear();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'outerDiameter', right: 'IDTE_D' },
            { left: 'width', right: 'IDTE_B' },
            { left: 'referenceDiameter', right: 'IDWG_DM' },
            { left: 'meanPitchAngle', right: 'IDWG_GAMMA' },
            { left: 'grooveRadius', right: 'IDWG_GROOVE_RADIUS' },
        ];
        this.addBinder(binder);
    }
}
