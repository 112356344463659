import { CoordinateSystem } from '../primitives/coordinate-system';
import { Shaft } from '../../cae-model/shaft';
import { ElementView2D } from './element-view-2d';
import { OPACITY_SHAFT_CS, STROKE_COLOR_2D } from './view-2d-constants';

export class ShaftView2D extends ElementView2D {
    private _coordinateSystem: CoordinateSystem;

    constructor(shaft: Shaft) {
        super(shaft);
    }

    protected get draggable() {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    setZLevel() {
        this.groupWithoutChildren.moveToTop(); // coordinate system should be shown seen above segments
        this.groupWithChildren.moveToBottom(); // shafts should be shown below all other elements
    }

    protected updateGroupWithoutChildren(): void {
        this._coordinateSystem = new CoordinateSystem(STROKE_COLOR_2D, OPACITY_SHAFT_CS, this.input.unitScaleFactor);
        this.groupWithoutChildren.add(this._coordinateSystem.group);
    }
}
