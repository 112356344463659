export enum DataModelType {
    Languages = 'languages',
    Methods = 'methods',
    ObjectTypes = 'object-types',
    Units = 'units',
    UnitSets = 'unit-sets',
    InstallationTypes = 'installation-types',
    ProductFeatures = 'product-features',
    BearingTypes = 'bearing-types',
    MaterialCombinations = 'material-combinations',
}
