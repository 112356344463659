import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotFoundData } from './not-found-data';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'bx-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
    public data$: Observable<NotFoundData> = this._route.data.pipe(map(({ notFoundData }: { notFoundData: NotFoundData }) => notFoundData));

    constructor(private readonly _route: ActivatedRoute) {}
}
