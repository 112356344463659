import { DynamicSimulationData } from './dynamic-simulation-data';
import { ModelElement } from './model-element';

export class LoadContainer extends ModelElement {
    private _dynamicSimulationData: DynamicSimulationData;

    constructor() {
        super();
        this._dynamicSimulationData = new DynamicSimulationData();
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof DynamicSimulationData) {
            this._addDinamicSimulationData(child);
        }
    }

    private _addDinamicSimulationData(dynamicSimulationData: DynamicSimulationData): void {
        this._dynamicSimulationData = dynamicSimulationData;
    }

    public get dynamicSimulationData(): DynamicSimulationData {
        return this._dynamicSimulationData;
    }

    public set dynamicSimulationData(dynamicSimulationData: DynamicSimulationData) {
        this._dynamicSimulationData = dynamicSimulationData;
    }
}
