import { ModelElement } from '../../../cae-model/model-element';
import { KeySeat } from '../../../cae-model/notches/key-seat';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

export class KeySeatMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_KEY_SEAT'];
    }

    protected createModelElement(): ModelElement {
        return new KeySeat();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'width', right: 'IDN_HUB_WIDTH' },
            { left: 'height', right: 'IDN_KEY_HEIGHT' },
            { left: 'depth', right: 'IDN_KEY_SEAT_DEPTH' },
        ];
        this.addBinder(binder);
    }
}
