import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';

export function createLineLoadStandardBinder(): BearinxStandardBinder {
    const binder = new BearinxStandardBinder();
    binder.properties = [
        { left: 'x', right: 'IDLD_X_0' },
        { left: 'xEnd', right: 'IDLD_X_MAX' },
    ];
    return binder;
}
