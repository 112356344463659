import { IRect } from 'konva/lib/types';
import { Hub } from '../../../cae-model/notches/hub';
import { ElementView2D } from '../../elements-view/element-view-2d';
import { StandardNotchDimensions2D } from './standard-notch-dimensions-2d';

export class HubDimensions2D extends StandardNotchDimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected getNotchWidth(): number {
        return (this.elementView2D.modelElement as Hub).width;
    }
}
