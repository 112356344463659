import { ModelElement } from '../../cae-model/model-element';
import { NaturalFrequency } from '../../cae-model/natural-frequency';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { BasicMediator } from './basic-mediator';

export class NaturalFrequencyMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_DYNAMIC_SIMULATION_DATA_NATURAL_FREQUENCIES'];
    }

    protected createModelElement(): ModelElement {
        return new NaturalFrequency();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'numberOfValidModeShapes', right: 'IDDSD_NUMBER_OF_VALID_MODESHAPES' },
            { left: 'shaftRotationSpeed', right: 'IDNFD_SHAFT_ROTATION_SPEED' },
        ];
        this.addBinder(binder);
    }
}
