import { ModelElement } from '../../../cae-model/model-element';
import { Hub } from '../../../cae-model/notches/hub';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

export class HubMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_HUB'];
    }

    protected createModelElement(): ModelElement {
        return new Hub();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'width', right: 'IDN_HUB_WIDTH' }];
        this.addBinder(binder);
    }
}
