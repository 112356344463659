import { Context } from 'konva/lib/Context';
import { Group } from 'konva/lib/Group';
import { Shape, ShapeConfig } from 'konva/lib/Shape';
import { Line } from 'konva/lib/shapes/Line';
import { InternalStep } from '../../../cae-model/notches/internal-step';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { SEGMENT_COLOR_2D, STROKE_WIDTH, DISPLAYRED, TRANSPARENT } from '../view-2d-constants';
import { NotchView2D } from './notch-view-2d';

export class InternalStepView2D extends NotchView2D {
    constructor(step: InternalStep) {
        super(step);
    }

    private get _internalStep(): InternalStep {
        return this.modelElement as InternalStep;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('internal-step-view-2d-group');
    }

    protected createNotchView(): Group {
        const group = new Group();
        const { radius } = this._internalStep;
        const shapes: Shape[] = [];
        const commonAttributes = {
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            y: -this.innerRadius,
            scaleX: -1,
            offsetX: radius,
            closed: true,
        };

        const triangle = new Shape({
            ...commonAttributes,
            stroke: SEGMENT_COLOR_2D,
            fill: SEGMENT_COLOR_2D,
            sceneFunc: (context, shape) => {
                this._createDefaultPath(context);
                context.lineTo(radius, 0);
                context.closePath();
                context.fillStrokeShape(shape);
            },
        });
        shapes.push(triangle);

        const triangleTransparent = new Line({
            ...commonAttributes,
            points: [0, 0, radius, 0, radius, radius],
            stroke: TRANSPARENT,
            fill: TRANSPARENT,
        });
        shapes.push(triangleTransparent);

        const curveLine = triangle.clone();
        curveLine.stroke(DISPLAYRED);
        curveLine.sceneFunc((context: Context, shape: Shape<ShapeConfig>) => {
            this._createDefaultPath(context);
            context.fillStrokeShape(shape);
        });
        curveLine.fill(TRANSPARENT);
        shapes.push(curveLine);

        shapes.forEach(part => {
            group.add(generateUpperAndLowerParts(part, 'internalStepPart'));
        });

        return group;
    }

    private _createDefaultPath(context: Context): void {
        const { radius } = this._internalStep;
        context.beginPath();
        context.moveTo(0, 0);
        context.quadraticCurveTo(radius, 0, radius, radius);
    }
}
