import Konva from 'konva';
import { VNotch } from '../../../cae-model/notches/v-notch';
import { SI_UNIT_SCALE, TINY_RADIUS } from '../../../views-foundation/views-foundation-constants';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { BACKGROUND_COLOR_2D, DISPLAYRED, STROKE_WIDTH } from '../view-2d-constants';
import { NotchView2D } from './notch-view-2d';

export class VNotchView2D extends NotchView2D {
    constructor(rectangularGroove: VNotch) {
        super(rectangularGroove);
    }

    private get _vNotch(): VNotch {
        const notch = this.modelElement as VNotch;
        notch.unitScale = this.input?.unitScaleFactor ? this.input.unitScaleFactor : SI_UNIT_SCALE;
        return this.modelElement as VNotch;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('v-notch-view-2d-group');
    }

    protected createNotchView(): Konva.Group {
        const group = new Konva.Group();
        const vNotchDrawingInfo: Konva.Shape[] = [];
        const { xInShaftCS, depth, width } = this._vNotch;
        const outerRadius = this._vNotch.getCurrentOuterRadius(xInShaftCS);

        vNotchDrawingInfo.push(
            new Konva.Line({
                fill: BACKGROUND_COLOR_2D,
                stroke: BACKGROUND_COLOR_2D,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                points: [-width, -outerRadius * 1.01, width, -outerRadius * 1.01, 0, -outerRadius + depth],
                closed: true,
            }),
        );
        vNotchDrawingInfo.push(
            new Konva.Line({
                stroke: DISPLAYRED,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                points: [width, -outerRadius, 0, -outerRadius + depth, -width, -outerRadius],
            }),
        );
        vNotchDrawingInfo.forEach((line: Konva.Line) => {
            group.add(generateUpperAndLowerParts(line, 'v-notch'));
        });

        const innerRadius = this._vNotch.getCurrentInnerRadius(xInShaftCS);

        if (innerRadius < TINY_RADIUS / this.input.unitScaleFactor) {
            [
                [-width, 0],
                [0, depth],
                [width, 0],
            ].forEach(([xPos, yOffset]) => {
                group.add(
                    new Konva.Line({
                        stroke: DISPLAYRED,
                        strokeWidth: STROKE_WIDTH,
                        strokeScaleEnabled: false,
                        points: [xPos, -outerRadius + yOffset, xPos, outerRadius - yOffset],
                    }),
                );
            });
        }

        return group;
    }
}
