import { RollingElement } from './../../../cae-model/bearing-elements/rolling-element';
import { BufferGeometry, Mesh } from 'three';
import { createVisualizationGeometry, getVisualizationParameters } from '../../functions/create-visualization-geometry';
import { createDummyMaterial, createRollingElementMaterial } from '../../settings/default-materials';
import { BearingElementView3D } from './bearingelement-view-3d';
import { getUnitSetScaleValue, isCenterInClippingZone } from '../../functions/utils-3d';

export class RollingElementView3D extends BearingElementView3D {
    constructor(rolingElement: RollingElement) {
        super(rolingElement);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    private _hasVisualization = false;

    protected updateGroupWithoutChildren(): void {
        const geometry = this._setGeometry();
        this.mesh.geometry = geometry;
    }

    private get _rollingElement(): RollingElement {
        return this.modelElement as RollingElement;
    }

    private _createMesh(): Mesh {
        const material = createRollingElementMaterial();
        this.mesh = new Mesh(undefined, material);
        return this.mesh;
    }

    get hasVisualization(): boolean {
        return this._hasVisualization;
    }

    private _setGeometry(): BufferGeometry {
        const geometry = createVisualizationGeometry(
            getVisualizationParameters(this.input.settings, true, this._rollingElement.visualizationData),
        );
        this._hasVisualization = geometry != null;
        if (geometry == null) {
            return new BufferGeometry();
        }
        const view3dSetting = this.input.settings;
        const dummyMesh = new Mesh(geometry, createDummyMaterial());
        const isVisible = !isCenterInClippingZone(view3dSetting, dummyMesh);
        if (isVisible) {
            const scale = 1 / getUnitSetScaleValue(this.input.settings.unitSet);
            // This is a dummy model rendering, we will just scale the whole model for now.
            // In the future, we will refactor the visualization for the model, this line will be removed,
            // and move the scale value into createVisualizationGeometry()
            return geometry.scale(scale, scale, scale);
        } else {
            return new BufferGeometry();
        }
    }
}
