import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TenantService } from './tenant.service';

@Injectable({ providedIn: 'root' })
export class TenantGuard {
    constructor(private readonly _tenantService: TenantService, private readonly _router: Router) {}

    public canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
        const tenant = this._tenantService.tenant;
        if (!tenant) {
            this._router.navigate(['tenant']);
        }
        return !!tenant;
    }
}
