import { ModelElement } from '../../cae-model/model-element';
import { ModeShape } from '../../cae-model/mode-shape';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { BasicMediator } from './basic-mediator';

export class NaturalModeShapeMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_DYNAMIC_SIMULATION_MODESHAPE'];
    }

    protected createModelElement(): ModelElement {
        return new ModeShape();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'id', right: 'IDCO_IDENTIFICATION' },
            { left: 'frequency', right: 'IDMSD_FREQUENCY' },
            { left: 'modeShapeType', right: 'IDMSD_MODESHAPE_TYPE' },
            { left: 'modeShapeMostExitedShaft', right: 'IDMSD_MODESHAPE_MOST_EXITED_SHAFT' },
        ];
        this.addBinder(binder);
    }
}
