import { Mesh } from 'three';
import { Group } from 'three';
import { Bearing } from '../../../cae-model/supports/bearings/bearing';
import { createRingMaterial } from '../../settings/default-materials';
import { BearingElementView3D } from '../bearing-parts/bearingelement-view-3d';
import { SupportView3D } from './support-view-3d';

export class BearingView3D extends SupportView3D {
    constructor(bearing: Bearing) {
        super(bearing);
    }

    get selectableGroup(): Group | null {
        return this.groupWithChildren;
    }

    protected updateGroupWithoutChildren(): void {
        if (!this.isUpdatedWhenAllViewsGenerated) {
            return;
        }
        const childHasVisualization = this.children.some(child => {
            const belement3D = child as BearingElementView3D;
            return belement3D?.hasVisualization;
        });
        if (!childHasVisualization) {
            this.visualize();
        } else {
            if (this.mesh != null) {
                this.mesh.visible = false;
            }
        }
    }

    protected setMesh(): Mesh {
        const material = createRingMaterial();
        return new Mesh(undefined, material);
    }
}
