export enum ToastIconType {
    Spinner = 'autorenew',
    Lock = 'lock',
    Error = 'dangerous',
    Warning = 'warning',
    Information = 'info',
}

export interface ToastData {
    id?: number;
    groupId?: string;
    label: string;
    icon: ToastIconType;
    spinning?: boolean;
    closeIcon?: boolean;
    closeAutomatically?: boolean;
    times?: number;
    link?: string;
    linkText?: string;
}
