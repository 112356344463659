export enum BearingView2DUrls {
    SVG_SCHRAEGROLLE_EINR_URL = 'assets/icons2d/Schraegrolle_einr.svg',
    SVG_PENDELKUGELLAGER_URL = 'assets/icons2d/Pendelkugellager.svg',
    SVG_PERO1ROW_URL = 'assets/icons2d/PeRo1Row.svg',
    SVG_PERO2ROWS_URL = 'assets/icons2d/PeRo2Rows.svg',
    SVG_TORIODIAL_URL = 'assets/icons2d/Toriodial.svg',
    SVG_SX011880_URL = 'assets/icons2d/SX011880.svg',
    SVG_3309_URL = 'assets/icons2d/3309.svg',
    SVG_VIERPUNKTLAGER_URL = 'assets/icons2d/7004BE2RS.svg',
    SVG_7004BE2RS_URL = 'assets/icons2d/7004BE2RS.svg',
    SVG_AXIAL_SCHRAEGROLLE_EINR_URL = 'assets/icons2d/Axial_Schraegrolle_einr.svg',
    SVG_AXIAL_SCHRAEGROLLE_O_URL = 'assets/icons2d/Axial_Schraegrolle_O.svg',
    SVG_AXIAL_SCHRAEGROLLE_X_URL = 'assets/icons2d/Axial_Schraegrolle_X.svg',
    SVG_SCHRAEGROLLE_O_URL = 'assets/icons2d/Schraegrolle_O.svg',
    SVG_SCHRAEGROLLE_X_URL = 'assets/icons2d/Schraegrolle_X.svg',
    SVG_SCHRAEGROLLE_TANDEM_URL = 'assets/icons2d/Schraegrolle_Tandem.svg',
    SVG_KEGELROLLE_DOPPEL_O_URL = 'assets/icons2d/Kegelrolle_Doppel_O.svg',
    SVG_KEGELROLLE_DOPPEL_X_URL = 'assets/icons2d/Kegelrolle_Doppel_X.svg',
    SVG_ZKLN90150_2Z_URL = 'assets/icons2d/ZKLN90150_2Z.svg',
    SVG_ZKLN2AP_URL = 'assets/icons2d/ZKLN2AP.svg',
    SVG_ZKLF_URL = 'assets/icons2d/ZKLF.svg',
    SVG_ZKLF2AP_URL = 'assets/icons2d/ZKLF2AP.svg',
    SVG_ZKLFA_URL = 'assets/icons2d/ZKLFA.svg',
    SVG_ZARN_URL = 'assets/icons2d/ZARN.svg',
    SVG_ZARN_L_URL = 'assets/icons2d/ZARN_L.svg',
    SVG_ZARF_URL = 'assets/icons2d/ZARF.svg',
    SVG_ZARF_L_URL = 'assets/icons2d/ZARF_L.svg',
    SVG_NKXR_URL = 'assets/icons2d/NKXR.svg',
    SVG_NKX_URL = 'assets/icons2d/NKX.svg',
    SVG_NX_URL = 'assets/icons2d/NX.svg',
    SVG_YRT100_URL = 'assets/icons2d/YRT100.svg',
    SVG_NKIB_URL = 'assets/icons2d/NKIB.svg',
    SVG_DKLFA_URL = 'assets/icons2d/DKLFA.svg',
    SVG_AXIALKEGELROLLE_EINR_SYM_URL = 'assets/icons2d/AxialKegelRolle_einr_sym.svg',
    SVG_AXIALKEGELROLLEBORDGS_URL = 'assets/icons2d/AxialKegelRolleBordGS.svg',
    SVG_AXIALKEGELROLLEBORDWS_URL = 'assets/icons2d/AxialKegelRolleBordWS.svg',
    SVG_AXIALKEGELROLLE_ZWEIR_URL = 'assets/icons2d/AxialKegelRolle_zweir.svg',
    SVG_AXIALPENDELROLLE_URL = 'assets/icons2d/AxialPendelrolle.svg',
    SVG_AXIAL_NADELKRANZ_EINR_URL = 'assets/icons2d/Axial_Nadelkranz_einr.svg',
    SVG_AXIAL_NADELKRAENZE_58_ZWEIS_GS_MITTIG_URL = 'assets/icons2d/Axial_Nadelkraenze_58_zweis_gs_mittig.svg',
    SVG_AXIAL_NADELKRAENZE_58_ZWEIS_WS_MITTIG_URL = 'assets/icons2d/Axial_Nadelkraenze_58_zweis_ws_mittig.svg',
    SVG_AXIAL_NADELLAGER_EINR_URL = 'assets/icons2d/Axial_Nadellager_einr.svg',
    SVG_AXIAL_NADELLAGER_ZWEIS_GSM_URL = 'assets/icons2d/Axial_Nadellager_zweis_gsm.svg',
    SVG_AXIAL_NADELLAGER_ZWEIS_WSM_URL = 'assets/icons2d/Axial_Nadellager_zweis_wsm.svg',
    SVG_AXIAL_ROLLENLAGER_EINR_URL = 'assets/icons2d/Axial_Rollenlager_einr.svg',
    SVG_AXIAL_ROLLENLAGER_ZWEIR_URL = 'assets/icons2d/Axial_Rollenlager_zweir.svg',
    SVG_AXIAL_ROLLENLAGER_EINR_GSM_URL = 'assets/icons2d/Axial_Rollenlager_einr_gsm.svg',
    SVG_AXIAL_ROLLENLAGER_ZWEIR_GSM_URL = 'assets/icons2d/Axial_Rollenlager_zweir_gsm.svg',
    SVG_AXIAL_ROLLENLAGER_EINR_WSM_URL = 'assets/icons2d/Axial_Rollenlager_einr_wsm.svg',
    SVG_AXIAL_ROLLENLAGER_ZWEIR_WSM_URL = 'assets/icons2d/Axial_Rollenlager_zweir_wsm.svg',
}
