import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AnimationService {
    private _animate = new BehaviorSubject<number | null>(null);

    animate(timeDelta: number): void {
        this._animate.next(timeDelta);
    }

    updateAnimation(): Observable<number | null> {
        return this._animate;
    }
}
