/* eslint-disable max-len */
import { WMFImage } from './wmf-image';

export class WMF_CylindricalPlainBush extends WMFImage {
    constructor() {
        super(1001, 1001);
    }

    protected doPaint() {
        const gc = this.gc;

        gc.moveTo(0, 0);
        gc.lineTo(999, 0);
        gc.lineTo(999, 999);
        gc.lineTo(0, 999);
        gc.lineTo(0, 0);
        gc.moveTo(0, 499);
        gc.lineTo(999, 499);
    }
}
