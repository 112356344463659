import Konva from 'konva';
import { InternalBevelGear } from '../../../../cae-model/transmission-elements/internal-bevel-gear';
import { InternalBevelGearGeometry } from '../../../../views-foundation/geometries/internal-bevel-gear-geometry';
import { PartType } from '../../element-view-2d';
import { STROKE_WIDTH, BACKGROUND_COLOR_2D } from '../../view-2d-constants';
import { BevelGearBaseView2D } from '../bevel-gear-base-view-2d';

export class InternalBevelGearView2D extends BevelGearBaseView2D {
    constructor(private _internalBevelGear: InternalBevelGear) {
        super(_internalBevelGear);
    }

    protected setGeometry(): void {
        this.geometry = new InternalBevelGearGeometry(this._internalBevelGear, this.input.unitScaleFactor);
    }

    protected updateBevelGear(group: Konva.Group): void {
        const { tB, tBore, tTooth } = this.geometry;
        const { leftX, rightX, inDia, outDia, color } = super.getBevelGearInput();

        group.add(
            new Konva.Rect({
                x: leftX,
                y: -tBore / 2.0,
                width: tB,
                height: tBore,
                stroke: color,
                strokeWidth: STROKE_WIDTH,
                strokeScaleEnabled: false,
                fill: BACKGROUND_COLOR_2D,
                name: 'internal-bevel-gear-view-clickable-area',
            }),
        );

        [PartType.Up, PartType.Down].forEach(partType => {
            const isPartUp = this._isPartTypeUp(partType);
            const y1 = isPartUp ? -outDia / 2.0 : outDia / 2.0;
            const y2 = isPartUp ? -inDia / 2.0 : inDia / 2.0;
            group.add(
                new Konva.Line({
                    points: [leftX, y1, rightX, y2],
                    stroke: color,
                    strokeWidth: STROKE_WIDTH,
                    strokeScaleEnabled: false,
                }),
            );
        });

        const scale = this.input.unitScaleFactor;
        if (tTooth > 1 / scale) {
            const x = tB / 2.0 + 3.0 / scale;
            const y = -tBore / 2.0;
            group.add(super.createText(`${tTooth}`, color, x, y));
        }
    }

    private _isPartTypeUp(partType: PartType): boolean {
        return partType === PartType.Up;
    }
}
