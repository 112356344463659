<h3>{{ 'SHARED.SHARE_DIALOG.SUBTITLE' | translate }}</h3>

<label>
    <input type="radio" name="shareMethod" checked />
    {{ 'SHARED.SHARE_DIALOG.TYPE.BY_LINK' | translate }}
</label>

<section>
    <bx-input-container class="full-width">
        <input bxInput type="text" readonly [value]="url" />
        <bx-input-addons>
            <bx-icon icon="mail" (click)="share()" [title]="'SHARED.SHARE_DIALOG.SEND_MAIL' | translate"></bx-icon>
            <bx-icon icon="content_copy" (click)="copy()" [title]="'SHARED.SHARE_DIALOG.COPY_LINK' | translate"> </bx-icon>
        </bx-input-addons>
    </bx-input-container>
</section>

<label>
    <input type="radio" name="shareMethod" disabled />
    {{ 'SHARED.SHARE_DIALOG.TYPE.BY_USER' | translate }}
</label>
<section>
    <input bxInput type="text" readonly [placeholder]="'SHARED.SHARE_DIALOG.ADDITIONAL_USER' | translate" />
    <button class="secondary" disabled>{{ 'GLOBALS.ADD' | translate }}</button>
</section>
