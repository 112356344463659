import { InternalSpurGear } from './../../../cae-model/transmission-elements/internal-spur-gear';
import { BasicMediator } from '../basic-mediator';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { ModelElement } from '../../../cae-model/model-element';

export class InternalSpurGearMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_INTERNAL_SPUR_GEAR'];
    }

    protected createModelElement(): ModelElement {
        return new InternalSpurGear();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'innerDiameter', right: 'IDTE_DI' },
            { left: 'width', right: 'IDTE_B' },
            { left: 'workingPitchDiameter', right: 'IDSG_DM' },
            { left: 'toothCount', right: 'IDSG_NB_TOOTH_CONTACTS' },
            { left: 'helixAngleReferenceDiameter', right: 'IDSG_BETA' },
        ];
        this.addBinder(binder);
    }
}
