/**
 * CAEModel
 */

import { ModelElement } from './model-element';
import { ShaftSystem } from './shaft-system';

export class CAEModel extends ModelElement {
    private _shaftSystem: ShaftSystem;

    constructor() {
        super();
        this._shaftSystem = new ShaftSystem();
    }
    protected doAdd(child: ModelElement) {
        if (child instanceof ShaftSystem) {
            this._addShaftSystem(child);
        }
    }

    private _addShaftSystem(shaftSystem: ShaftSystem): void {
        this._shaftSystem = shaftSystem;
    }

    public get shaftSystem(): ShaftSystem {
        return this._shaftSystem;
    }

    public set shaftSystem(shaftSystem: ShaftSystem) {
        this._shaftSystem = shaftSystem;
    }
}
