import { PromptResult } from './prompt.model';
import { Subject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

@Component({
    template: '',
})
export abstract class BasePrompt implements OnDestroy {
    public readonly decisionMade = new Subject<PromptResult>();

    public confirm(): void {
        this.decisionMade.next(PromptResult.Confirm);
    }

    public decline(): void {
        this.decisionMade.next(PromptResult.Decline);
    }

    public cancel(): void {
        this.decisionMade.next(PromptResult.Cancel);
    }

    public choose(result: PromptResult): void {
        this.decisionMade.next(result);
    }

    ngOnDestroy(): void {
        this.decisionMade.next(PromptResult.Cancel);
    }
}
