import { Injectable, OnDestroy } from '@angular/core';
import { ModelElement } from '../../cae-model/model-element';
import { ElementView3D } from '../elements-view/element-view-3d';

// ToDo: implement find view from element
@Injectable()
export class ModelElementFinder3DService implements OnDestroy {
    private _viewModelMap: Map<ElementView3D, ModelElement>;
    private _modelViewMap: Map<ModelElement, ElementView3D>;

    constructor() {
        this._viewModelMap = new Map<ElementView3D, ModelElement>();
        this._modelViewMap = new Map<ModelElement, ElementView3D>();
    }

    ngOnDestroy(): void {
        this.clearViews();
    }

    addView(modelElement: ModelElement, view: ElementView3D) {
        this._viewModelMap.set(view, modelElement);
        this._modelViewMap.set(modelElement, view);
    }

    clearViews(): void {
        this._viewModelMap.clear();
        this._modelViewMap.clear();
    }

    getModelElement(id: ElementView3D): ModelElement | undefined {
        return this._viewModelMap.get(id);
    }

    getView(elementId: string): ElementView3D | undefined {
        return this.views.find((view: ElementView3D) => view.modelElement.id === elementId);
    }

    get views(): ElementView3D[] {
        return Array.from(this._viewModelMap.keys());
    }
}
