import Konva from 'konva';
import { AxisArrowType, LoadType } from '../../bearinx-mediator/functions/loads-properties';
import { AxisInputArrow, AxisInputCircle } from '../../bearinx-mediator/functions/axis-input-interface';
import {
    createAxisArrow,
    createAxisCircle,
    createAxisInputArrow,
    createAxisInputCirlCle,
    createConnectLine,
    getStartEndArrowLength,
} from '../functions/loads-utils';
import { DistributedLoad } from '../../cae-model/loads/distributed-load';
import { ARROW_HEAD_LENGTH_2D, DISTRIBUTED_LOAD_TORQUE_NAME, STROKE_WIDTH } from '../elements-view/view-2d-constants';

export class DistributedLoadTorque {
    private _group: Konva.Group;

    constructor(private _distributedLoad: DistributedLoad, private _unitScaleFactor: number) {
        this._group = this._createShapes();
    }

    get group(): Konva.Group {
        return this._group;
    }

    private _createShapes(): Konva.Group {
        const group = new Konva.Group({
            name: DISTRIBUTED_LOAD_TORQUE_NAME,
        });
        const arrowLength = this._getArrowLength();
        const { torqueXStart, torqueYStart, torqueZStart, torqueXEnd, torqueYEnd, torqueZEnd } = this._distributedLoad;
        const circleRadius = arrowLength * 0.2;
        const endPosition = this._distributedLoad.xEnd - this._distributedLoad.x;

        const maxArrowLength = arrowLength + circleRadius + (STROKE_WIDTH * 2) / this._unitScaleFactor;

        const inputArrows: AxisInputArrow[] = [];
        // Create Vector X
        if (torqueXStart !== 0 || torqueXEnd !== 0) {
            inputArrows.push(
                ...createAxisInputArrow({
                    loadValueStart: torqueXStart,
                    loadValueEnd: torqueXEnd,
                    maxArrowLength: maxArrowLength,
                    axisArrowType: AxisArrowType.X,
                    loadType: LoadType.Torque,
                    endPosition: endPosition,
                    unitScaleFactor: this._unitScaleFactor,
                }),
            );
        }
        // Create Vector Y and Connect Line
        if (torqueYStart !== 0 || torqueYEnd !== 0) {
            const { startArrowLength, endArrowLength } = getStartEndArrowLength(torqueYStart, torqueYEnd, maxArrowLength);
            group.add(createConnectLine(torqueYStart, torqueYEnd, endPosition, startArrowLength, endArrowLength));
            inputArrows.push(
                ...createAxisInputArrow({
                    loadValueStart: torqueYStart,
                    loadValueEnd: torqueYEnd,
                    maxArrowLength: maxArrowLength,
                    axisArrowType: AxisArrowType.Y,
                    loadType: LoadType.Torque,
                    endPosition: endPosition,
                    unitScaleFactor: this._unitScaleFactor,
                }),
            );
        }
        inputArrows.forEach(axisInput => {
            group.add(createAxisArrow(axisInput));
        });

        const inputCircles: AxisInputCircle[] = [];
        // Create Vector Z
        inputCircles.push(
            ...createAxisInputCirlCle({
                loadValueStart: torqueZStart,
                loadValueEnd: torqueZEnd,
                circleRadius: circleRadius,
                loadType: LoadType.Torque,
                endPosition: endPosition,
                unitScaleFactor: this._unitScaleFactor,
            }),
        );
        inputCircles.forEach(axisInput => {
            group.add(createAxisCircle(axisInput));
        });

        return group;
    }

    private _getArrowLength(): number {
        return (ARROW_HEAD_LENGTH_2D * 2) / this._unitScaleFactor;
    }
}
