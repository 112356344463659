import { ShaftSegment } from '../../../cae-model/shaft-segments/shaft-segment';
import { MathUtils, Mesh, BufferGeometry } from 'three';
import { ElementView3D } from '../element-view-3d';
import { clipMesh } from '../../functions/clip-mesh';
import { createShaftSegmentMaterial } from '../../settings/default-materials';

export abstract class ShaftSegmentView3D extends ElementView3D {
    protected abstract createGeometry(): BufferGeometry;

    constructor(shaftSegment: ShaftSegment) {
        super(shaftSegment);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    protected updateGroupWithoutChildren(): void {
        const geometry = this.createGeometry();
        this.mesh.geometry = geometry;
        this.mesh.geometry = clipMesh(this.mesh, this.input.settings, this.groupWithoutChildren).geometry;
        this.mesh.position.x = this._shaftSegment.length / 2;
    }

    private get _shaftSegment(): ShaftSegment {
        return this.modelElement as ShaftSegment;
    }

    private _createMesh(): Mesh {
        const geometry = this.createGeometry();
        const material = createShaftSegmentMaterial();
        this.mesh = new Mesh(geometry, material);
        this.mesh.rotation.z = MathUtils.degToRad(90);
        return this.mesh;
    }
}
