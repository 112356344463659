import { PointLoad } from '../../cae-model/loads/point-load';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { BearinxLoadDependentBinder } from '../binders/bearinx-load-dependent-binder';

export class PointLoadMediator extends BasicMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_LOAD_CASE_OVERLAPPING_POINT_LOAD'];
    }

    protected createModelElement(): ModelElement {
        return new PointLoad();
    }

    private _createBinder() {
        const binder = new BearinxLoadDependentBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_LOADCASE_DEPENDENT_X' },
            { left: 'forceX', right: 'IDLD_FX' },
            { left: 'forceY', right: 'IDLD_FY' },
            { left: 'forceZ', right: 'IDLD_FZ' },
            { left: 'torqueX', right: 'IDLD_MX' },
            { left: 'torqueY', right: 'IDLD_MY' },
            { left: 'torqueZ', right: 'IDLD_MZ' },
        ];
        this.addBinder(binder);
    }
}
