/* eslint-disable max-len */
import { WMFImage } from './wmf-image';
import { XForm } from './x-form';

export class WMF_RadialBallBearing extends WMFImage {
    withoutInnerRing_: boolean;
    numberOfRows_: number;
    dx_: number;
    dy_: number;
    gapDistance_: number;

    constructor() {
        super(1001, 1001);
    }

    protected doPaint() {
        const gc = this.gc;
        const yOffset = this.withoutInnerRing_ ? -100 : 0;
        const xform = new XForm(
            -this.width_,
            0,
            this.width_ * 2,
            this.height_ * 2,
            this.numberOfRows_ * 300,
            this.withoutInnerRing_ ? 200 : 300,
            false,
            false,
        );
        let ringFactor = 1.0;

        if (!this.withoutInnerRing_) {
            gc.moveTo(xform.calculateX(0), xform.calculateY(0));
            gc.lineTo(xform.calculateX(this.numberOfRows_ * 300), xform.calculateY(0));
            gc.moveTo(xform.calculateX(0), xform.calculateY(150 - 50 * this.gapDistance_));
            gc.lineTo(xform.calculateX(this.numberOfRows_ * 300), xform.calculateY(150 - 50 * this.gapDistance_));

            this.roundAngle(
                xform.calculateX(0),
                xform.calculateY(300),
                xform.calculateX(this.numberOfRows_ * 200),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(30),
                xform.calculateVerticalRadius(30),
            );
            this.roundAngle(
                xform.calculateX(this.numberOfRows_ * 300),
                xform.calculateY(300),
                xform.calculateX(100),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(30),
                xform.calculateVerticalRadius(30),
            );
        } else {
            this.roundAngle(
                xform.calculateX(0),
                xform.calculateY(200),
                xform.calculateX(this.numberOfRows_ * 200),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(30),
                xform.calculateVerticalRadius(30),
            );
            this.roundAngle(
                xform.calculateX(this.numberOfRows_ * 300),
                xform.calculateY(200),
                xform.calculateX(100),
                xform.calculateY(0),
                xform.calculateHorizontalRadius(30),
                xform.calculateVerticalRadius(30),
            );
            ringFactor = 1.5;
        }
        gc.moveTo(xform.calculateX(0), xform.calculateY(150 + 50 * this.gapDistance_ + yOffset));
        gc.lineTo(xform.calculateX(this.numberOfRows_ * 300), xform.calculateY(150 + 50 * this.gapDistance_ + yOffset));

        let curOffset = 150;
        const cy = curOffset;
        let xRadius = 0;
        let yRadius = 0;

        if ((this.dx_ * (1.0 / this.dy_)) / this.numberOfRows_ / ringFactor > 1) {
            xRadius = ((100 * this.dy_) / this.dx_) * this.numberOfRows_ * ringFactor;
            yRadius = 100;
        } else {
            xRadius = 100;
            yRadius = (100.0 * this.dx_) / this.dy_ / this.numberOfRows_ / ringFactor;
        }
        for (let i = 0; i < this.numberOfRows_; i++) {
            gc.ellipse(
                xform.calculateX(curOffset - xRadius),
                xform.calculateY(cy - yRadius + yOffset),
                xform.calculateHorizontalRadius(xRadius * 2),
                xform.calculateVerticalRadius(yRadius * 2),
            );
            curOffset += 300;
        }
    }
}
