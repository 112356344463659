import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, NEVER, Observable, timer } from 'rxjs';
import { catchError, map, mapTo, startWith, switchMap, tap } from 'rxjs/operators';
import { LoggingService } from '../error-handling';
import { LockTokenApiService } from './lock-token-api.service';
import { LockToken } from './lock-token.model';

export const REFRESH_TOKEN_AFTER_PERCENT_OF_LIFESPAN = 0.75;
export const MINIMUM_MILLISECONDS_REQUIRED_FOR_REFRESH = 10 * 60 * 1000;

export class LockTokenRefreshEvent {
    constructor(public readonly lockToken: LockToken) {}
}

export class LockTokenExpireEvent {}

@Injectable()
export class LockTokenRefreshService {
    private readonly _pageVisible$ = fromEvent(this._document, 'visibilitychange').pipe(
        startWith(undefined),
        map(() => this._document.visibilityState === 'visible'),
    );

    constructor(
        @Inject(DOCUMENT) private readonly _document: any,
        private readonly _lockTokenApiService: LockTokenApiService,
        private readonly _logger: LoggingService,
    ) {}

    public refreshBeforeExpiry(token: LockToken | null): Observable<LockTokenRefreshEvent | LockTokenExpireEvent> {
        return this._pageVisible$.pipe(
            switchMap(visible => {
                if (!token) {
                    this._logger.log('[LockTokenRefreshService] Lock token is not set, will not refresh.');
                    return NEVER;
                }

                if (!visible) {
                    this._logger.log('[LockTokenRefreshService] Document is not visible, will not refresh.');
                    return NEVER;
                }

                this._logger.log('[LockTokenRefreshService] Token is set and document visible, will refresh.');
                return this._scheduleRefreshOrExpireEvent(token);
            }),
        );
    }

    private _scheduleRefreshOrExpireEvent(lockToken: LockToken): Observable<LockTokenRefreshEvent | LockTokenExpireEvent> {
        const expiresAt = new Date(lockToken.expireAt);
        const now = new Date();
        const millisecondsUntilExpiry = Math.max(0, expiresAt.getTime() - now.getTime());
        if (millisecondsUntilExpiry < MINIMUM_MILLISECONDS_REQUIRED_FOR_REFRESH) {
            const expiryDate = new Date(now.getTime() + millisecondsUntilExpiry);
            const logMessage = millisecondsUntilExpiry === 0 ? 'Token already expired' : 'Not enough time to refresh';
            this._logger.log(`[LockTokenRefreshService] ${logMessage}, send event at ${expiryDate.toLocaleString()}.`);
            return timer(millisecondsUntilExpiry).pipe(
                tap(() => this._logger.log('[LockTokenRefreshService] Token expired.')),
                mapTo(new LockTokenExpireEvent()),
            );
        }

        const millisecondsUntilRefresh = Math.floor(millisecondsUntilExpiry * REFRESH_TOKEN_AFTER_PERCENT_OF_LIFESPAN);
        const retryDate = new Date(now.getTime() + millisecondsUntilRefresh);
        this._logger.log(
            `[LockTokenRefreshService] Lock token expires at ${expiresAt.toLocaleString()}, attempt refresh at ` +
                `${REFRESH_TOKEN_AFTER_PERCENT_OF_LIFESPAN * 100}% of remaining lifespan (${retryDate.toLocaleString()})`,
        );
        return timer(millisecondsUntilRefresh).pipe(
            switchMap(() => this._lockTokenApiService.update(lockToken.documentType, lockToken.documentId)),
            tap(() => this._logger.log('[LockTokenRefreshService] Token successfully refreshed.')),
            map(token => new LockTokenRefreshEvent(token)),
            catchError(() => {
                this._logger.log('[LockTokenRefreshService] Token could not be refreshed, try again.');
                return this._scheduleRefreshOrExpireEvent(lockToken);
            }),
        );
    }
}
