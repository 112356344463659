import { Injectable } from '@angular/core';
import { MousetrapStatic } from 'mousetrap';
import { Observable, Observer } from 'rxjs';
import { WindowRef } from '../util/window-ref';

interface ScopedHotkey {
    hotkey: string;
    scope: HTMLElement | 'global';
}

interface MousetrapWindow extends Window {
    Mousetrap: MousetrapStatic;
}

@Injectable({ providedIn: 'root' })
export class HotkeyService {
    private readonly _window: MousetrapWindow;
    private readonly _hotkeyObservers: Map<ScopedHotkey, Observer<void>> = new Map();

    constructor(windowRef: WindowRef) {
        this._window = windowRef.nativeWindow as MousetrapWindow;

        if (!this._window.Mousetrap) {
            throw new Error('Mousetrap not found.');
        }
    }

    // todo: fix that BS
    public register(hotkey: string, scope: HTMLElement | 'global' = 'global'): Observable<string> {
        return Observable.create((observer: Observer<any>) => {
            this._hotkeyObservers.set({ hotkey, scope }, observer);

            // TODO Observable.fromEvent
            const mousetrap = this._getMousetrap(scope);
            const hotkeys = hotkey.split(',');
            mousetrap.bind(hotkeys, (_: any, combo: any) => observer.next(combo));

            return () => {
                mousetrap.unbind(hotkeys);
            };
        });
    }

    public unregister(hotkey: string, scope: HTMLElement) {
        if (!this._hotkeyObservers.has({ hotkey, scope })) {
            //     throw new Error('Unregistering from hotkey not possible. Not found.');
        }

        const observer = this._hotkeyObservers.get({ hotkey, scope })!;
        // todo: fix also this BS
        if (observer) {
            observer.complete();
        }
    }

    private _getMousetrap(scope: HTMLElement | 'global') {
        return scope === 'global' ? this._window.Mousetrap : this._window.Mousetrap(scope);
    }
}
