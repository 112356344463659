import { DimensionsType2D, View2DSettings } from '../settings/view-2d-settings';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UnitSet } from '../../views-foundation/view-foundation-settings';

export function createDefaultView2DSettings(): View2DSettings {
    return { showDimensions: DimensionsType2D.DimensionsOfElement, unitSet: UnitSet.SI };
}

@Injectable()
export class View2DSettingsService {
    private _settings = new BehaviorSubject<View2DSettings>(createDefaultView2DSettings());

    getSettings(): Observable<View2DSettings> {
        return this._settings;
    }

    setSettings(settings: View2DSettings): void {
        this._settings.next(settings);
    }
}
