import { Load } from './load';

export class PointLoad extends Load {
    forceX = 0.0;
    forceY = 0.0;
    forceZ = 0.0;
    torqueX = 0.0;
    torqueY = 0.0;
    torqueZ = 0.0;
}
