import { VisualizationDataParameters } from '../interfaces/visualization-data.parameters';
import { Curve } from '../curves/curve';
import { CurveLnIna } from '../curves/curve-ln-ina';
import { CurveStraightLine } from '../curves/curve-straight-line';
import { CurveArc } from '../curves/curve-arc';
import { SolidInterface } from '../interfaces/solid-interface';
import { Vector2 } from 'three';
import { parseCoordinates } from '../../cae-model/functions/cae-model-utils';

interface ParsingOutput {
    lastReadLine: number;
    solidInterface: SolidInterface;
}

interface ParsingInput {
    visData: string[];
    useCustomAmount: boolean;
    customAmount: number;
}

/* eslint-disable sonarjs/cognitive-complexity */

function parseSolidData(input: ParsingInput): ParsingOutput {
    const visData = input.visData;
    let deltaIdx = -1;
    const hasMaterialData = parseInt(visData[1 + deltaIdx], 10) === 1;
    if (hasMaterialData) {
        deltaIdx += 13;
    }

    let amount = parseFloat(visData[12 + deltaIdx]);

    if (input.useCustomAmount) {
        amount = input.customAmount;
    }

    const coorPart = visData.slice(2 + deltaIdx, 8 + deltaIdx);
    const coordinates = parseCoordinates(coorPart);

    const vData: SolidInterface = {
        hasMaterialData: hasMaterialData,
        coordinates: coordinates,
        numberOfPlanes: parseFloat(visData[8 + deltaIdx]),
        rotate: parseInt(visData[9 + deltaIdx], 10) === 1,
        planeOrientation: parseInt(visData[10 + deltaIdx], 10),
        yAxis: parseInt(visData[11 + deltaIdx], 10),
        amount: amount,
        a00: parseFloat(visData[13 + deltaIdx]),
        a01: parseFloat(visData[14 + deltaIdx]),
        a02: parseFloat(visData[15 + deltaIdx]),
        a10: parseFloat(visData[16 + deltaIdx]),
        a11: parseFloat(visData[17 + deltaIdx]),
        a12: parseFloat(visData[18 + deltaIdx]),
        halfCrossSection: parseInt(visData[19 + deltaIdx], 10) === 1,
        lineSegments: new Array<Curve>(),
    };
    const lineSegmentsCount = parseInt(visData[20 + deltaIdx], 10);

    let lineIndex = 21 + deltaIdx;
    while (true) {
        if (lineSegmentsCount === vData.lineSegments.length || lineIndex >= visData.length) {
            break;
        }
        if (parseInt(visData[lineIndex], 10) === 0) {
            const sx = parseFloat(visData[lineIndex + 1]);
            const sy = parseFloat(visData[lineIndex + 2]);
            const straitLine = new CurveStraightLine(new Vector2(sx, sy), vData.planeOrientation);
            vData.lineSegments.push(straitLine);
            lineIndex += 3;
        } else if (parseInt(visData[lineIndex], 10) === 1) {
            const sx = parseFloat(visData[lineIndex + 1]);
            const sy = parseFloat(visData[lineIndex + 2]);
            const radius = parseFloat(visData[lineIndex + 3]);

            let radiusScale = 1.0;
            if (vData.planeOrientation === 0) {
                radiusScale = -1.0;
            }

            vData.lineSegments.push(new CurveArc(new Vector2(sx, sy), vData.planeOrientation, radius * radiusScale));
            lineIndex += 4;
        } else if (parseInt(visData[lineIndex], 10) === 2) {
            const sx = parseFloat(visData[lineIndex + 1]);
            const sy = parseFloat(visData[lineIndex + 2]);
            const a = parseFloat(visData[lineIndex + 3]);
            const b = parseFloat(visData[lineIndex + 4]);
            const c = parseFloat(visData[lineIndex + 5]);
            vData.lineSegments.push(new CurveLnIna(new Vector2(sx, sy), vData.planeOrientation, a, b, c));
            lineIndex += 6;
        } else {
            console.log('warning: invalid object ID: ' + vData[lineIndex]);
            lineIndex += 1;
        }
    }

    for (let s = 0; s < vData.lineSegments.length; s += 1) {
        const c: Curve = vData.lineSegments[s];
        if (s < vData.lineSegments.length - 1) {
            const nextCurve: Curve = vData.lineSegments[s + 1];
            c.setEndPoint(nextCurve.getStartPoint());
        } else {
            const nextCurve: Curve = vData.lineSegments[0];
            c.setEndPoint(nextCurve.getStartPoint());
        }
    }

    if (vData.halfCrossSection) {
        for (let s = vData.lineSegments.length - 1; s >= 0; s -= 1) {
            vData.lineSegments.push(vData.lineSegments[s].getMirroredClone());
        }
        const nSegments = vData.lineSegments.length;
        vData.lineSegments.splice(nSegments / 2 - 1, 2);
    }

    return {
        lastReadLine: lineIndex,
        solidInterface: vData,
    };
}

export function parseVisualizationData(parameters: VisualizationDataParameters): SolidInterface[] {
    const rawVisData = parameters.visualizationData;
    let visData = rawVisData.split(' ');
    const solidsCount = parseFloat(visData[0]);
    visData.shift();
    let countReadLInes = 0;
    const output = new Array<SolidInterface>();
    let curSolid = 0;
    while (countReadLInes < visData.length && curSolid < solidsCount) {
        const parsingOutput = parseSolidData({
            visData: visData,
            useCustomAmount: parameters.useCustomAmount,
            customAmount: parameters.customAmount,
        });
        countReadLInes = parsingOutput.lastReadLine;
        output.push(parsingOutput.solidInterface);
        visData = visData.slice(countReadLInes);
        countReadLInes = 0;
        curSolid++;
    }
    return output;
}
