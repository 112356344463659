import { Support } from './../../../cae-model/supports/support';
import { ElementView3D } from '../element-view-3d';
import { SupportGeometry } from '../../../views-foundation/geometries/support-geometry';
import { Mesh } from 'three';
import { SupportViewInput } from '../../../views-foundation/interfaces/support-view-input-interface';
import { createPlaceholderGeometry, getUnitSetScaleValue } from '../../functions/utils-3d';

export abstract class SupportView3D extends ElementView3D {
    public supportViewInput: SupportViewInput;
    protected abstract setMesh(): Mesh;
    private _isUpdatedWhenAllViewsGenerated = false;
    private _supportGeometry: SupportGeometry;

    constructor(private _support: Support) {
        super(_support);
    }
    public get isUpdatedWhenAllViewsGenerated(): boolean {
        return this._isUpdatedWhenAllViewsGenerated;
    }

    get supportGeometry(): SupportGeometry {
        if (this._supportGeometry == null) {
            throw new Error('SupportGeometry is not generated yet');
        }
        return this._supportGeometry;
    }

    updateWhenAllViewsGenerated() {
        super.updateWhenAllViewsGenerated();
        this._isUpdatedWhenAllViewsGenerated = true;
        this.updateGroupWithoutChildren();
    }

    protected updateGroupWithoutChildren(): void {
        if (!this.isUpdatedWhenAllViewsGenerated) {
            return;
        }
        if (this.mesh != null) {
            this.mesh.visible = false;
        } else {
            this.visualize();
        }
    }

    protected visualize() {
        if (!this.mesh) {
            this.mesh = this.setMesh();
            this.groupWithoutChildren.add(this.mesh);
        }
        this._setSupportGeometry();
        const geometry = this.createSupportPlaceholderGeometry();
        this.mesh.geometry = geometry;
    }

    protected createSupportPlaceholderGeometry() {
        const { sDI, sDO, sB } = this.supportGeometry;
        return createPlaceholderGeometry(this.input.settings, sDI, sDO, sB);
    }

    private _setSupportGeometry() {
        this._supportGeometry = this.supportViewInput.createGeometry(this._support, getUnitSetScaleValue(this.input.settings.unitSet));
    }
}
