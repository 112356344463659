import { ModelElement } from '../../cae-model/model-element';
import { VISUALIZATION_DATA_BX } from '../bearinx-mediator-constants';
import { BearinxModelObject } from '../bearinx-model-object';
import { ParametersDataBinder } from '../binders/parameters-data-binder';

const PROPERTIES_GROUP = 'properties';

export function isOfType(bearinxModelObject: BearinxModelObject, type: string): boolean {
    return bearinxModelObject.subTypes.includes(type);
}

export function getProperty(modelObj: BearinxModelObject, name: string): number | string {
    if (modelObj) {
        // ToDo: use guess value
        for (const prop of modelObj[PROPERTIES_GROUP]) {
            if (prop['name'] === name) {
                return prop['value'];
            }
        }
    }
    const errorStr = 'Property ' + name + ' does not exist';
    throw new Error(errorStr);
}

export function getDimensionDependentProperty(modelObj: BearinxModelObject, name: string, dimension1: number): number | string {
    if (modelObj) {
        // ToDo: use guess value
        for (const prop of modelObj[PROPERTIES_GROUP]) {
            if (prop['name'] === name && prop['dimension1'] === dimension1) {
                return prop['value'];
            }
        }
    }
    const errorStr = 'Property ' + name + ' for the load case ' + dimension1 + ' does not exist';
    throw new Error(errorStr);
}

export function setProperty(modelObj: BearinxModelObject, name: string, value: number | string) {
    const propChange = modelObj[PROPERTIES_GROUP].filter((prop: { [x: string]: string }) => prop['name'] === name);
    if (propChange.length > 0) {
        propChange[0]['value'] = value;
    } else {
        const errorStr = 'Cannot set property ' + name;
        throw new Error(errorStr);
    }
}

export function createModelElement<T extends ModelElement>(bearinxObject: BearinxModelObject, c: new () => T): T {
    const output = new c();
    output.id = bearinxObject.id;
    return output;
}

export interface HierarchicalInterface {
    id: string;
    children: HierarchicalInterface[];
    type?: string;
}

/// get all objects and children objects
export function hierarchicalFind(tree: HierarchicalInterface[], func: (node: HierarchicalInterface) => boolean): HierarchicalInterface[] {
    const output: HierarchicalInterface[] = [];
    for (const node of tree) {
        if (func(node)) {
            output.push(node);
        }
        if (node.children) {
            const foundChildren = hierarchicalFind(node.children, func);
            output.push(...foundChildren);
        }
    }
    return output;
}

/// get all objects and children objects
export function hierarchicalFindFirst(
    tree: HierarchicalInterface[],
    findRequierement: (node: HierarchicalInterface) => boolean,
): HierarchicalInterface | null {
    for (const node of tree) {
        if (findRequierement(node)) {
            return node;
        }
        const desiredNode = hierarchicalFindFirst(node.children, findRequierement);
        if (desiredNode) {
            return desiredNode;
        }
    }
    return null;
}

export function findObjectById(tree: HierarchicalInterface[], nodeId: string): HierarchicalInterface {
    const findFunc = (node: { id: string }) => node.id === nodeId;
    return hierarchicalFindFirst(tree, findFunc)!;
}

type hierarchicalInterfaceConstructor<T extends HierarchicalInterface> = new (...args: any[]) => T;
export function hierarchicalFindObjectsOfType<T extends HierarchicalInterface>(
    tree: HierarchicalInterface[],
    className: hierarchicalInterfaceConstructor<T>,
): T[] {
    const findFunc = (node: HierarchicalInterface) => node instanceof className;
    const output = hierarchicalFind(tree, findFunc);
    return output as T[];
}

export function hierarchicalFindFirstObjectOfType<T extends HierarchicalInterface>(
    tree: HierarchicalInterface[],
    className: hierarchicalInterfaceConstructor<T>,
): T {
    const findFunc = (node: HierarchicalInterface) => node instanceof className;
    const output = hierarchicalFindFirst(tree, findFunc);
    return output as T;
}

export function createVisualizationDataBinder(): ParametersDataBinder {
    const binder = new ParametersDataBinder();
    binder.properties = [{ left: 'visualizationData', right: VISUALIZATION_DATA_BX }];
    return binder;
}
