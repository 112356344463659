import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AboutService {
    constructor(private readonly httpClient: HttpClient, private readonly translateService: TranslateService) {}

    public loadThirdPartyLicenses(): Observable<string> {
        // NOTE: The 3rdpartylicenses.txt file is only generated during a productive build. It will not show when using `ng serve`.
        return this.httpClient
            .get('./3rdpartylicenses.txt', { responseType: 'text' })
            .pipe(catchError(() => this.translateService.get('BEARINX.ABOUT.THIRD_PARTY_LICENSES.LOADING_FAILED')));
    }
}
