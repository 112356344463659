import { Unbalance, UnbalanceType } from '../../cae-model/loads/unbalance';
import { degToRad } from '../../views-foundation/functions/views-foundation-utils';
import { UNBALANCE_MIN_RADIAL_DISP } from '../elements-view/view-2d-constants';

function getRadialAmplitude(unbalance: Unbalance): number {
    if (unbalance.unbalanceType === UnbalanceType.Calculate) {
        return unbalance.radialMissalignment;
    }
    return Math.max(unbalance.getCurrentDiameter(unbalance.x) / 2, UNBALANCE_MIN_RADIAL_DISP);
}

export function getDistalCircleYCoordinate(unbalance: Unbalance): number {
    const angleXInRad = degToRad(unbalance.startAngleAboutX);
    const amplitude = getRadialAmplitude(unbalance);
    return amplitude * Math.cos(angleXInRad);
}
