import * as THREE from 'three';
import { Box3, Mesh, Group, Vector3 } from 'three';
import { CSG } from 'three-csg-ts';
import { createDummyMaterial } from '../settings/default-materials';
import { ClippingPlaneType, ClippingType, View3DSettings } from '../settings/view-3d-settings';

function calcNormInfinity(vec: Vector3): number {
    return Math.max(Math.abs(vec.x), Math.abs(vec.y), Math.abs(vec.z));
}

function getMaxDimension(mesh: THREE.Mesh): number {
    const bbox = new Box3();
    bbox.setFromObject(mesh);
    const dimensions = new Vector3();
    bbox.getSize(dimensions);
    const maxDim = calcNormInfinity(dimensions);
    const center = new Vector3();
    bbox.getCenter(center);

    const centerMax = calcNormInfinity(center);
    return centerMax + maxDim;
}

function getMaxTranslation(group: Group): number {
    group.updateMatrixWorld();
    const pos = new Vector3();
    pos.setFromMatrixPosition(group.matrixWorld);
    return calcNormInfinity(pos);
}

// make half or quater clipping of a mesh where cliping planes are defined in the group coordiante system
export function clipMesh(mesh: THREE.Mesh, settings: View3DSettings, group: Group): Mesh {
    if (settings == null) {
        return mesh;
    }
    if (settings.clippingType === ClippingType.Off) {
        return mesh;
    }

    const dimension = (getMaxDimension(mesh) + getMaxTranslation(group)) * 10;
    const cubeGeometry = new THREE.BoxGeometry(dimension, dimension, dimension);

    const clippingCube = new THREE.Mesh(cubeGeometry, createDummyMaterial());
    const position = clippingCube.position;

    const sign = settings.flipClippingPlane ? 1 : -1;
    const displ = (sign * dimension) / 2;
    if (settings.clippingPlane === ClippingPlaneType.XY) {
        position.z = displ;
    } else if (settings.clippingPlane === ClippingPlaneType.XZ) {
        position.y = displ;
    } else {
        throw new Error('Cannot clip. Unknown ClippingPlaneType');
    }

    if (settings.clippingType === ClippingType.Quarter) {
        if (settings.clippingPlane === ClippingPlaneType.XY) {
            position.y = displ;
        } else if (settings.clippingPlane === ClippingPlaneType.XZ) {
            position.z = -displ;
        } else {
            throw new Error('Cannot clip. Unknown ClippingPlaneType');
        }
    }

    clippingCube.updateMatrixWorld(true);

    const groupNew = new Group();
    groupNew.applyMatrix4(group.matrixWorld);
    groupNew.add(clippingCube);
    groupNew.updateMatrixWorld();

    return CSG.subtract(mesh, clippingCube);
}
