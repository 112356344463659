import { ErrorHandler, Injectable, Injector, isDevMode } from '@angular/core';
import { ErrorMessagesService } from '../error-messages/error-messages.service';
import { loadAppConfig } from 'src/modules/util/util';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private _injector: Injector) {}

    handleError(error: any): void {
        const appConfig = loadAppConfig();

        if (isDevMode()) {
            console.error(error);
        }

        if ('suppressMessage' in error && error.suppressMessage) {
            return;
        }

        if (error.url?.startsWith(appConfig.helpBaseUrl)) {
            return;
        }

        const errorMessage = this._extractErrorMessage(error);
        const errorMessagesService = this._injector.get<ErrorMessagesService>(ErrorMessagesService);
        errorMessagesService.displayErrorMessage(errorMessage);
    }

    private _extractErrorMessage(error: any): string {
        if (error.rejection) {
            return error.rejection;
        }
        if (error.error && (typeof error.error === 'string' || error.error instanceof String)) {
            return error.error;
        }
        if (error.message) {
            return error.message;
        }
        return error.toString();
    }
}
