import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../../auth/authentication.service';
import { ModelService } from '../../model/model.service';

@Component({
    selector: 'bx-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent {
    public expanded = false;

    public readonly user$ = this._authenticationService.user$;
    public readonly disabled$ = this._modelService.model$.pipe(map(model => !!model));

    constructor(private readonly _authenticationService: AuthenticationService, private readonly _modelService: ModelService) {}

    public expand() {
        this.expanded = !this.expanded;
    }
}
