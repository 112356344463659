import { Injectable } from '@angular/core';
import { WebGLRenderer, Scene, PerspectiveCamera, Vector3, AmbientLight } from 'three';
import { createCoordinateSystem } from '../functions/utils-3d';
import {
    AMBIENT_LIGHT_COLOR,
    AMBIENT_LIGHT_INTENSITY,
    AXES_ARROW_BODY_HEIGHT,
    AXES_ARROW_BODY_WIDTH,
    AXES_ARROW_HEAD_HEIGHT,
    AXES_ARROW_HEAD_WIDTH,
    RENDERER_BACKGROUND_COLOR,
} from '../settings/view-3d-constants';

@Injectable()
export class View3DAxesRendererService {
    // classes used to visualize small scene with axes
    private _rendererAxes: WebGLRenderer | null;
    private _sceneAxes: Scene | null;
    private _cameraAxes: PerspectiveCamera | null;

    public init(cameraUpVector: Vector3, htmlElement: HTMLElement): void {
        // renderer
        this._rendererAxes = new WebGLRenderer({ alpha: true });
        this._rendererAxes.setClearColor(RENDERER_BACKGROUND_COLOR, 0);
        const CANVAS_WIDTH = 200;
        const CANVAS_HEIGHT = 200;
        this._rendererAxes.setSize(CANVAS_WIDTH, CANVAS_HEIGHT);
        const insetDiV = htmlElement;
        insetDiV.appendChild(this._rendererAxes.domElement);

        // scene
        this._sceneAxes = new Scene();

        const windowAny = window as any;
        if (windowAny.Cypress != null) {
            windowAny.sceneAxes = this._sceneAxes;
        }

        // camera
        this._cameraAxes = new PerspectiveCamera(50, CANVAS_WIDTH / CANVAS_HEIGHT, 1, 1000);
        this._cameraAxes.up = cameraUpVector; // important!

        // axes
        this._sceneAxes.add(new AmbientLight(AMBIENT_LIGHT_COLOR, AMBIENT_LIGHT_INTENSITY));
        this._sceneAxes.add(
            createCoordinateSystem({
                arrowBodyWidth: AXES_ARROW_BODY_WIDTH,
                arrowBodyHeight: AXES_ARROW_BODY_HEIGHT,
                arrowHeadWidth: AXES_ARROW_HEAD_WIDTH,
                arrowHeadHeight: AXES_ARROW_HEAD_HEIGHT,
                shaftId: '',
                enableLabel: true,
            }),
        );
    }

    public render(cameraPostion: Vector3, controlsTarget: Vector3, aspectRatio: number): void {
        if (this._rendererAxes && this._cameraAxes && this._sceneAxes) {
            this._cameraAxes.position.copy(cameraPostion);
            this._cameraAxes.position.sub(controlsTarget); // added by @libe
            const CAM_DISTANCE = 300;
            this._cameraAxes.aspect = aspectRatio;
            this._cameraAxes.position.setLength(CAM_DISTANCE);

            const post = this._sceneAxes.position;
            this._cameraAxes.lookAt(post);

            this._rendererAxes.render(this._sceneAxes, this._cameraAxes);
        }
    }

    public destroy(): void {
        if (this._sceneAxes != null) {
            this._sceneAxes = null;
        }

        if (this._cameraAxes != null) {
            this._cameraAxes = null;
        }

        const windowAny = window as any;
        if (windowAny.Cypress != null) {
            windowAny.sceneAxes = null;
        }

        if (this._rendererAxes != null) {
            this._rendererAxes.renderLists.dispose();
            this._rendererAxes.dispose();
            this._rendererAxes.clear();
            this._rendererAxes = null;
        }
    }
}
