import { Group } from 'konva/lib/Group';
import { Shape } from 'konva/lib/Shape';
import { Vector2d } from 'konva/lib/types';
import { Text } from 'konva/lib/shapes/Text';
import {
    FIXED_SCALE_ATTRIBUTE,
    FONT_FAMILY_2D,
    NOT_IN_STAGE_BOUNDING_BOX,
    RULER_BACKGROUND_COLOR,
    RULER_BACKGROUND_NAME,
    RULER_GROUP_NAME,
    RULER_HEIGHT,
    RULER_MARKER_X,
    RULER_MARKER_Y,
    RULER_REFERENCE_STEPS,
    RULER_STROKE_COLOR,
    RULER_STROKE_WIDTH,
    TEXT_FONT_SIZE,
    RULER_UNIT_X,
    RULER_UNIT_Y,
    Y_2D_VIEW,
} from '../elements-view/view-2d-constants';
import { Line } from 'konva/lib/shapes/Line';

enum RulerAxis {
    X,
    Y,
}

interface RulerInputInterface {
    containerSize: number;
    rulerStartPostion: number;
    scale: number;
    referenceStep: number;
}

interface RulerDataInterface {
    rulerLength: number;
    minorStep: number;
    middleStep: number;
    majorStep: number;
    rulerHeight: number;
    firstMarkerPostion: number;
}

function normalizeNumber(number: number, base: number): number {
    return Math.round(number * base) / base;
}

function getFirstMarkerPostion(rulerStartPostion: number, step: number): number {
    return Math.trunc((rulerStartPostion * 100) / (step * 100)) * step;
}

function getRulerData(rulerInputInterface: RulerInputInterface): RulerDataInterface {
    const { containerSize, rulerStartPostion, scale, referenceStep } = rulerInputInterface;

    const rulerLength = containerSize / scale;
    const minorStep = referenceStep / 10;
    const middleStep = referenceStep / 2;
    const majorStep = referenceStep;
    const rulerHeight = RULER_HEIGHT / scale;
    const firstMarkerPostion = normalizeNumber(getFirstMarkerPostion(rulerStartPostion, minorStep), 10);

    return {
        rulerLength,
        minorStep,
        middleStep,
        majorStep,
        rulerHeight,
        firstMarkerPostion,
    };
}

function createRulerBackground(containerSize: Vector2d): Shape {
    return new Shape({
        stroke: RULER_STROKE_COLOR,
        strokeWidth: RULER_STROKE_WIDTH / 2,
        strokeScaleEnabled: false,
        fill: RULER_BACKGROUND_COLOR,
        width: containerSize.x,
        height: containerSize.y,
        name: RULER_BACKGROUND_NAME,
        sceneFunc: (context, shape) => {
            context.beginPath();

            context.moveTo(0, 0);
            context.lineTo(0, containerSize.y);
            context.lineTo(RULER_HEIGHT, containerSize.y);
            context.lineTo(RULER_HEIGHT, RULER_HEIGHT);
            context.lineTo(containerSize.x, RULER_HEIGHT);
            context.lineTo(containerSize.x, 0);

            context.closePath();
            context.fillStrokeShape(shape);
        },
    });
}

function createRulerMarkers(rulerInput: RulerInputInterface, rulerAxis: RulerAxis): Group {
    const markers = new Group();
    const { rulerStartPostion, scale } = rulerInput;
    const { rulerLength, minorStep, middleStep, majorStep, rulerHeight, firstMarkerPostion } = getRulerData(rulerInput);

    for (let i = firstMarkerPostion; i < rulerLength + rulerStartPostion; i += minorStep) {
        i = normalizeNumber(i, 10);
        const markerPosition = normalizeNumber(i - rulerStartPostion, 100);

        if (markerPosition < rulerHeight) {
            continue;
        }

        let startMarker = rulerHeight * 0.78;

        if (i % majorStep === 0) {
            startMarker = rulerHeight * 0.35;
        } else if (i % middleStep === 0) {
            startMarker = rulerHeight * 0.55;
        }

        markers.add(
            new Line({
                points: [0, startMarker, 0, rulerHeight],
                x: markerPosition,
                y: 0,
                stroke: RULER_STROKE_COLOR,
                strokeWidth: RULER_STROKE_WIDTH,
                strokeScaleEnabled: false,
                name: rulerAxis === RulerAxis.Y ? RULER_MARKER_Y : RULER_MARKER_X,
            }),
        );
    }

    return markers;
}

function createRulerUnits(rulerInput: RulerInputInterface, rulerAxis: RulerAxis): Group {
    const units = new Group();

    const { rulerStartPostion, scale } = rulerInput;
    const { rulerLength, minorStep, majorStep, rulerHeight } = getRulerData(rulerInput);

    const firstMajorStepTextPosition = getFirstMarkerPostion(rulerStartPostion, majorStep);
    for (let i = firstMajorStepTextPosition; i < rulerLength + rulerStartPostion; i += majorStep) {
        const majorStepTextPositionX = i - rulerStartPostion;
        const isRulerAxisY = rulerAxis === RulerAxis.Y;

        const unit = new Text({
            text: i.toFixed(1),
            x: majorStepTextPositionX + minorStep * 0.5 * (isRulerAxisY ? -1 : 1),
            y: rulerHeight / 4,
            fontSize: TEXT_FONT_SIZE,
            fontFamily: FONT_FAMILY_2D,
            name: isRulerAxisY ? RULER_UNIT_Y : RULER_UNIT_X,
        });
        unit.scale({ x: 1 / scale, y: 1 / scale });

        if (majorStepTextPositionX - (isRulerAxisY ? unit.width() / scale : 0) < rulerHeight) {
            continue;
        }

        if (isRulerAxisY) {
            unit.scaleX(-1 / scale);
        }

        units.add(unit);
    }

    return units;
}

function createRulerAxes(rulerInput: RulerInputInterface, rulerAxis: RulerAxis): Group {
    const rulerAxes = new Group();

    rulerAxes.add(createRulerMarkers(rulerInput, rulerAxis));
    rulerAxes.add(createRulerUnits(rulerInput, rulerAxis));

    return rulerAxes;
}

export function getMeasurmentUnits(containerSize: Vector2d, scale: Vector2d): number {
    const rulerLength = Math.min(containerSize.x / scale.x, containerSize.y / scale.y);
    const maxMajorStepWidth: number = rulerLength / 3;
    let referenceStep = 1;
    const power10NonLimited = Math.floor(Math.log10(maxMajorStepWidth));
    const power10 = Math.max(power10NonLimited, 1);
    const rulerRefSteps = RULER_REFERENCE_STEPS.map(x => x * (maxMajorStepWidth < 10 ? 1 : 10 ** power10));
    rulerRefSteps.forEach((refStep: number) => {
        if (maxMajorStepWidth > refStep) {
            referenceStep = refStep;
        }
    });

    return referenceStep;
}

// create only ruler (without pouse position triangles)
export function createRuler(containerSize: Vector2d, rulerPosition: Vector2d, scale: Vector2d): Group {
    const ruler = new Group();
    ruler.name(RULER_GROUP_NAME);

    const rulerBackground = createRulerBackground(containerSize);
    rulerBackground.scaleX(1 / scale.x);
    rulerBackground.scaleY(1 / scale.y);
    ruler.add(rulerBackground);

    const referenceStep = getMeasurmentUnits(containerSize, scale);

    const rulerX = createRulerAxes(
        {
            containerSize: containerSize.x,
            rulerStartPostion: rulerPosition.x,
            scale: scale.x,
            referenceStep,
        },
        RulerAxis.X,
    );
    ruler.add(rulerX);

    const rulerY = createRulerAxes(
        {
            containerSize: containerSize.y,
            rulerStartPostion: rulerPosition.y - Y_2D_VIEW,
            scale: scale.y,
            referenceStep,
        },
        RulerAxis.Y,
    );
    rulerY.rotation(90);
    rulerY.scaleY(-1);
    ruler.add(rulerY);

    ruler.setAttr(FIXED_SCALE_ATTRIBUTE, true);
    ruler.setAttr(NOT_IN_STAGE_BOUNDING_BOX, true);

    return ruler;
}
