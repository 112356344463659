import { ModelElement } from '../../../../cae-model/model-element';
import { ThrustWasher } from '../../../../cae-model/supports/sliding-bearings/thrust-washer';
import { BearinxStandardBinder } from '../../../binders/bearinx-standard-binder';
import { SlidingBearingMediator } from './sliding-bearing-mediator';

export class ThrustWasherMediator extends SlidingBearingMediator {
    constructor() {
        super();
        this._createThrustWasherBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_THRUST_WASHER'];
    }

    private _createThrustWasherBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'iDir', right: 'IDL_INSTALLATION_DIRECTION' }];
        this.addBinder(binder);
    }

    protected createModelElement(): ModelElement {
        return new ThrustWasher();
    }
}
