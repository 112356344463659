import { BindingProperties } from './binding-properties';

/// class binding properties of two classes
export class Binder {
    properties: BindingProperties[];

    // setting left properties
    leftSetter: (prop: BindingProperties) => void;

    rightSetter: (prop: BindingProperties) => void;

    setLeftVariables() {
        if (this.leftSetter != null) {
            this.properties.map(prop => this.leftSetter(prop));
        }
    }

    setRightVariables() {
        if (this.rightSetter != null) {
            this.properties.map(prop => this.rightSetter(prop));
        }
    }
}
