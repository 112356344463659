import { Injectable } from '@angular/core';
import { WebGLRenderer, Clock } from 'three';
import { AnimationService } from './animation.service';

@Injectable()
export class NaturalModesVisualizationService {
    constructor(private _animationService: AnimationService) {}

    public render(renderer: WebGLRenderer) {
        if (renderer) {
            const clock = new Clock();
            renderer.setAnimationLoop(() => {
                const timeDelta = clock.getDelta();
                this._animationService.animate(timeDelta);
            });
        }
    }
}
