import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    private static readonly _IGNORE_URLS_REGEXP = ['/models/import', '/thumbnail/hover', '/thumbnail/default', '/thumbnail/custom'];

    constructor(private readonly _spinnerService: SpinnerService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req || !this._skipRequest(req.url)) {
            this._spinnerService.showSpinner();

            return next.handle(req).pipe(finalize(() => this._spinnerService.hideSpinner()));
        } else {
            return next.handle(req);
        }
    }

    private _skipRequest(url: string): boolean {
        let skip = false;
        for (let i = 0; i < SpinnerInterceptor._IGNORE_URLS_REGEXP.length; i++) {
            const re = new RegExp(SpinnerInterceptor._IGNORE_URLS_REGEXP[i], 'gi');
            if (url.search(re) !== -1) {
                skip = true;
                break;
            }
        }
        return skip;
    }
}
