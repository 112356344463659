import { ModelElement } from './model-element';
import { ModeShape } from './mode-shape';

export class NaturalFrequency extends ModelElement {
    private _modeShapes: ModeShape[];
    private _numberOfValidModeShapes: number;
    private _shaftRotationSpeed: number;

    constructor() {
        super();
        this._modeShapes = [];
    }

    public get numberOfValidModeShapes(): number {
        return this._numberOfValidModeShapes;
    }

    public set numberOfValidModeShapes(numberOfValidModeShapes: number) {
        this._numberOfValidModeShapes = numberOfValidModeShapes;
    }

    public get shaftRotationSpeed(): number {
        return this._shaftRotationSpeed;
    }

    public set shaftRotationSpeed(shaftRotationSpeed: number) {
        this._shaftRotationSpeed = shaftRotationSpeed;
    }

    public get modeShapes(): ModeShape[] {
        return this._modeShapes;
    }

    public set modeShapes(modeShapes: ModeShape[]) {
        this._modeShapes = modeShapes;
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof ModeShape) {
            this._addShape(child);
        }
    }

    private _addShape(shape: ModeShape): void {
        this._modeShapes.push(shape);
    }
}
