import { DistributedLoad } from './../../../cae-model/loads/distributed-load';
import { ElementView3D } from '../element-view-3d';
import { Mesh, Vector3, BufferGeometry } from 'three';
import { createLoadView, LoadViewInterface } from '../../functions/create-load-view';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { createLoadMaterial } from '../../settings/default-materials';
import { getDiameter } from '../../../view-2d/functions/loads-utils';

export class DistributedLoadView3D extends ElementView3D {
    constructor(load: DistributedLoad) {
        super(load);
        const mesh = this._createMesh();
        this.groupWithoutChildren.add(mesh);
    }

    protected updateGroupWithoutChildren(): void {
        const geometry = this._setGeometry();
        this.mesh.geometry = geometry;
    }

    private _setGeometry(): BufferGeometry {
        const load = this._distributedLoad;
        const loadLength = load.xEnd - load.x;
        const segmentsCount = 3;

        const forceStart = new Vector3(load.forceXStart, load.forceYStart, load.forceZStart);
        const forceEnd = new Vector3(load.forceXEnd, load.forceYEnd, load.forceZEnd);
        const totalForceAmp = Math.max(forceStart.length(), forceEnd.length(), 0.001);

        const torqueStart = new Vector3(load.torqueXStart, load.torqueYStart, load.torqueZStart);
        const torqueEnd = new Vector3(load.torqueXEnd, load.torqueYEnd, load.torqueZEnd);
        const totalTorqueAmp = Math.max(torqueStart.length(), torqueEnd.length(), 0.001);

        const outerDiameter = getDiameter(load);

        const geometries: BufferGeometry[] = [];
        for (let segmentIndex = 0; segmentIndex < segmentsCount; segmentIndex++) {
            const scaling = segmentIndex / (segmentsCount - 1);

            // interpolate vector depending on scaling
            // interpolate(scaling = 0) = vecStart
            // interpolate(scaling = 1) = vecEnd
            const interpolate = (vecStart: Vector3, vecEnd: Vector3) =>
                vecStart
                    .clone()
                    .multiplyScalar(1 - scaling)
                    .add(vecEnd.clone().multiplyScalar(scaling));
            const curPos = scaling * loadLength;

            const force = interpolate(forceStart, forceEnd);
            const scaledfoceAmp = force.length() / totalForceAmp;
            const loadViewInterfaceForce: LoadViewInterface = {
                outerDiameter: outerDiameter,
                direction: force,
                arrowLength: outerDiameter * scaledfoceAmp,
                isTorque: false,
            };
            const partForce = createLoadView(loadViewInterfaceForce);
            partForce.translate(curPos, 0, 0);
            geometries.push(partForce);

            const torque = interpolate(torqueStart, torqueEnd);
            const scaledTorqueAmp = torque.length() / totalTorqueAmp;
            const loadViewInterfaceTorque: LoadViewInterface = {
                outerDiameter: outerDiameter,
                arrowLength: outerDiameter * scaledTorqueAmp,
                direction: torque,
                isTorque: true,
            };

            const partTorque = createLoadView(loadViewInterfaceTorque);
            partTorque.translate(curPos, 0, 0);
            geometries.push(partTorque);
        }
        return BufferGeometryUtils.mergeBufferGeometries(geometries);
    }

    private get _distributedLoad(): DistributedLoad {
        return this.modelElement as DistributedLoad;
    }

    private _createMesh(): Mesh {
        const geometry = this._setGeometry();
        const material = createLoadMaterial();
        this.mesh = new Mesh(geometry, material);
        return this.mesh;
    }
}
