import { Vector2 } from 'three';
import { Curve } from './curve';

export class CurveArc extends Curve {
    m_radius = 0;
    origin = new Vector2();
    angles = new Vector2();
    convex = false;
    constructor(start: Vector2, public xzCos: number, public rad: number) {
        super(start, xzCos);
        this.m_radius = Math.abs(rad);
        this.convex = false;

        if (this.rad > 0.0) {
            this.convex = true;
        }
    }

    getMirroredClone() {
        const clone = new CurveArc(new Vector2(-this.endPoint.x, this.endPoint.y), this.xzCos, this.rad);
        clone.setEndPoint(new Vector2(-this.startPoint.x, this.startPoint.y));
        return clone;
    }

    getBoundingBox() {
        const points = [this.getCurvePoint(0.0), this.getCurvePoint(0.5), this.getCurvePoint(1.0)];

        const xValues = points.map(x => x.x);
        const xMin = Math.min.apply(Math, xValues);
        const xMax = Math.max.apply(Math, xValues);

        const yValues = points.map(x => x.y);
        const yMin = Math.min.apply(Math, yValues);
        const yMax = Math.max.apply(Math, yValues);
        return { xMin: xMin, xMax: xMax, yMin: yMin, yMax: yMax }; // super.getBoundingBox();
    }

    isConvex() {
        return this.convex;
    }

    getCurvePoint3(t: number) {
        return this.applyCos(this.getCurvePoint(t));
    }

    getCurvePoint(t: number) {
        return this.evalPt(t);
    }

    setEndPoint(point: Vector2) {
        super.setEndPoint(point);
        this.origin = this.findOrigin(this.startPoint, this.endPoint, this.rad);
        this.angles = this.findAngles(this.startPoint, this.endPoint, this.origin, this.rad);
    }

    getNumberOfPoints(pointsPerUnit: number) {
        return Math.round(Math.max(this.getCurveLength() * pointsPerUnit, 3));
    }

    getCurveLength() {
        const lower_limit: number = Math.min(this.angles.x, this.angles.y);
        const upper_limit: number = Math.max(this.angles.x, this.angles.y);

        // arc length
        return this.m_radius * Math.abs(upper_limit - lower_limit);
    }

    CalcTan2(y: number, x: number) {
        const TB_TINY = 1e-10;
        let angle: number = Math.atan2(y, x);
        if (Math.abs(y) < TB_TINY) {
            if (x > 0.0) {
                angle = 0.0;
            } else {
                angle = Math.PI;
            }
        }
        if (angle < 0.0) {
            angle = angle + Math.PI * 2.0;
        }

        return angle;
    }

    evalPt(t: number): Vector2 {
        // diese zeile passt füe parametric functions
        // const angle = this.angles.x + (this.angles.y - this.angles.x) * (1.0 - t );
        const angle = this.angles.x + (this.angles.y - this.angles.x) * t;

        let x: number = this.origin.x + this.m_radius * Math.cos(angle);
        let y: number = this.origin.y + this.m_radius * Math.sin(angle);
        const TB_TINY = 1e-10;
        if (Math.abs(x) < TB_TINY) {
            x = 0.0;
        }
        if (Math.abs(y) < TB_TINY) {
            y = 0.0;
        }
        return new Vector2(x, y);
    }

    findAngles(start_pnt: Vector2, end_pnt: Vector2, mid_pnt: Vector2, rad: number): Vector2 {
        const Xa: number = start_pnt.x - mid_pnt.x;
        const Ya: number = start_pnt.y - mid_pnt.y;

        const Xe = end_pnt.x - mid_pnt.x;
        const Ye = end_pnt.y - mid_pnt.y;

        // calculate start angle and end angle
        const StartAngle = this.CalcTan2(Ya, Xa);
        let EndAngle = this.CalcTan2(Ye, Xe);

        if (this.isConvex()) {
            let Delta: number = EndAngle - StartAngle;

            if (Delta < 0.0) {
                Delta = Delta + Math.PI * 2.0;
            }
            // calculate new end angle
            EndAngle = StartAngle + Delta;
        } else {
            let Delta = EndAngle - StartAngle;

            if (Delta > 0.0) {
                Delta = Math.PI * 2.0 - Delta;

                // calculate new end angle
                EndAngle = StartAngle - Delta;
            }
        }
        return new Vector2(StartAngle, EndAngle);
    }

    findOrigin(start_pnt: Vector2, end_pnt: Vector2, rad: number) {
        let mid_pnt = new Vector2();
        const P1: Vector2 = start_pnt;
        const P2: Vector2 = end_pnt;
        const R: number = Math.abs(rad);

        // Vektor vom Startpunkt zum Endpunkt
        const P: Vector2 = new Vector2().subVectors(P2, P1);

        // Abstand der zwei Punkte
        const length = P.length();

        // Punkt zwischen dem Start- und Endpunkt
        const Pm: Vector2 = new Vector2().addVectors(P1, P2).multiplyScalar(0.5);

        // Vektoren senkrecht zum Verbindungsvektor der zwei Punkte
        const orthographicVec1 = new Vector2(-P.y, P.x);
        const orthographicVec2 = new Vector2(P.y, -P.x);
        orthographicVec1.normalize();
        orthographicVec2.normalize();

        // Abstand zum evtl. Kreismittelpunkt
        let lengthToCircleMiddlePoint = 0.0;

        const TB_TINY = 1e-10;
        const s: number = R * R - length * length * 0.25;
        if (s > 0 && Math.abs(s) > TB_TINY) {
            lengthToCircleMiddlePoint = Math.sqrt(s);
        }

        // mögliche lösungen für den Mittelpunkt
        const Z1: Vector2 = new Vector2(
            Pm.x + lengthToCircleMiddlePoint * orthographicVec1.x,
            Pm.y + lengthToCircleMiddlePoint * orthographicVec1.y,
        );

        const Z2: Vector2 = new Vector2(
            Pm.x + lengthToCircleMiddlePoint * orthographicVec2.x,
            Pm.y + lengthToCircleMiddlePoint * orthographicVec2.y,
        );

        // von der Hälfte der Strecke zum P2
        const v: Vector2 = P.multiplyScalar(0.5);

        // zum Mittelpunkt
        const m1 = new Vector2().subVectors(Z1, Pm);
        const m2 = new Vector2().subVectors(Z2, Pm);
        const n1 = new Vector2(-v.y, v.x);
        const n2 = new Vector2(v.y, -v.x);

        if (rad > 0.0) {
            if (m1.x * n1.x >= 0 && m1.y * n1.y >= 0) {
                mid_pnt = Z1;
            } else {
                mid_pnt = Z2;
            }
        } else {
            if (m1.x * n2.x >= 0 && m1.y * n2.y >= 0) {
                mid_pnt = Z1;
            } else {
                mid_pnt = Z2;
            }
        }

        // numeric
        if (Math.abs(mid_pnt.x) < TB_TINY) {
            mid_pnt.x = 0.0;
        }
        if (Math.abs(mid_pnt.y) < TB_TINY) {
            mid_pnt.y = 0.0;
        }

        return mid_pnt;
    }
}
