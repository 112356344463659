import Konva from 'konva';
import { ARROW_HEAD_LENGTH_2D, POINT_LOAD_TORQUE_NAME, STROKE_WIDTH } from '../elements-view/view-2d-constants';
import { createAxisArrow, createAxisCircle } from '../functions/loads-utils';
import { AxisArrowType, LoadType } from '../../bearinx-mediator/functions/loads-properties';
import { AxisInputArrow, AxisInputCircle } from '../../bearinx-mediator/functions/axis-input-interface';
import { PointLoad } from '../../cae-model/loads/point-load';

export class PointLoadTorque {
    private _group: Konva.Group;

    constructor(private _pointLoad: PointLoad, private _unitScaleFactor: number) {
        this._group = this._createShapes();
    }

    get group(): Konva.Group {
        return this._group;
    }

    private _createShapes(): Konva.Group {
        const group = new Konva.Group({
            name: POINT_LOAD_TORQUE_NAME,
        });
        const arrowLength = this._getArrowLength();
        const circleRadius = arrowLength * 0.2;
        const { torqueX, torqueY, torqueZ } = this._pointLoad;
        const torqueLoadArrowLength = arrowLength + circleRadius + (STROKE_WIDTH * 2) / this._unitScaleFactor;

        if (torqueX !== 0) {
            const xAxis: AxisInputArrow = {
                loadValue: torqueX,
                arrowLength: torqueLoadArrowLength,
                axisArrowType: AxisArrowType.X,
                loadType: LoadType.Torque,
                positionX: 0,
                unitScaleFactor: this._unitScaleFactor,
            };
            group.add(createAxisArrow(xAxis));
        }
        if (torqueY !== 0) {
            const yAxis: AxisInputArrow = {
                loadValue: torqueY,
                arrowLength: torqueLoadArrowLength,
                axisArrowType: AxisArrowType.Y,
                loadType: LoadType.Torque,
                positionX: 0,
                unitScaleFactor: this._unitScaleFactor,
            };
            group.add(createAxisArrow(yAxis));
        }
        if (torqueZ !== 0) {
            const zAxis: AxisInputCircle = {
                loadValue: torqueZ,
                circleRadius: circleRadius,
                loadType: LoadType.Torque,
                positionX: 0,
                unitScaleFactor: this._unitScaleFactor,
            };
            group.add(createAxisCircle(zAxis));
        }

        return group;
    }

    private _getArrowLength(): number {
        return (ARROW_HEAD_LENGTH_2D * 2) / this._unitScaleFactor;
    }
}
