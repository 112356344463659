/* eslint-disable no-shadow */

import { Bearing } from './bearing';

export enum BEARING_POSITION_REFERENCE {
    LB_INNER_RING = 'LB_INNER_RING',
    LB_OUTER_RING = 'LB_OUTER_RING',
    LB_CENTER = 'LB_CENTER',
}

/**
 * RadialTaperedRoller
 */
export class RadialTaperedRoller extends Bearing {
    positionReference = '';
    xLockingSurface = 0;

    constructor() {
        super();
    }
}
