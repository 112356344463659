import { Injectable } from '@angular/core';
import { GltfExporterService } from '../modules/view-3d/services';
import { Observable } from 'rxjs';
import { BearinxMediatorService } from '../modules/bearinx-mediator/services/bearinx-mediator.service';
import { CaeModelProviderService } from '../modules/views-foundation/services/cae-model-provider.service';
import { CameraType, ClippingPlaneType, ClippingType, View3DSettings } from '../modules/view-3d/settings/view-3d-settings';
import { UnitSet } from '../modules/views-foundation/view-foundation-settings';

@Injectable()
/// export of  bearinx models in gltf text format
export class BearinxGltfTextExporterService {
    constructor(
        private _bearinxMediatorService: BearinxMediatorService,
        private _caeModelProviderService: CaeModelProviderService,
        private _gltfExporterService: GltfExporterService,
    ) {}

    export(bearinxModel: any): Observable<string> | null {
        const zeroLoadCase = 0;
        const caeModel = this._bearinxMediatorService.convert(bearinxModel, zeroLoadCase);
        if (caeModel != null) {
            this._caeModelProviderService.model = caeModel;
        }
        const view3DSettings: View3DSettings = {
            rotateAroundSelection: false,
            clippingType: ClippingType.Off,
            clippingPlane: ClippingPlaneType.XZ,
            flipClippingPlane: true,
            cameraType: CameraType.Orthographic,
            unitSet: UnitSet.SI,
        };
        return caeModel == null ? null : this._gltfExporterService.export(caeModel, view3DSettings);
    }
}
