import { GraphicsContext } from './graphics-context';
import Konva from 'konva';

export abstract class WMFImage {
    protected gc: GraphicsContext;

    protected width_: number;
    protected height_: number;

    get width(): number {
        return this.width_;
    }

    get heigth(): number {
        return this.height_;
    }

    constructor(width: number, height: number) {
        this.width_ = width;
        this.height_ = height;
        this.gc = new GraphicsContext();
    }

    paint(): Konva.Group {
        this.doPaint();
        return this.gc.group;
    }

    protected abstract doPaint(): void;

    protected roundAngle(x0: number, y0: number, x1: number, y1: number, xRadius: number, yRadius: number) {
        let x: number;
        let y: number;
        let width: number;
        let height: number;
        let angle: number;
        let xFactor: number;
        let yFactor: number;

        if (x0 < x1) {
            x = x0;
            width = x1 - x0;
            xFactor = 0;
            angle = 90;
        } else {
            x = x1;
            width = x0 - x1;
            xFactor = 1;
            angle = 0;
        }
        if (y0 < y1) {
            y = y0;
            height = y1 - y0;
            yFactor = 0;
        } else {
            y = y1;
            height = y0 - y1;
            yFactor = 1;
            angle = angle + 270 - angle * 2;
        }

        const gc = this.gc;
        gc.moveTo(x + width * xFactor, y + yRadius - yRadius * yFactor);
        gc.lineTo(x + width * xFactor, y + height - yRadius * yFactor);
        gc.moveTo(x + xRadius - xRadius * xFactor, y + height * yFactor);
        gc.lineTo(x + width - xRadius * xFactor, y + height * yFactor);

        if (xRadius > 0 && yRadius > 0) {
            gc.arc(x + (width - xRadius * 2) * xFactor, y + (height - yRadius * 2) * yFactor, 2 * xRadius, 2 * yRadius, angle, 90);
        }
    }
}
