<div class="app-buttons">
    <ng-content select="button[position='start']"></ng-content>
    <ng-content select="[caption]"></ng-content>
</div>

<ng-content select="[subCaption]"></ng-content>

<div class="app-buttons">
    <ng-content select="button:not([position='start'])"></ng-content>
</div>
