import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { WMFImage } from '../bearing/wmf-images/wmf-image';
import { SupportView2D } from '../support-view-2d';
import { Group } from 'konva/lib/Group';
import { SlidingBearing } from '../../../../cae-model/supports/sliding-bearings/sliding-bearing';
import { Observable, of } from 'rxjs';
import { getSupportGroupFromWMF } from '../../../functions/utils-2d';
import { takeUntil } from 'rxjs/operators';

export class SlidingBearingView2D extends SupportView2D {
    private _hasBorder = true;

    constructor(slidingBearing: SlidingBearing) {
        super(slidingBearing);
    }

    protected get hasBorder(): boolean {
        return this._hasBorder;
    }

    protected paintElement(group: Group, geometry: SupportGeometry): void {
        this._updateSlidingBearing(group, geometry);
    }

    protected getName(): string {
        return 'sliding-bearing-view-2d-group';
    }

    private _updateSlidingBearing(group: Group, geometry: SupportGeometry): void {
        this._getSlidingBearingGroup(geometry)
            ?.pipe(takeUntil(this.destroy$))
            .subscribe(result => {
                if (result instanceof Group) {
                    const konvaGroups = this.getSupportGroups(result, geometry, geometry.iDir);
                    konvaGroups.forEach(konvaGroup => group.add(konvaGroup));
                    if (group.getLayer() != null) {
                        group.draw();
                    }
                }
            });
    }

    private _getSlidingBearingGroup(geometry: SupportGeometry): Observable<Group | null> {
        const wmfImage = this.createWMFImage(geometry);
        if (wmfImage != null) {
            const imageGroup = getSupportGroupFromWMF(wmfImage, geometry);
            return of(imageGroup);
        }
        return of(null);
    }

    // Create image manually. Can be overriden.
    protected createWMFImage(_geometry: SupportGeometry): WMFImage | null {
        return null;
    }
}
