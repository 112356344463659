import { ModelElement } from '../../../../cae-model/model-element';
import { CylindricalPlainBush } from '../../../../cae-model/supports/sliding-bearings/cylindrical-plain-bush';
import { SlidingBearingMediator } from './sliding-bearing-mediator';

export class CylindricalPlainBushMediator extends SlidingBearingMediator {
    constructor() {
        super();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_CYLINDRICAL_PLAIN_BUSH'];
    }

    protected createModelElement(): ModelElement {
        return new CylindricalPlainBush();
    }
}
