import { BearingConstructionType } from '../../../../../bearinx-mediator/bearinx-ids';
import { WMFImage } from './wmf-image';
import { XForm } from './x-form';

export class WMF_ThrustBallBearing extends WMFImage {
    design_: string;

    public WMF_ThrustBallBearing() {}

    constructor() {
        super(1001, 1001);
    }

    protected doPaint() {
        let sides = 1;
        let hwMiddle = false;
        const width_ = this.width_;
        const design_ = this.design_;
        const gc = this.gc;
        if (
            design_ === BearingConstructionType.LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_GS_MITTIG ||
            design_ === BearingConstructionType.LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_WS_MITTIG
        ) {
            sides = 2;
        }
        if (design_ === BearingConstructionType.LB_AXIAL_RILLENKUGELLAGER_ZWEISEITIG_GS_MITTIG) {
            hwMiddle = true;
        }

        const xform = new XForm(-width_, 0, width_ * 2, this.height_ * 2, sides === 1 ? 600 : 1000, sides === 1 ? 600 : 1000, false, false);

        if (sides === 1) {
            gc.moveTo(xform.calculateX(0), xform.calculateY(0));
            gc.lineTo(xform.calculateX(200), xform.calculateY(0));
            gc.lineTo(xform.calculateX(200), xform.calculateY(600));
            gc.lineTo(xform.calculateX(0), xform.calculateY(600));
            gc.lineTo(xform.calculateX(0), xform.calculateY(0));

            gc.moveTo(xform.calculateX(400), xform.calculateY(0));
            gc.lineTo(xform.calculateX(600), xform.calculateY(0));
            gc.lineTo(xform.calculateX(600), xform.calculateY(600));
            gc.lineTo(xform.calculateX(400), xform.calculateY(600));
            gc.lineTo(xform.calculateX(400), xform.calculateY(0));

            gc.ellipse(
                xform.calculateX(150),
                xform.calculateX(450),
                xform.calculateHorizontalRadius(300),
                xform.calculateVerticalRadius(300),
            );
        } else {
            if (hwMiddle) {
                gc.moveTo(xform.calculateX(0), xform.calculateY(0));
                gc.lineTo(xform.calculateX(200), xform.calculateY(0));
                gc.lineTo(xform.calculateX(200), xform.calculateY(900));
                gc.lineTo(xform.calculateX(0), xform.calculateY(900));
                gc.lineTo(xform.calculateX(0), xform.calculateY(0));

                gc.moveTo(xform.calculateX(400), xform.calculateY(100));
                gc.lineTo(xform.calculateX(600), xform.calculateY(100));
                gc.lineTo(xform.calculateX(600), xform.calculateY(1000));
                gc.lineTo(xform.calculateX(400), xform.calculateY(1000));
                gc.lineTo(xform.calculateX(400), xform.calculateY(100));

                gc.moveTo(xform.calculateX(800), xform.calculateY(0));
                gc.lineTo(xform.calculateX(1000), xform.calculateY(0));
                gc.lineTo(xform.calculateX(1000), xform.calculateY(900));
                gc.lineTo(xform.calculateX(800), xform.calculateY(900));
                gc.lineTo(xform.calculateX(800), xform.calculateY(0));
            } else {
                gc.moveTo(xform.calculateX(0), xform.calculateY(100));
                gc.lineTo(xform.calculateX(200), xform.calculateY(100));
                gc.lineTo(xform.calculateX(200), xform.calculateY(1000));
                gc.lineTo(xform.calculateX(0), xform.calculateY(1000));
                gc.lineTo(xform.calculateX(0), xform.calculateY(100));

                gc.moveTo(xform.calculateX(400), xform.calculateY(0));
                gc.lineTo(xform.calculateX(600), xform.calculateY(0));
                gc.lineTo(xform.calculateX(600), xform.calculateY(900));
                gc.lineTo(xform.calculateX(400), xform.calculateY(900));
                gc.lineTo(xform.calculateX(400), xform.calculateY(0));

                gc.moveTo(xform.calculateX(800), xform.calculateY(100));
                gc.lineTo(xform.calculateX(1000), xform.calculateY(100));
                gc.lineTo(xform.calculateX(1000), xform.calculateY(1000));
                gc.lineTo(xform.calculateX(800), xform.calculateY(1000));
                gc.lineTo(xform.calculateX(800), xform.calculateY(100));
            }
            gc.ellipse(
                xform.calculateX(150),
                xform.calculateY(250),
                xform.calculateHorizontalRadius(300),
                xform.calculateVerticalRadius(500),
            );
            gc.ellipse(
                xform.calculateX(550),
                xform.calculateY(250),
                xform.calculateHorizontalRadius(300),
                xform.calculateVerticalRadius(500),
            );
        }
    }
}
