import { SlewingBall } from '../../../../cae-model/supports/bearings/slewing-ball';
import { BearingMediator } from './bearing-mediator';
import { ModelElement } from '../../../../cae-model/model-element';

// mediator, used for the convergence of SlewingBall
export class SlewingBallMediator extends BearingMediator {
    get bearinxObjectTypes(): string[] {
        return ['IDO_SLEWING_BALL_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new SlewingBall();
    }
}
