import { Component } from '@angular/core';

@Component({
    selector: 'bx-simple-tooltip',
    templateUrl: './simple-tooltip.component.html',
    styleUrls: ['./simple-tooltip.component.scss'],
})
export class SimpleTooltipComponent {
    text: string;
}
