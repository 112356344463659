import { DirectionalSupportInterface } from './directional-support-interface';
import { Support } from './support';

/**
 * RigidSupport
 */

export class RigidSupport extends Support implements DirectionalSupportInterface {
    outerDiameter = 0; // IDSU_DA
    innerDiameter = 0; // IDSU_DI
    width = 0; // IDSU_B
    DirXTrans = ''; // IDSU_DIRECTION_X_TRANSLATORY
    DirYTrans = ''; // IDSU_DIRECTION_Y_TRANSLATORY
    DirZTrans = ''; // IDSU_DIRECTION_Z_TRANSLATORY
    DirYRot = ''; // IDSU_DIRECTION_Y_ROTATORY
    DirZRot = ''; // IDSU_DIRECTION_Z_ROTATORY

    constructor() {
        super();
    }
}
