import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModelObject } from '../model-object.model';
import { Model } from '../model.model';
import { ModelApiService } from '../model-api.service';
import { Vg2ExportData } from './vg2.export.data';
import { Vg2ModelExporter } from './vg2.model.exporter';
import { hasProperty } from './has-property';
import { ExportConstants } from './export.constants';
import { DataProvider } from './data.provider';

@Injectable()
export class Vg2Json2Vg2Dataprovider implements DataProvider {
    public readonly inputFormats = [ExportConstants.vg2Json];

    public readonly outputFormat = Vg2ModelExporter.vg2;

    /**
     *  C'tor
     * @param  _modelService The bearinx model service for fetching data
     */
    constructor(private readonly _modelService: ModelApiService) {}

    /**
     * Converts the given data to the target format
     * @param input Input data to be converted
     * @returns Output data or null if not supported
     */
    public convert(input: any): Observable<any> {
        return new Observable<any>(observer => {
            const model = <Model<ModelObject>>input;
            if (model) {
                const vg2Json = ['id', 'name', 'project', 'method'];
                if (vg2Json.every(attr => hasProperty(model, attr))) {
                    const finished$ = new Subject<void>();
                    const modelId = model.id;

                    this._modelService
                        .getExportToken(modelId)
                        .pipe(takeUntil(finished$))
                        .subscribe(token => {
                            this._modelService
                                .export(modelId, this.outputFormat, token)
                                .pipe(takeUntil(finished$))
                                .subscribe(vg2Data => {
                                    finished$.next();
                                    finished$.complete();

                                    observer.next(new Vg2ExportData(modelId, model.name, vg2Data));
                                    observer.complete();
                                });
                        });
                }
            } else {
                observer.error();
                observer.complete();
            }
        });
    }
}
