import { PipeSegment } from '../../../cae-model/shaft-segments/pipe-segment';
import { BufferGeometry } from 'three';
import { createCylinderWithHole, CylinderWithHoleInterface } from '../../functions/utils-3d';
import { ShaftSegmentView3D } from './shaft-segment-view-3d';

export class PipeSegmentView3D extends ShaftSegmentView3D {
    constructor(pipe: PipeSegment) {
        super(pipe);
    }

    protected createGeometry(): BufferGeometry {
        const outerRadius = this._pipe.outerDiameter / 2;
        const innerRadius = this._pipe.innerDiameter / 2;
        const cylinderWithHoleInterface: CylinderWithHoleInterface = {
            outerRadiusStart: outerRadius,
            outerRadiusEnd: outerRadius,
            innerRadiusStart: innerRadius,
            innerRadiusEnd: innerRadius,
            length: this._pipe.length,
        };
        return createCylinderWithHole(cylinderWithHoleInterface);
    }

    private get _pipe(): PipeSegment {
        return this.modelElement as PipeSegment;
    }
}
