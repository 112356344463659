import { LoadContainer } from '../../cae-model/load-container';
import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';

export class LoadContainerMediator extends BasicMediator {
    get bearinxObjectTypes(): string[] {
        return ['IDO_LOADCONTAINER'];
    }

    protected createModelElement(): ModelElement {
        return new LoadContainer();
    }
}
