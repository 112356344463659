import Konva from 'konva';
import { Notch, XSide } from '../../../cae-model/notches/notch';
import { ElementView2D } from '../element-view-2d';
import { BOUNDING_BOX, DISPLAYRED, TRANSPARENT } from '../view-2d-constants';

export abstract class NotchView2D extends ElementView2D {
    protected group: Konva.Group | null = null;
    protected abstract createNotchView(): Konva.Group;

    private get _notch(): Notch {
        return this.modelElement as Notch;
    }

    protected get isStandardSelector() {
        return true;
    }

    protected get draggable() {
        return true;
    }

    protected get hasBorder(): boolean {
        return false;
    }

    private _createShapes(shapeName: string): Konva.Group {
        return new Konva.Group({
            name: shapeName,
            transformable: true,
        });
    }

    protected rerenderViewWithoutChildren(shapeName: string): void {
        this.group?.destroy();
        this.group = this._createShapes(shapeName);
        this.group.add(this.paint());

        const boundingRectForUTSelection = new Konva.Rect({
            ...this.group.getClientRect({
                skipStroke: true,
            }),
            fill: TRANSPARENT,
            name: BOUNDING_BOX,
        });
        this.group.add(boundingRectForUTSelection);

        this.groupWithoutChildren.add(this.group);
    }

    protected paint(): Konva.Group {
        const group = new Konva.Group();

        const color = DISPLAYRED;

        const notch = this.createNotchView();

        this.flipRightSideNotch(notch);

        group.add(notch);

        const { x, y } = this.getMarkerPosition();
        const yPos = y - 4.0 / this.input.unitScaleFactor;
        const triangle = new Konva.Line({
            points: [x, y, x - 2.0 / this.input.unitScaleFactor, yPos, x + 2.0 / this.input.unitScaleFactor, yPos],
            closed: true,
            fill: color,
        });

        group.add(triangle);

        return group;
    }

    protected getMarkerPosition(): { x: number; y: number } {
        return {
            x: 0,
            y: -this.outerRadius,
        };
    }

    protected get outerRadius(): number {
        return this._notch.getCurrentOuterRadius(this._notch.xInShaftCS);
    }

    protected get innerRadius(): number {
        return this._notch.getCurrentInnerRadius(this._notch.xInShaftCS);
    }

    protected flipRightSideNotch(group: Konva.Group): void {
        if (this._notch.getSide() === XSide.RIGHT) {
            group.scaleX(-1);
        }
    }
}
