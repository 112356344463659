import Konva from 'konva';
import { BACKGROUND_COLOR_2D, STROKE_WIDTH, TORQUE_CIRCLE_DOT_NAME } from '../elements-view/view-2d-constants';

export class TorqueCircleDot {
    private _group: Konva.Group;
    private _circle: Konva.Circle;
    private _circleDot: Konva.Circle;
    private _dot: Konva.Circle;

    constructor(stroke: string, radius: number, positionX = 0) {
        this._group = this._createShapes(stroke, radius, positionX);
    }

    get group(): Konva.Group {
        return this._group;
    }

    public updateShapes(stroke: string, radius: number, positionX: number): void {
        [this._circle, this._circleDot, this._dot].forEach(circle => {
            circle.x(positionX);
            circle.stroke(stroke);
        });

        this._circle.radius(radius);
        this._circleDot.radius((1 / 3) * radius);
        this._dot.radius((1 / 3) * radius < 1 ? (1 / 6) * radius : 1);
    }

    private _createShapes(stroke: string, radius: number, positionX = 0): Konva.Group {
        const group = new Konva.Group({
            name: TORQUE_CIRCLE_DOT_NAME,
        });

        this._circle = this._createCircle(BACKGROUND_COLOR_2D);
        this._circleDot = this._createCircle(BACKGROUND_COLOR_2D);
        this._dot = this._createCircle(stroke);

        this.updateShapes(stroke, radius, positionX);

        group.add(this._circle);
        group.add(this._circleDot);
        group.add(this._dot);

        return group;
    }

    private _createCircle(fillColor: string): Konva.Circle {
        const circle = new Konva.Circle();
        circle.fill(fillColor);
        circle.strokeWidth(STROKE_WIDTH);
        circle.strokeScaleEnabled(false);
        return circle;
    }
}
