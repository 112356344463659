import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { NotFoundData } from './not-found-data';

@Injectable({ providedIn: 'root' })
export class NotFoundResolverService {
    private _data: NotFoundData;

    public setData(data: NotFoundData): void {
        this._data = data;
    }

    public resolve(): Observable<NotFoundData> | Observable<never> {
        return of(this._data);
    }
}
