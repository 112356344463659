import Konva from 'konva';
import { generateUpperAndLowerParts } from '../../../functions/utils-2d';
import { SpurGear } from '../../../../cae-model/transmission-elements/spur-gear';
import { DISPLAYGREEN, DISPLAYRED, STROKE_WIDTH, BACKGROUND_COLOR_2D } from '../../view-2d-constants';
import { SpurGearBaseView2D } from '../spur-gear-base-view-2d';
import { SpurGearGeometry } from '../../../../views-foundation/geometries/spur-gear-geometry';

export class SpurGearView2D extends SpurGearBaseView2D {
    constructor(private _spurGear: SpurGear) {
        super(_spurGear);
    }

    protected updateSpurGear(group: Konva.Group): void {
        const { tTooth, tB, isOnShaft } = this.geometry;
        const tDO = this.geometry.tDO;
        const tDI = this.geometry.tDI;
        const height = (tDO - tDI) / 2.0;
        const color = isOnShaft === true ? DISPLAYGREEN : DISPLAYRED;

        const spurGearPart = new Konva.Rect({
            x: -tB / 2.0,
            y: -tDO / 2.0,
            width: tB,
            height: height,
            stroke: color,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: BACKGROUND_COLOR_2D,
        });

        group.add(generateUpperAndLowerParts(spurGearPart, 'spur-gear-line'));

        const scale = this.input.unitScaleFactor;
        if (tTooth > 1 / scale) {
            const x = tB / 2.0 + 3.0 / scale;
            const y = -tDO / 2.0;
            group.add(super.createText(`${tTooth}`, color, x, y));
        }
    }

    protected setGeometry(): void {
        this.geometry = new SpurGearGeometry(this._spurGear, this.input.unitScaleFactor);
    }
}
