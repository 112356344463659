import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { GroupService } from '../group/group.service';

@Component({
    selector: 'bx-userlevel',
    templateUrl: './userlevel.component.html',
    styleUrls: ['./userlevel.component.scss'],
})
export class UserLevelComponent {
    public readonly userlevel$ = this._groupService.group$.pipe(map(group => (group ? `USERLEVEL.${group.userLevel}` : undefined)));

    constructor(private readonly _groupService: GroupService) {}
}
