import { Component } from '@angular/core';
import { SpinnerService } from '../../modules/spinner/spinner.service';

@Component({
    selector: 'bx-app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(public readonly spinnerService: SpinnerService) {}
}
