import { of, Observable, defer, from } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class BearinxClipboardWrapper {
    private supportsSystemClipboard = false;
    private clipboardFallback = '';

    constructor() {
        if (navigator?.clipboard?.readText) {
            navigator.clipboard
                .readText()
                .then(() => {
                    this.supportsSystemClipboard = true;
                })
                .catch(() => {
                    console.warn('System clipboard not supported');
                    this.supportsSystemClipboard = false;
                });
        } else {
            this.supportsSystemClipboard = false;
        }
    }

    permissionTest(): Promise<string> {
        if (this.supportsSystemClipboard) {
            return navigator.clipboard.readText();
        }
        return Promise.resolve('');
    }

    readText(silent = false): Observable<string> {
        if (this.supportsSystemClipboard) {
            return defer(() =>
                from(navigator.clipboard.readText()).pipe(
                    catchError(err => {
                        if (!silent) {
                            console.warn(`[Clipboard Read] ${err.message ?? 'failed'}`);
                        }
                        return of(this.clipboardFallback);
                    }),
                ),
            );
        }
        return of(this.clipboardFallback);
    }

    writeText(data: string): void {
        this.clipboardFallback = data;

        if (this.supportsSystemClipboard) {
            navigator.clipboard.writeText(data);
        }
    }
}
