import { ThrustRollerRadialRoller } from './../../../../cae-model/supports/bearings/thrust-roller-radial-roller';
import { BearingMediator } from './bearing-mediator';
import { ModelElement } from '../../../../cae-model/model-element';

// mediator, used for the convergence of ThrustRollerRadialRoller
export class ThrustRollerRadialRollerMediator extends BearingMediator {
    get bearinxObjectTypes(): string[] {
        return ['IDO_THRUST_ROLLER_RADIAL_ROLLER_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new ThrustRollerRadialRoller();
    }
}
