<div class="title">
    <h4 caption>{{ title }}</h4>
</div>
<div class="content">
    <ng-content select="p"></ng-content>
</div>
<footer class="footer">
    <div class="button-bar">
        <ng-content select="button"></ng-content>
    </div>
</footer>
