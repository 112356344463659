import { Injectable } from '@angular/core';
import { Camera, Intersection, Object3D, Raycaster, Scene, Vector2 } from 'three';
import { SHAFT_ROOT_OBJECT_NAME, USERDATA_ELEMENT_3D_SELECTABLE } from '../settings/view-3d-constants';

@Injectable()
export class Picker {
    private _raycaster: Raycaster | null;

    constructor() {
        this.initPicker();
    }

    public initPicker(): void {
        if (this._raycaster == null) {
            this._raycaster = new Raycaster();
        }
    }

    public pick(normalizedPosition: Vector2, scene: Scene, camera: Camera): Object3D | null {
        if (this._raycaster != null) {
            // cast a ray through the frustum
            this._raycaster.setFromCamera(normalizedPosition, camera);
            // get the list of objects the ray intersected
            const clickableObjects = scene.getObjectByName(SHAFT_ROOT_OBJECT_NAME)?.children;
            if (clickableObjects != null && clickableObjects.length > 0) {
                const intersectedObjects = this._findIntersectedObjects(clickableObjects, scene);
                if (intersectedObjects.length > 0) {
                    // pick the first object. It's the closest one
                    return this._findSelectableObject(intersectedObjects[0].object);
                }
            }
        }

        return null;
    }

    public destroy(): void {
        if (this._raycaster != null) {
            this._raycaster = null;
        }
    }

    private _findIntersectedObjects(objectsToCheck: Object3D[], scene: Scene): Intersection[] {
        const intersectedObjects = this._raycaster!.intersectObjects(objectsToCheck);

        if (intersectedObjects.length === 0) {
            for (let index = 0; index < objectsToCheck.length; index++) {
                const object = objectsToCheck[index];
                if (object != null && object.children.length > 0) {
                    const subIntersectedObjects = this._findIntersectedObjects(object.children, scene);

                    if (subIntersectedObjects.length > 0) {
                        return subIntersectedObjects;
                    }
                }
            }
        }

        return intersectedObjects;
    }

    private _findSelectableObject(obj: Object3D): Object3D {
        if (obj.userData.hasOwnProperty(USERDATA_ELEMENT_3D_SELECTABLE) && obj.userData[USERDATA_ELEMENT_3D_SELECTABLE]) {
            return obj;
        }
        if (obj.parent == null) {
            return obj;
        }
        return this._findSelectableObject(obj.parent);
    }
}
