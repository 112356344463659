import {
    Object3D,
    BufferGeometry,
    Vector3,
    LineSegments,
    WebGLRenderer,
    Scene,
    LineBasicMaterial,
    Color,
    Mesh,
    ConeGeometry,
    MeshBasicMaterial,
    Group,
    PlaneGeometry,
    PerspectiveCamera,
    Triangle,
    Vector2,
    Texture,
    LinearFilter,
    RepeatWrapping,
    DoubleSide,
    Material,
    Box3,
} from 'three';
import { UnitSet } from '../../views-foundation/view-foundation-settings';
import { getUnitSetScaleValue } from '../functions/utils-3d';
import { COLOR_AXIS_X, COLOR_AXIS_Y, COLOR_AXIS_Z, GRID_NAME, MAIN_GRID_Z, SUB_GRID_Z } from '../settings/view-3d-constants';

export class LabeledGrid extends Group {
    private _length: number;
    private _mainGrid: LineSegments;
    private _subGrid: LineSegments;
    private _margin: LineSegments;
    private _xAxis: LineSegments;
    private _yAxis: LineSegments;
    private _zAxis: LineSegments;
    private _labels: Object3D;
    private _minorLabels: any;
    private _mayorLabels: any;
    private _minorLabelSize: number;
    private _textLabels: any;

    constructor(
        private _renderer: WebGLRenderer,
        private _scene: Scene,
        private _width = 1,
        private _widthDim = 1.0,
        private _step = 100,
        private _upVector: Vector3 = new Vector3().fromArray([0, 1, 0]),
        private _color = 0x000000,
        private _opacity = 0.2,
        private _text = true,
        private _textColor = '#000000',
        private _textLocation = 'center',
        private _labelSize = 0.4,
        private _labelOffset = 0.125,
        private _unit = 'mm',
        private _marginSize = 8,
        private _stepSubDivisions = 10,
        private _majorTickSize = 0.1,
        private _minorTickSize = 0,
        private _font = '18px sans-serif',
        private _xColor = 0xff0000,
        private _yColor = 0x008000,
        private _zColor = 0x0000ff,
        private _tooltipDelay = 100,
        private _flipZAxis = true,
        private _flipYAxis = true,
        private _showYAxis = true,
        private _nDigits = 2,
        private _labelScale = 1.0,
        private _showMinorTicks = true,
        private _size = 128,
        private _minMinorLabelSize = 80.0,
    ) {
        super();
        this._length = _width;
        this['name'] = GRID_NAME;
        this._minorLabels = [[], [], [], []];
        this._mayorLabels = [[], [], [], []];
        this._textLabels = [];
        this._minorLabelSize = this._labelSize * 0.3;
        this._drawGrid();
        this.up = _upVector;
        this.lookAt(_upVector);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    _drawGrid() {
        /* offset to avoid z fighting */
        const mainGridZ = MAIN_GRID_Z;
        const verticesGridGeometry: Vector3[] = [];
        const gridMaterial = new LineBasicMaterial({
            color: new Color().setHex(this._color),
            opacity: this._opacity,
            linewidth: 2,
            transparent: true,
        });

        const subGridZ = SUB_GRID_Z;
        const verticesSubGridGeometry: Vector3[] = [];
        const subGridMaterial = new LineBasicMaterial({
            color: new Color().setHex(this._color),
            opacity: this._opacity / 2,
            transparent: true,
        });
        const [xAxisMaterial, yAxisMaterial, zAxisMaterial] = [this._xColor, this._yColor, this._zColor].map<LineBasicMaterial>(
            (color: number) =>
                new LineBasicMaterial({
                    color: new Color().setHex(color),
                    opacity: this._opacity * 2,
                    transparent: true,
                }),
        );

        const step = this._step;
        const stepSubDivisions = this._stepSubDivisions;
        const width = this._width;
        const length = this._length;
        for (let i = 1; i <= width / 2 - 1; i += step / stepSubDivisions) {
            if (i % step === 0) {
                verticesGridGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._majorTickSize, i, mainGridZ));
                verticesGridGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._majorTickSize, i, mainGridZ));
                verticesGridGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._majorTickSize, -i, mainGridZ));
                verticesGridGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._majorTickSize, -i, mainGridZ));
            } else {
                verticesSubGridGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._minorTickSize, i, subGridZ));
                verticesSubGridGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._minorTickSize, i, subGridZ));
                verticesSubGridGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._minorTickSize, -i, subGridZ));
                verticesSubGridGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._minorTickSize, -i, subGridZ));
            }
        }

        // x axis
        const verticesXAxisGeometry: Vector3[] = [];
        verticesXAxisGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._majorTickSize, 0, mainGridZ));
        verticesXAxisGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._majorTickSize, 0, mainGridZ));

        const xAxisGeometry = new BufferGeometry().setFromPoints(verticesXAxisGeometry);
        this._xAxis = new LineSegments(xAxisGeometry, xAxisMaterial);
        this._xAxis.name = 'x-axis';
        this._xAxis.userData['TOOLTIP'] = { text: 'x', position: 'mouse', delay: this._tooltipDelay };

        const xAxisTip = new Mesh(
            new ConeGeometry(this._marginSize / 20, this._marginSize * 0.25, 8, 1, false),
            new MeshBasicMaterial({ color: new Color().setHex(this._xColor) }),
        );

        xAxisTip.position.setX(width / 2);
        xAxisTip.rotateZ(-Math.PI * 0.5);
        this._xAxis.add(xAxisTip);

        const xLabel = this.drawTextOnPlane('X', 128, COLOR_AXIS_X);
        xLabel.scale.set(0.5, 0.5, 0.5);
        xLabel.position.set(width / 2 - this._marginSize * 0.5, this._marginSize * 0.25, 0);
        this._xAxis.add(xLabel);

        for (let i = 1; i <= length / 2 - 1; i += step / stepSubDivisions) {
            if (i % step === 0) {
                verticesGridGeometry.push(new Vector3(i, -width / 2 - this._marginSize * 0.5 * this._majorTickSize, mainGridZ));
                verticesGridGeometry.push(new Vector3(i, width / 2 + this._marginSize * 0.5 * this._majorTickSize, mainGridZ));
                verticesGridGeometry.push(new Vector3(-i, -width / 2 - this._marginSize * 0.5 * this._majorTickSize, mainGridZ));
                verticesGridGeometry.push(new Vector3(-i, width / 2 + this._marginSize * 0.5 * this._majorTickSize, mainGridZ));
            } else {
                verticesSubGridGeometry.push(new Vector3(i, -width / 2 - this._marginSize * 0.5 * this._minorTickSize, subGridZ));
                verticesSubGridGeometry.push(new Vector3(i, width / 2 + this._marginSize * 0.5 * this._minorTickSize, subGridZ));
                verticesSubGridGeometry.push(new Vector3(-i, -width / 2 - this._marginSize * 0.5 * this._minorTickSize, subGridZ));
                verticesSubGridGeometry.push(new Vector3(-i, width / 2 + this._marginSize * 0.5 * this._minorTickSize, subGridZ));
            }
        }

        // z axis
        const verticesZAxisGeometry: Vector3[] = [];
        verticesZAxisGeometry.push(new Vector3(0, -width / 2 - this._marginSize * 0.5 * this._majorTickSize, mainGridZ));
        verticesZAxisGeometry.push(new Vector3(0, width / 2 + this._marginSize * 0.5 * this._majorTickSize, mainGridZ));

        const zAxisGeometry = new BufferGeometry().setFromPoints(verticesZAxisGeometry);
        this._zAxis = new LineSegments(zAxisGeometry, zAxisMaterial);
        this._zAxis.name = 'z-axis';
        this._zAxis.userData['TOOLTIP'] = { text: 'z', position: 'mouse', delay: this._tooltipDelay };

        const zAxisTip = new Mesh(
            new ConeGeometry(this._marginSize / 20, this._marginSize * 0.25, 8, 1, false),
            new MeshBasicMaterial({ color: new Color().setHex(this._zColor) }),
        );

        if (this._flipZAxis) {
            zAxisTip.position.setY(width / 2);
        } else {
            zAxisTip.position.setY(-(width / 2));
            zAxisTip.rotateZ(-Math.PI);
        }

        this._zAxis.add(zAxisTip);

        const zLabel = this.drawTextOnPlane('Z', 128, COLOR_AXIS_Z);
        zLabel.scale.set(0.5 * -1, 0.5, 0.5);
        zLabel.rotateZ(-Math.PI * 0.5);

        if (this._flipZAxis) {
            zLabel.position.set(this._marginSize * 0.25, (-width / 2 + this._marginSize * 0.5) * -1, 0);
        } else {
            zLabel.position.set(this._marginSize * 0.25, -width / 2 + this._marginSize * 0.5, 0);
        }
        this._zAxis.add(zLabel);

        // y-axis
        if (this._showYAxis) {
            const verticesYAxisGeometry: Vector3[] = [];
            verticesYAxisGeometry.push(new Vector3(0, 0, (width / 2.0) * 0.25));
            verticesYAxisGeometry.push(new Vector3(0, 0, -width / 2.0));

            const yAxisGeometry = new BufferGeometry().setFromPoints(verticesYAxisGeometry);
            this._yAxis = new LineSegments(yAxisGeometry, yAxisMaterial);
            this._yAxis.name = 'y-axis';

            const yLabel = this.drawTextOnPlane('Y', 128, COLOR_AXIS_Y);
            yLabel.scale.set(0.5, -0.5, 0.5);
            yLabel.position.set(this._marginSize * 0.25, 0.0, this._flipYAxis ? (width / 2) * 0.25 : -(width / 2));
            this._yAxis.add(yLabel);

            const yAxisTip = new Mesh(
                new ConeGeometry(this._marginSize / 20, this._marginSize * 0.25, 8, 1, false),
                new MeshBasicMaterial({ color: new Color().setHex(this._yColor) }),
            );

            if (this._flipYAxis) {
                yAxisTip.position.setZ((width / 2) * 0.25);
                yAxisTip.rotateX(Math.PI * 0.5);
            } else {
                yAxisTip.position.setZ(-(width / 2));
                yAxisTip.rotateX(-Math.PI * 0.5);
            }
            this._yAxis.add(yAxisTip);
        }

        const gridGeometry = new BufferGeometry().setFromPoints(verticesGridGeometry);
        this._mainGrid = new LineSegments(gridGeometry, gridMaterial);
        this._mainGrid.name = 'mainGrid';

        const subGridGeometry = new BufferGeometry().setFromPoints(verticesSubGridGeometry);
        this._subGrid = new LineSegments(subGridGeometry, subGridMaterial);
        this._subGrid.name = 'subGrid';

        const offsetWidth = width + this._marginSize;
        const offsetLength = length + this._marginSize;

        const verticesMarginGeometry: Vector3[] = [];

        verticesMarginGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._majorTickSize, -width / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._majorTickSize, -width / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(length / 2, -width / 2 - this._marginSize * 0.5 * this._majorTickSize, subGridZ));

        verticesMarginGeometry.push(new Vector3(length / 2, width / 2 + this._marginSize * 0.5 * this._majorTickSize, subGridZ));

        verticesMarginGeometry.push(new Vector3(length / 2 + this._marginSize * 0.5 * this._majorTickSize, width / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(-length / 2 - this._marginSize * 0.5 * this._majorTickSize, width / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(-length / 2, width / 2 + this._marginSize * 0.5 * this._majorTickSize, subGridZ));

        verticesMarginGeometry.push(new Vector3(-length / 2, -width / 2 - this._marginSize * 0.5 * this._majorTickSize, subGridZ));

        verticesMarginGeometry.push(new Vector3(-offsetLength / 2, -offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(offsetLength / 2, -offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(offsetLength / 2, -offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(offsetLength / 2, offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(offsetLength / 2, offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(-offsetLength / 2, offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(-offsetLength / 2, offsetWidth / 2, subGridZ));

        verticesMarginGeometry.push(new Vector3(-offsetLength / 2, -offsetWidth / 2, subGridZ));

        const strongGridMaterial = new LineBasicMaterial({
            color: new Color().setHex(this._color),
            opacity: this._opacity * 2,
            linewidth: 2,
            transparent: true,
        });

        const marginGeometry = new BufferGeometry().setFromPoints(verticesMarginGeometry);
        this._margin = new LineSegments(marginGeometry, strongGridMaterial);
        this._margin.name = 'margin';

        this.add(this._mainGrid);
        this.add(this._subGrid);
        this.add(this._margin);
        this.add(this._xAxis);
        this.add(this._yAxis);
        this.add(this._zAxis);

        const interactionPlanes = new Group();
        interactionPlanes.name = 'interaction';

        this.add(interactionPlanes);

        this._addInteraction(interactionPlanes);
        this._drawNumbering();
        this._flipAxisLabels();

        this.scale.set(this._widthDim * 0.01, this._widthDim * 0.01, this._widthDim * 0.01);
    }

    _addInteraction(parent: Group): void {
        const zPos = 0.0;

        const opacity = 0.0;

        const verticesInteractionTriangleGeometry: Vector3[] = [];

        verticesInteractionTriangleGeometry.push(new Vector3(-this._marginSize * 0.25, this._marginSize * 0.25, 0.0));
        verticesInteractionTriangleGeometry.push(new Vector3(-this._marginSize * 0.25, -this._marginSize * 0.25, 0.0));
        verticesInteractionTriangleGeometry.push(new Vector3(this._marginSize * 0.25, -this._marginSize * 0.25, 0.0));

        const interactionPlaneGeometry = new PlaneGeometry(this._width * 0.5, this._marginSize * 0.5);

        // corner triangles
        const outerTriangleMaterial = new MeshBasicMaterial({
            opacity: opacity,
            transparent: true,
        });

        const innerTriangleMaterial = new MeshBasicMaterial({
            opacity: opacity,
            transparent: true,
        });

        for (let i = 0; i < 4; i++) {
            const interactionTriangleGeometry = new BufferGeometry().setFromPoints(verticesInteractionTriangleGeometry);
            const sideBar = new Group();
            parent.add(sideBar);

            const outerTriangle = new Mesh(interactionTriangleGeometry, outerTriangleMaterial);
            outerTriangle.rotation.set(0, 0.0, 0.0);
            outerTriangle.position.set(-this._width * 0.5 - this._marginSize / 4, -this._width * 0.5 - this._marginSize / 4, zPos);
            sideBar.add(outerTriangle);

            const innerTriangle = new Mesh(interactionTriangleGeometry, innerTriangleMaterial);
            innerTriangle.position.set(-this._width * 0.5 - this._marginSize / 4, -this._width * 0.5 - this._marginSize / 4, zPos);
            innerTriangle.rotation.set(0, 0.0, Math.PI);
            sideBar.add(innerTriangle);
            sideBar.rotation.z = (i * Math.PI) / 2;

            const negativeAxisMaterial = new MeshBasicMaterial({
                opacity: opacity,
                transparent: true,
            });

            const negativeAxisPanel = new Mesh(interactionPlaneGeometry, negativeAxisMaterial);
            negativeAxisPanel.position.set(-this._width * 0.25, -this._width * 0.5 - this._marginSize / 4, zPos);
            // negativeAxisPanel.userData = this.marginSidePanelUserData[i][1];
            sideBar.add(negativeAxisPanel);

            const positiveAxisPanel = new Mesh(interactionPlaneGeometry, negativeAxisMaterial);
            positiveAxisPanel.position.set(this._width * 0.25, -this._width * 0.5 - this._marginSize / 4, zPos);
            // positiveAxisPanel.userData = this.marginSidePanelUserData[i][0];
            sideBar.add(positiveAxisPanel);
            sideBar.rotation.z = (i * Math.PI) / 2;
        }
    }

    showMinorTickLabels(show: boolean): void {
        for (const labels of this._minorLabels) {
            for (const label of labels) {
                label.visible = show;
            }
        }
    }

    showMayorTickLabels(show: boolean): void {
        for (const labels of this._mayorLabels) {
            for (const label of labels) {
                label.visible = show;
            }
        }
    }

    _flipAxisLabels(flips = [true, true, true, true]): void {
        flips.forEach((flip, index) => {
            const scale = flip ? -1 : 1;

            for (const label of this._minorLabels[index]) {
                if (index === 0 || index === 3) {
                    label.scale.y = this._minorLabelSize * scale;
                } else {
                    label.scale.x = this._minorLabelSize * scale;
                }
            }

            for (const label of this._mayorLabels[index]) {
                if (index === 0 || index === 3) {
                    label.scale.y = this._labelSize * scale;
                } else {
                    label.scale.x = this._labelSize * scale;
                }
            }
        });
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    public updateGrid(camera: PerspectiveCamera) {
        if (camera !== undefined) {
            this._scene.updateMatrixWorld(true);
            this.updateMatrixWorld(true);

            camera.updateProjectionMatrix();

            // get corner position on screen
            const screenCoordinateAtXminZmax = this.deteremineScreenCoordinate(this.localToWorld(new Vector3(-50, -50, 0)), true, camera);
            const screenCoordinateAtXminZmin = this.deteremineScreenCoordinate(this.localToWorld(new Vector3(-50, 50, 0)), true, camera);

            const screenCoordinateAtXmaxZmax = this.deteremineScreenCoordinate(this.localToWorld(new Vector3(50, -50, 0)), true, camera);

            const screenCoordinateAtXmaxZmin = this.deteremineScreenCoordinate(this.localToWorld(new Vector3(50, 50, 0)), true, camera);

            const flipOrder = [];

            // x axis at z-
            flipOrder.push(screenCoordinateAtXminZmax.y < screenCoordinateAtXminZmin.y ? false : true);

            // x axis at z+
            flipOrder.push(screenCoordinateAtXmaxZmax.y < screenCoordinateAtXmaxZmin.y ? true : false);

            // z axis at x+
            flipOrder.push(screenCoordinateAtXmaxZmax.y < screenCoordinateAtXminZmax.y ? true : false);

            // z axis at x-
            flipOrder.push(screenCoordinateAtXmaxZmin.y < screenCoordinateAtXminZmin.y ? false : true);

            this._flipAxisLabels(flipOrder);

            const checkPoints = [];

            for (let x = -1.0; x <= 1.0; x = x + 0.2) {
                checkPoints.push([50 * x, 50]);
                checkPoints.push([50 * x, -50]);
                checkPoints.push([50, 50 * x]);
                checkPoints.push([-50, 50 * x]);
            }

            let maxArea = 0.0;
            for (const checkPoint of checkPoints) {
                const area = this.getScreenArea(checkPoint[0], checkPoint[1], 100, camera) * 1000000000;
                maxArea = Math.max(area, maxArea);
            }

            /*
            if ( maxArea > 0.0013 / 2.0) {
              this.showMayorTickLabels(true);
            } else {
              this.showMayorTickLabels(false);
            }
            */

            if (maxArea > this._minMinorLabelSize) {
                this.showMinorTickLabels(true);
            } else {
                this.showMinorTickLabels(false);
            }
        } else {
            throw new Error('missing camera');
        }
    }

    private getScreenArea(x: number, z: number, steps: number, camera: PerspectiveCamera): number {
        this._scene.updateMatrixWorld(true);
        this.updateMatrixWorld(true);
        camera.updateProjectionMatrix();

        const p_x_z = this.localToWorld(new Vector3(x, -z, 0));
        const p_x_max_z = this.localToWorld(new Vector3(x + 0.5 / steps, -z, 0));
        const p_x_z_max = this.localToWorld(new Vector3(x, -(z + 0.5 / steps), 0));
        const p1 = this.deteremineScreenCoordinate(p_x_z, true, camera);
        const p2 = this.deteremineScreenCoordinate(p_x_max_z, true, camera);
        const p3 = this.deteremineScreenCoordinate(p_x_z_max, true, camera);

        if (Math.abs(p1.x) < 1.0 && Math.abs(p1.y) < 1.0) {
            const triangle = new Triangle(p1, p2, p3);
            return triangle.getArea();
        } else {
            return 0.0;
        }
    }

    private deteremineScreenCoordinate(vector: Vector3, mouseCoordinates: boolean, camera: PerspectiveCamera) {
        if (camera === undefined) {
            throw new Error('could not determine screen coordinate. Missing camera');
        } else {
            vector.project(camera);
            const size = this._renderer.getSize(new Vector2(vector.x, vector.y));

            const widthHalf = size.width / 2;
            const heightHalf = size.height / 2;
            vector.x = vector.x * widthHalf + widthHalf;
            vector.y = -(vector.y * heightHalf) + heightHalf;

            if (mouseCoordinates) {
                vector.x = vector.x / widthHalf - 1.0;
                vector.y = vector.y / -heightHalf + 1.0;
            }
            return vector;
        }
        return new Vector3();
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    _drawNumbering() {
        const step = this._step;

        if (this._labels != null) {
            this._mainGrid.remove(this._labels);
        }
        this._labels = new Object3D();

        const width = this._width;
        const length = this._length;
        const labelsFront = new Object3D();
        labelsFront.name = 'labelsFront';

        const labelsZaxisMajor = new Object3D();
        labelsZaxisMajor.name = 'labelsZaxisMajor';

        const labelsZaxisMinor = new Object3D();
        labelsZaxisMinor.name = 'labelsZaxisMinor';
        labelsFront.add(labelsZaxisMinor);
        labelsFront.add(labelsZaxisMajor);

        const labelsSideRight = new Object3D();
        labelsSideRight.name = 'labelsSideRight';

        const labelsSideRightMajor = new Object3D();
        labelsSideRightMajor.name = 'labelsSideRightMajor';

        const labelsSideRightMinor = new Object3D();
        labelsSideRightMinor.name = 'labelsSideRightMinor';
        labelsSideRight.add(labelsSideRightMinor);
        labelsSideRight.add(labelsSideRightMajor);

        const z = -0.01;

        for (let i = 0; i <= width / 2; i += 1) {
            let lSize = this._labelSize;

            let lOffset = this._labelOffset;
            let nDigits = this._nDigits;
            let visible = this._text;

            let labels = this._mayorLabels;

            let parent = labelsZaxisMajor;
            if (i % step !== 0) {
                // minor
                parent = labelsZaxisMinor;
                lSize = this._minorLabelSize;
                lOffset *= 0.3;
                nDigits += 1;
                visible = this._showMinorTicks;
                labels = this._minorLabels;
            }

            let text = '' + ((i / this._length) * this._labelScale).toFixed(nDigits);
            let size = this._size;

            if (i === 0) {
                text = '[' + this._unit + ']';
                size *= 2;
            }

            const idx1 = this._flipZAxis ? -i : i;
            const sizeLabel = this.drawTextOnPlane(text, size);

            sizeLabel.position.set(length / 2 + this._marginSize * lOffset, -idx1, z);
            sizeLabel.rotation.z = -Math.PI / 2;

            sizeLabel.scale.set(lSize, lSize, lSize);
            sizeLabel.visible = visible;
            sizeLabel.name = 'z label at x max';
            labels[2].push(sizeLabel);
            parent.add(sizeLabel);

            const sizeLabelClone = this.drawTextOnPlane(text, size);
            sizeLabelClone.position.set(-length / 2 - this._marginSize * lOffset, -idx1, z);
            sizeLabelClone.name = 'z label at x min';
            sizeLabelClone.scale.set(lSize, lSize, lSize);
            sizeLabelClone.rotation.z = -Math.PI * 1.5;
            sizeLabelClone.visible = visible;
            labels[3].push(sizeLabelClone);
            parent.add(sizeLabelClone);

            if (i === 0) {
                this._textLabels.push(sizeLabel);
                this._textLabels.push(sizeLabelClone);
            }

            if (i > 0) {
                const idx2 = this._flipZAxis ? -i : i;
                const sizeLabel2 = this.drawTextOnPlane('-' + text, size);

                sizeLabel2.position.set(length / 2 + this._marginSize * lOffset, idx2, z);
                sizeLabel2.rotation.z = -Math.PI / 2;
                sizeLabel2.visible = visible;
                sizeLabel2.scale.set(lSize, lSize, lSize);
                parent.add(sizeLabel2);
                labels[2].push(sizeLabel2);

                const sizeLabel2Clone = this.drawTextOnPlane('-' + text, size);
                sizeLabel2Clone.position.set(-length / 2 - this._marginSize * lOffset, idx2, z);
                sizeLabel2Clone.scale.set(lSize, lSize, lSize);
                sizeLabel2Clone.rotation.z = -Math.PI * 1.5;
                sizeLabel2Clone.visible = visible;
                parent.add(sizeLabel2Clone);
                labels[3].push(sizeLabel2Clone);
            }
        }

        for (let i = 0; i <= length / 2; i += 1) {
            let lSize = this._labelSize;
            let lOffset = this._labelOffset;
            let nDigits = this._nDigits;
            let visible = this._text;
            let parent = labelsSideRightMajor;
            let labels = this._mayorLabels;
            if (i % step !== 0) {
                // minor
                lSize = this._minorLabelSize;
                lOffset *= 0.3;
                nDigits += 1;
                parent = labelsSideRightMinor;
                visible = this._showMinorTicks;
                labels = this._minorLabels;
            }

            let text = '' + ((i / this._width) * this._labelScale).toFixed(nDigits);
            let size = this._size;

            if (i === 0) {
                text = '[' + this._unit + ']';
                size *= 2;
            }

            const sizeLabel = this.drawTextOnPlane(text, size);
            sizeLabel.position.set(i, width / 2 + this._marginSize * lOffset, z);
            sizeLabel.name = 'x label at z max';
            sizeLabel.scale.set(lSize, lSize, lSize);
            sizeLabel.visible = visible;
            labels[0].push(sizeLabel);
            parent.add(sizeLabel);

            const sizeLabelClone = this.drawTextOnPlane(text, size);
            sizeLabelClone.position.set(i, -width / 2 - this._marginSize * lOffset, z);
            sizeLabelClone.scale.set(lSize, lSize, lSize);
            sizeLabelClone.rotation.z = -Math.PI;
            sizeLabelClone.visible = visible;
            sizeLabelClone.name = 'x label at z min';
            labels[1].push(sizeLabelClone);
            parent.add(sizeLabelClone);

            if (i === 0) {
                this._textLabels.push(sizeLabel);
                this._textLabels.push(sizeLabelClone);
            }

            if (i > 0) {
                const sizeLabel2 = this.drawTextOnPlane('-' + text, size);
                sizeLabel2.position.set(-i, width / 2 + this._marginSize * lOffset, z);
                sizeLabel2.scale.set(lSize, lSize, lSize);
                sizeLabel2.visible = visible;
                parent.add(sizeLabel2);
                labels[0].push(sizeLabel2);

                const sizeLabel2Clone = this.drawTextOnPlane('-' + text, size);
                sizeLabel2Clone.position.set(-i, -width / 2 - this._marginSize * lOffset, z);
                sizeLabel2Clone.scale.set(lSize, lSize, lSize);
                sizeLabel2Clone.rotation.z = -Math.PI;
                sizeLabel2Clone.visible = visible;
                parent.add(sizeLabel2Clone);
                labels[1].push(sizeLabel2Clone);
            }
        }

        const labelsSideLeft = labelsSideRight.clone();
        labelsSideLeft.rotation.z = -Math.PI;
        const labelsBack = labelsFront.clone();
        labelsBack.rotation.z = -Math.PI;

        this._labels.add(labelsFront);
        this._labels.add(labelsSideRight);

        this._mainGrid.add(this._labels);
    }

    drawTextOnPlane(text: string, size = 128, textColor = this._textColor): Mesh {
        const canvas: Element = document.createElement('canvas');
        const c: HTMLCanvasElement = <HTMLCanvasElement>canvas;

        c.width = size;
        c.height = size;
        const context = c.getContext('2d');

        if (context) {
            context.font = this._font;
            context.textAlign = 'center';
            context.fillStyle = textColor;
            context.fillText(text, canvas['width'] / 2, canvas['height'] / 2);
            context.strokeStyle = textColor;
            context.strokeText(text, canvas['width'] / 2, canvas['height'] / 2);

            const texture = new Texture(c);
            texture.needsUpdate = true;
            texture.generateMipmaps = true;
            texture.magFilter = LinearFilter;
            texture.minFilter = LinearFilter;
            texture.wrapS = RepeatWrapping;

            const material = new MeshBasicMaterial({
                map: texture,
                alphaTest: 0.3,
                side: DoubleSide,
            });

            return new Mesh(new PlaneGeometry(size / 8, size / 8), material);
        }
        return new Mesh(new PlaneGeometry(1, 1), new Material() as any);
    }
}

function getUnitString(unitSet: UnitSet, isMeter?: boolean): string {
    return unitSet === UnitSet.FPS ? 'in' : isMeter ? 'm' : 'mm';
}

export function createLabeledGrid(renderer: WebGLRenderer, scene: Scene, object3D: Object3D, unitSet: UnitSet): LabeledGrid {
    const unitScale = getUnitSetScaleValue(unitSet);
    const mUnitScale = unitScale / 1000;
    const gridDimensionSteps = [
        {
            width: (1000 * 0.25) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet),
            labelValueScale: 250 / unitScale,
            nDigits: 0,
        },
        {
            width: (1000 * 0.5) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet),
            labelValueScale: 500 / unitScale,
            nDigits: 0,
        },
        {
            width: (1000 * 1.0) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet),
            labelValueScale: 1000.0 / unitScale,
            nDigits: 0,
        },
        {
            width: (1000 * 2.0) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet),
            labelValueScale: 2000.0 / unitScale,
            nDigits: 0,
        },
        {
            width: (1000 * 5.0) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet, true),
            labelValueScale: 5 / mUnitScale,
            nDigits: 2,
        },
        {
            width: (1000 * 10.0) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet, true),
            labelValueScale: 10.0 / mUnitScale,
            nDigits: 2,
        },
        {
            width: (1000 * 50.0) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet, true),
            labelValueScale: 50.0 / mUnitScale,
            nDigits: 2,
        },
        {
            width: (1000 * 100.0) / unitScale,
            majorSteps: 10,
            minorSteps: 100,
            unit: getUnitString(unitSet, true),
            labelValueScale: 100.0 / mUnitScale,
            nDigits: 2,
        },
    ];

    const bbox = new Box3();
    bbox.setFromObject(object3D);
    const sceneWorldPosition = new Vector3();
    object3D.getWorldPosition(sceneWorldPosition);

    const modelDimension =
        Math.max(
            Math.abs(bbox.max.x - sceneWorldPosition.x),
            Math.abs(bbox.min.x - sceneWorldPosition.x),
            Math.abs(bbox.max.y - sceneWorldPosition.y),
            Math.abs(bbox.min.y - sceneWorldPosition.y),
            Math.abs(bbox.max.z - sceneWorldPosition.z),
            Math.abs(bbox.min.z - sceneWorldPosition.z),
        ) * 5.0;

    const deltas = gridDimensionSteps.map(x => x['width'] - modelDimension);
    let gridWidth = gridDimensionSteps[gridDimensionSteps.length - 1]['width'];
    let unit = gridDimensionSteps[gridDimensionSteps.length - 1]['unit'];
    let labelValueScale = gridDimensionSteps[gridDimensionSteps.length - 1]['labelValueScale'];
    let nDigits = gridDimensionSteps[gridDimensionSteps.length - 1]['nDigits'];
    let i = 0;
    for (const delta of deltas) {
        if (delta >= 0) {
            gridWidth = gridDimensionSteps[i]['width'];
            unit = gridDimensionSteps[i]['unit'];
            labelValueScale = gridDimensionSteps[i]['labelValueScale'];
            nDigits = gridDimensionSteps[i]['nDigits'];
            break;
        }
        i += 1;
    }

    const grid = new LabeledGrid(
        renderer,
        scene,
        100,
        gridWidth,
        100 / 10,
        new Vector3().set(0, 1, 0),
        0x000000,
        0.2,
        true,
        '#000000',
        'center',
        0.2,
        0.2,
        unit,
        8,
        10,
        0.1,
        0,
        '18px sans-serif',
        0xff0000,
        0x008000,
        0x0000ff,
        100,
        false,
        true,
        true,
        nDigits,
        labelValueScale,
        true,
        128,
        40.0,
    );

    grid.showMayorTickLabels(true);
    grid.showMinorTickLabels(false);

    const modelGroupSize: Vector3 = new Vector3(0, 0, 0);
    bbox.getSize(modelGroupSize);

    grid.position.y = Math.max(modelGroupSize.x, modelGroupSize.y, modelGroupSize.z);

    return grid;
}
