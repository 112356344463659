import * as THREE from 'three';

export abstract class Curve {
    endPoint = new THREE.Vector2();
    constructor(public startPoint: THREE.Vector2, public plane: number) {}

    abstract getMirroredClone(): Curve;

    getCurveLength() {
        return this.startPoint.distanceTo(this.endPoint);
    }

    getCurvePoint(t: number): THREE.Vector2 {
        return new THREE.Vector2(this.startPoint.x * (1.0 - t) + this.endPoint.x * t, this.startPoint.y * (1.0 - t) + this.endPoint.y * t);
    }

    getNumberOfPoints(pointsPerUnit: number) {
        return this.getCurveLength() * pointsPerUnit;
    }

    applyCos(point: THREE.Vector2): THREE.Vector3 {
        if (this.plane === 1) {
            // XZ_COS
            return new THREE.Vector3(point.x, 0.0, point.y);
        } else if (this.plane === 2) {
            // YZ_COS
            return new THREE.Vector3(0.0, point.x, point.y);
        }
        return new THREE.Vector3(point.x, point.y, 0.0);
    }

    getStartPoint() {
        return this.startPoint;
    }

    getBoundingBox() {
        return {
            xMin: Math.min(this.startPoint.x, this.endPoint.x),
            xMax: Math.max(this.startPoint.x, this.endPoint.x),
            yMin: Math.min(this.startPoint.y, this.endPoint.y),
            yMax: Math.max(this.startPoint.y, this.endPoint.y),
        };
    }

    setEndPoint(point: THREE.Vector2) {
        this.endPoint = point;
    }
}
