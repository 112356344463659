import { Injectable } from '@angular/core';
import { Vector3 } from 'three';
import { ComplexNumber } from '../functions/complex-number';
import { NaturalModeViewConfigInterface } from '../interfaces/natural-mode-view-config.interface';
import { CaeModelProviderService } from '../../views-foundation/services/cae-model-provider.service';
import { NaturalMode } from '../interfaces/natural-mode.interface';
import { ModeShape } from '../../cae-model/mode-shape';
import { Shaft } from '../../cae-model/shaft';
import { ModeShapeSection } from '../../cae-model/mode-shape-section';
import { ShaftSystem } from '../../cae-model/shaft-system';
import { DEFAULT_RADIAL_SCALE_FACTOR } from '../settings/natural-mode-constants';
import { Params } from '../settings/natural-mode-params';

@Injectable()
export class NaturalModesService {
    private _shaftSystem: ShaftSystem;

    public get shaftSystem(): ShaftSystem {
        return this._shaftSystem;
    }

    public set shaftSystem(shaftSystem: ShaftSystem) {
        this._shaftSystem = shaftSystem;
    }

    constructor(private _caeModelProviderService: CaeModelProviderService) {
        this._caeModelProviderService.getModel$().subscribe(caeModel => {
            if (caeModel) {
                this._shaftSystem = caeModel.shaftSystem;
            }
        });
    }

    public getModes(viewConfig: NaturalModeViewConfigInterface): NaturalMode[] {
        return this._getNaturalModesData(this._shaftSystem, viewConfig);
    }

    private _getNaturalModesData(shaftSystem: ShaftSystem, viewConfig: NaturalModeViewConfigInterface): NaturalMode[] {
        const naturaModes: NaturalMode[] = [];
        shaftSystem.shafts.forEach(shaft => {
            if (viewConfig.shaftId === null || shaft.id === viewConfig.shaftId) {
                const naturalMode = this._getNaturalModeForShaft(shaft, viewConfig);
                if (naturalMode) {
                    naturaModes.push(naturalMode);
                }
            }
        });
        return naturaModes;
    }

    private _getNaturalModeForShaft(shaft: Shaft, viewConfig: NaturalModeViewConfigInterface): NaturalMode | null {
        const naturalFrequencyModeShapes = shaft.loadContainer.dynamicSimulationData.naturalFrequency.modeShapes;
        const modeShape = naturalFrequencyModeShapes.find(modeShape => viewConfig.modeShapeIds.includes(modeShape.id));

        if (modeShape) {
            const frequency = modeShape.frequency;
            const deformations = this._getDeformations(modeShape, viewConfig);
            const coRotational = deformations[0].z < 0 ? false : true;
            return {
                frequency: frequency,
                shaftId: shaft.id,
                deformations: deformations,
                coRotational: coRotational,
                clockwise: !coRotational,
            };
        } else {
            return null;
        }
    }

    private _getDeformations(naturalModeShape: ModeShape, viewConfig: NaturalModeViewConfigInterface): Vector3[] {
        const deformations: Vector3[] = [];
        const maxRadialValue = this._getMaxRadialValue(naturalModeShape, viewConfig);
        naturalModeShape.sections.forEach(section => {
            const xStart = this._getXStart(section, viewConfig);
            const yReal = this._getYReal(section, viewConfig);
            const yImag = this._getYImag(section, viewConfig);
            const zReal = this._getZReal(section, viewConfig);
            const zImag = this._getZImag(section, viewConfig);
            const complY: ComplexNumber = new ComplexNumber(yReal, yImag);
            const complZ: ComplexNumber = new ComplexNumber(zReal, zImag);
            const complPhase = complZ.abs() !== 0 ? complY.divide(complZ).arg() : 0;
            const radialValue = Math.sqrt(Math.pow(yReal, 2) + Math.pow(zReal, 2));
            const radialValueScalled = this._getScaleFactor(this.shaftSystem.middleDiameter, maxRadialValue) * radialValue;
            deformations.push(new Vector3(xStart, radialValueScalled, complPhase));
        });
        return deformations;
    }

    private _getXStart(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (
            viewConfig.parameterToVisualize === Params.Displacement ||
            viewConfig.parameterToVisualize === Params.Inclination ||
            viewConfig.parameterToVisualize === Params.Thrust ||
            viewConfig.parameterToVisualize === Params.Torsional
        ) {
            return modeShape.x;
        } else {
            return 0;
        }
    }

    private _getYReal(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wy_real;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiy_real;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsy_real;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msy_real;
        } else {
            return 0;
        }
    }

    private _getYImag(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wy_imag;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiy_imag;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsy_imag;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msy_imag;
        } else {
            return 0;
        }
    }

    private _getZReal(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wz_real;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiz_real;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsz_real;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msz_real;
        } else {
            return 0;
        }
    }

    private _getZImag(modeShape: ModeShapeSection, viewConfig: NaturalModeViewConfigInterface): number {
        if (viewConfig.parameterToVisualize === Params.Displacement) {
            return modeShape.wz_imag;
        } else if (viewConfig.parameterToVisualize === Params.Inclination) {
            return modeShape.phiz_imag;
        } else if (viewConfig.parameterToVisualize === Params.Thrust) {
            return modeShape.fsz_imag;
        } else if (viewConfig.parameterToVisualize === Params.Torsional) {
            return modeShape.msz_imag;
        } else {
            return 0;
        }
    }

    private _getMaxRadialValue(modeShape: ModeShape, viewConfig: NaturalModeViewConfigInterface): number {
        const radialValues: number[] = [];
        modeShape.sections.forEach(section => {
            if (section instanceof ModeShapeSection) {
                const yReal = this._getYReal(section, viewConfig);
                const zReal = this._getZReal(section, viewConfig);
                const radialValue = Math.sqrt(Math.pow(yReal, 2) + Math.pow(zReal, 2));
                radialValues.push(radialValue);
            }
        });
        return Math.max(...radialValues.map(val => Math.abs(val)));
    }

    private _getScaleFactor(maxOuterDiameter: number, maxRadialValue: number) {
        return ((maxOuterDiameter / maxRadialValue) * DEFAULT_RADIAL_SCALE_FACTOR) / Math.PI;
    }
}
