import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { BasicMediator } from '../basic-mediator';

// mediator, used for the convergence of bearings (analog to BearingData in bearinx-online)
export abstract class SupportMediator extends BasicMediator {
    constructor() {
        super();
        this._createSupportBinder();
    }

    private _createSupportBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'idSupportedObjectInner', right: 'IDSU_SUPPORTED_OBJECT', dimension: 0 },
            { left: 'idSupportedObjectOuter', right: 'IDSU_SUPPORTED_OBJECT', dimension: 1 },
        ];
        this.addBinder(binder);
    }
}
