import { ModelElement } from '../../../cae-model/model-element';
import { InternalStep } from '../../../cae-model/notches/internal-step';
import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';
import { NotchMediator } from './notch-mediator';

// mediator, used for the convergence of internal steps
export class InternalStepMediator extends NotchMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_INTERNAL_STEP'];
    }

    protected createModelElement(): ModelElement {
        return new InternalStep();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'radius', right: 'IDN_SHOULDER_RADIUS' }];
        this.addBinder(binder);
    }
}
