import { ModelElement } from '../../../../cae-model/model-element';
import { AngularSphericalPlain } from '../../../../cae-model/supports/sliding-bearings/angular-spherical-plain';
import { BearinxStandardBinder } from '../../../binders/bearinx-standard-binder';
import { SlidingBearingMediator } from './sliding-bearing-mediator';

export class AngularSphericalPlainMediator extends SlidingBearingMediator {
    constructor() {
        super();
        this._createAngularSphericalPlainBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_ANGULAR_SPHERICAL_PLAIN_BEARING'];
    }

    private _createAngularSphericalPlainBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'iDir', right: 'IDL_INSTALLATION_DIRECTION' }];
        this.addBinder(binder);
    }

    protected createModelElement(): ModelElement {
        return new AngularSphericalPlain();
    }
}
