import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Group } from 'three';

/**
 *  3D View
 */
@Injectable()
export class View3DService {
    private _modelGroupSubj = new BehaviorSubject<Group | null>(null);
    private _selectionBoxGroupSubj = new BehaviorSubject<Group | null>(null);

    constructor() {}

    getModelGroup(): Observable<Group | null> {
        return this._modelGroupSubj;
    }

    updateModelGroup(group: Group): void {
        this._modelGroupSubj.next(group);
    }

    getSelectionBoxGroup(): Observable<Group | null> {
        return this._selectionBoxGroupSubj;
    }

    updateSelectionBoxGroup(selectionBoxGroup: Group): void {
        this._selectionBoxGroupSubj.next(selectionBoxGroup);
    }

    destroy(): void {
        this._modelGroupSubj.next(null);
        this._selectionBoxGroupSubj.next(null);
    }
}
