import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { TitleDirective } from './title.directive';
import { SimpleTooltipComponent } from './simple-tooltip/simple-tooltip.component';
import { SimpleTooltipDirective } from './simple-tooltip.directive';

const DECLARATIONS = [TitleDirective, SimpleTooltipDirective, SimpleTooltipComponent];

@NgModule({
    imports: [OverlayModule],
    declarations: [...DECLARATIONS],
    exports: [...DECLARATIONS],
})
export class TooltipModule {}
