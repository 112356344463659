import { Injectable } from '@angular/core';

import { Model } from './model.model';
import { ApiModelObject, ModelObject } from './model-object.model';

@Injectable({
    providedIn: 'root',
})
export class ModelTransformer {
    public api2local(apiModel: Model<ApiModelObject>): Model<ModelObject> {
        const object = this.api2localObject(apiModel.rootObject);
        return Object.assign({}, apiModel, { rootObject: object });
    }

    public local2api(model: Model<ModelObject>): Model<ApiModelObject> {
        const object = this.local2apiObject(model.rootObject);
        return Object.assign({}, model, { rootObject: object });
    }

    public api2localObject(root: ApiModelObject): ModelObject {
        const idProperty = root.properties.find(property => property.name === 'IDCO_IDENTIFICATION');

        if (!idProperty) {
            throw new Error('Cannot find Object Id Property');
        }

        return {
            id: idProperty.value as string,
            type: root.type,
            subTypes: root.subTypes,
            children: root.children.map(child => this.api2localObject(child)),
            childList: root.childList,
            properties: root.properties,
            visualizationData: root.visualizationData,
        };
    }

    public local2apiObject(root: ModelObject): ApiModelObject {
        return {
            type: root.type,
            subTypes: root.subTypes,
            children: root.children.map(child => this.local2apiObject(child)),
            childList: root.childList,
            properties: root.properties,
            visualizationData: root.visualizationData,
        };
    }
}
