import Konva from 'konva';
import { BACKGROUND_COLOR_2D, CIRCLE_DOT_NAME, DOT_RADIUS, STROKE_WIDTH } from '../elements-view/view-2d-constants';

export class CircleDot {
    private _group: Konva.Group;
    private _circle: Konva.Circle;
    private _dot: Konva.Circle;

    constructor(stroke: string, radius: number, positionX = 0, unitScaleFactor: number) {
        this._createShapes(stroke, radius, positionX, unitScaleFactor);
    }

    get group(): Konva.Group {
        return this._group;
    }

    public updateShapes(stroke: string, radius: number, positionX: number): void {
        [this._circle, this._dot].forEach(circle => {
            circle.x(positionX);
            circle.stroke(stroke);
        });

        this._circle.radius(radius);
    }

    private _createShapes(stroke: string, radius: number, positionX = 0, unitScaleFactor: number): void {
        this._group = new Konva.Group({
            name: CIRCLE_DOT_NAME,
        });

        this._circle = this._createCircle(BACKGROUND_COLOR_2D);
        this._dot = this._createCircle(stroke);
        this._dot.radius(DOT_RADIUS / unitScaleFactor);

        this.updateShapes(stroke, radius, positionX);

        this._group.add(this._circle);
        this._group.add(this._dot);
    }

    private _createCircle(fillColor: string): Konva.Circle {
        const circle = new Konva.Circle();
        circle.fill(fillColor);
        circle.strokeWidth(STROKE_WIDTH);
        circle.strokeScaleEnabled(false);
        return circle;
    }
}
