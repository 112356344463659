/* eslint-disable sonarjs/cognitive-complexity */
import { RadialTaperedRoller } from '../../cae-model/supports/bearings/radial-tapered-roller';
import { RigidSupport } from '../../cae-model/supports/rigid-support';
import { Bearing } from '../../cae-model/supports/bearings/bearing';
import { Spring } from '../../cae-model/supports/spring';
import { Shaft } from '../../cae-model/shaft';
import { createDefaultSupportGeometry, SupportGeometry, SupportGeometryColor } from '../geometries/support-geometry';
import { Support } from '../../cae-model/supports/support';
import { ModelElement } from '../../cae-model/model-element';
import { Injectable } from '@angular/core';
import { setDirectionalSupportGeometry } from '../support-geometry-setters/set-directional-support-geometry';
import { setBearingGeometry } from '../support-geometry-setters/set-bearing-geometry';
import { setRadialTaperedRollerGeometry } from '../support-geometry-setters/set-radial-tapered-roller-geometry';
import { TINY_DIAMETER } from '../views-foundation-constants';
import { SlidingBearing } from '../../cae-model/supports/sliding-bearings/sliding-bearing';

export interface SupportGeometryFactoryInput {
    findModelElement(elementId: string): ModelElement;
}

@Injectable()
export class SupportGeometryFactory {
    private _geometry: SupportGeometry;
    private _factoryInput: SupportGeometryFactoryInput;
    private _support: Support;

    // calculateGeometry in SupportData
    createSupportGeometry(factoryInput: SupportGeometryFactoryInput, support: Support, unitScale: number): SupportGeometry {
        this._support = support;
        this._factoryInput = factoryInput;

        this._geometry = createDefaultSupportGeometry();
        this._geometry.sX = this._support.x;

        this._calculateShaftDiameters(unitScale);
        this._updateGeometry(unitScale);
        this._setColor();
        return this._geometry;
    }

    private _calculateShaftDiameters(unitScale: number): void {
        const geometry = this._geometry;
        geometry.outerShaftDiameter = geometry.innerShaftDiameter = 0.0;

        const supportedObject2 = this._getOuterShaft();
        const supportedObject1 = this._getInnerShaft();

        geometry.outerSupported = supportedObject2 != null;
        geometry.innerSupported = supportedObject1 != null;

        const tinyDiameter = TINY_DIAMETER / unitScale;

        if (supportedObject1 === supportedObject2 && supportedObject1) {
            geometry.outerShaftDiameter = geometry.innerShaftDiameter = this._getOuterDiameter(supportedObject1);
        } else if (supportedObject1 != null && supportedObject2 != null) {
            geometry.outerShaftDiameter = this._getOuterDiameter(supportedObject1);

            geometry.innerShaftDiameter = this._getInnerDiameter(supportedObject2);

            if (geometry.outerShaftDiameter > tinyDiameter && geometry.innerShaftDiameter > tinyDiameter) {
                /// ok, nothing to do
            } else if (geometry.innerShaftDiameter < tinyDiameter) {
                geometry.innerShaftDiameter = this._getOuterDiameter(supportedObject2);

                if (geometry.innerShaftDiameter > tinyDiameter) {
                    geometry.outerShaftDiameter = this._getInnerDiameter(supportedObject1);
                    if (geometry.outerShaftDiameter < tinyDiameter) {
                        geometry.outerShaftDiameter = geometry.innerShaftDiameter;
                    }
                }
            } else {
                geometry.outerShaftDiameter = 0.002 / unitScale;
            }
        } else if (supportedObject1 != null) {
            geometry.outerShaftDiameter = this._getOuterDiameter(supportedObject1);
        } else if (supportedObject2 != null) {
            geometry.innerShaftDiameter = this._getInnerDiameter(supportedObject2);
            if (geometry.innerShaftDiameter < tinyDiameter) {
                geometry.outerShaftDiameter = this._getOuterDiameter(supportedObject2);
            } else {
                geometry.outerShaftDiameter = 0.002 / unitScale;
            }
        }
    }

    private _setColor() {
        const geometry = this._geometry;
        geometry.color = SupportGeometryColor.UNDIFINED;

        if (geometry.outerSupported || geometry.innerSupported) {
            if (geometry.outerShaftDiameter > 0.002 && geometry.innerShaftDiameter > 0.002) {
                if (geometry.outerShaftDiameter < geometry.innerShaftDiameter) {
                    geometry.color = SupportGeometryColor.DISPLAYGREEN;
                } else {
                    geometry.color = SupportGeometryColor.DISPLAYBRIGHTGREEN;
                }
            } else {
                const outerOk = !geometry.outerSupported || (geometry.outerSupported && geometry.innerShaftDiameter > 0.002);
                const innerOk = !geometry.innerSupported || (geometry.innerSupported && geometry.outerShaftDiameter > 0.002);
                if (outerOk && innerOk) {
                    geometry.color = SupportGeometryColor.DISPLAYGREEN;
                }
            }
        }
    }

    private _getInnerDiameter(shaft: Shaft): number {
        return this._getDiameter(shaft, false);
    }

    private _getOuterDiameter(shaft: Shaft): number {
        return this._getDiameter(shaft, true);
    }

    private _getDiameter(shaft: Shaft, isOuter: boolean): number {
        if (shaft == null) {
            return 0;
        }
        const xCoorInShaftCS = this._support.x - shaft.x;
        const radius = isOuter ? shaft.getCurrentOuterRadius(xCoorInShaftCS) : shaft.getCurrentInnerRadius(xCoorInShaftCS);
        return radius * 2;
    }

    private _getOuterShaft(): Shaft {
        const modelElement = this._factoryInput.findModelElement(this._support.idSupportedObjectOuter);
        return modelElement as Shaft;
    }

    private _getInnerShaft(): Shaft {
        const modelElement = this._factoryInput.findModelElement(this._support.idSupportedObjectInner);
        return modelElement as Shaft;
    }

    private _updateGeometry(unitScale: number) {
        if (this._support instanceof Spring || this._support instanceof RigidSupport || this._support instanceof SlidingBearing) {
            setDirectionalSupportGeometry(this._support, this._geometry, unitScale);
        }
        if (this._support instanceof Bearing) {
            setBearingGeometry(this._support, this._geometry, unitScale);
        }
        if (this._support instanceof RadialTaperedRoller) {
            setRadialTaperedRollerGeometry(this._support, this._geometry);
        }
    }
}
