import { BearinxStandardBinder } from '../../binders/bearinx-standard-binder';

export function createDirectionalSupportBinder(): BearinxStandardBinder {
    const binder = new BearinxStandardBinder();
    binder.properties = [
        { left: 'outerDiameter', right: 'IDSU_DA' },
        { left: 'innerDiameter', right: 'IDSU_DI' },
        { left: 'width', right: 'IDSU_B' },
        { left: 'DirXTrans', right: 'IDSU_DIRECTION_X_TRANSLATORY' },
        { left: 'DirYTrans', right: 'IDSU_DIRECTION_Y_TRANSLATORY' },
        { left: 'DirZTrans', right: 'IDSU_DIRECTION_Z_TRANSLATORY' },
        { left: 'DirYRot', right: 'IDSU_DIRECTION_Y_ROTATORY' },
        { left: 'DirZRot', right: 'IDSU_DIRECTION_Z_ROTATORY' },
    ];
    return binder;
}
