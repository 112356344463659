import { BufferGeometry, Material, MathUtils, Mesh } from 'three';
import { createGearGeometry } from '../../functions/create-gear-geometry';
import {
    resolveOverlapDisplayOrCreateDefaultBufferGeometry,
    getOpeningAngle,
    getStartAngle,
    getUnitSetScaleValue,
} from '../../functions/utils-3d';
import { GearParameters } from '../../interfaces/gear.parameters';
import { ClipMode, TransmissionElementView3D } from './transmission-element-view-3d';
import { TransmissionElement } from '../../../cae-model/transmission-elements/transmission-element';

export abstract class SpurGearBaseView3D extends TransmissionElementView3D {
    constructor(spurGear: TransmissionElement) {
        super(spurGear);
        this.clipMode = ClipMode.ClipOnPlane;
    }

    protected abstract updateGearGeometryParameters(): void;
    protected abstract get diameter(): number;
    protected abstract get pitchDiameter(): number;
    protected abstract get helixAngleReferenceDiameter(): number;
    protected abstract get faceWidth(): number;
    protected abstract get isOuterGear(): boolean;

    protected setMesh(geometry: BufferGeometry, material: Material): Mesh {
        return new Mesh(geometry, material);
    }

    protected get clipMeshEnabled(): boolean {
        return true;
    }

    private _createGearParameters(): GearParameters {
        const view3dSetting = this.input.settings;
        const unitScale = getUnitSetScaleValue(this.input.settings.unitSet);
        const teethCount = Math.min(Math.max(Math.round(this.pitchDiameter * 0.6 * unitScale), 25), 300);
        const normalModule = this.pitchDiameter / teethCount;
        const helixAngleReferenceDiameter = -MathUtils.degToRad(this.helixAngleReferenceDiameter);
        return {
            faceWidth: this.faceWidth,
            helixAngleReferenceDiameter: helixAngleReferenceDiameter,
            normalModule: normalModule,
            numberOfTeeth: teethCount,
            diameter: this.diameter,
            startAngle: getStartAngle(view3dSetting),
            openingAngle: getOpeningAngle(view3dSetting.clippingType),
            isRingGear: this.isOuterGear,
            clippingType: this.input.settings.clippingType,
            clippingPlaneType: this.input.settings.clippingPlane,
            flipClippingPlane: this.input.settings.flipClippingPlane,
            offsetDiameter: 0,
        };
    }

    protected setBufferGeometry(): BufferGeometry {
        this.updateGearGeometryParameters();
        const gearParameters = this._createGearParameters();
        const gearGeometry = createGearGeometry(gearParameters);
        return resolveOverlapDisplayOrCreateDefaultBufferGeometry(this.input.settings, gearGeometry);
    }
}
