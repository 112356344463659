import { share } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HotkeyService } from './hotkey.service';

@Injectable({ providedIn: 'root' })
export class GlobalHotkeyService {
    public readonly search$: Observable<void>;
    private readonly _searchSubject: Subject<void> = new Subject();

    constructor(private readonly _hotkeyService: HotkeyService) {
        this.search$ = this._searchSubject.pipe(share());
    }

    public initialize(): void {
        this._hotkeyService.register('ctrl+space').subscribe(() => this._searchSubject.next());
    }
}
