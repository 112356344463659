import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationAreaComponent } from './notification-area.component';
import { NotificationComponent } from './notification.component';

@NgModule({
    imports: [CommonModule, TranslateModule.forChild()],
    declarations: [NotificationAreaComponent, NotificationComponent],
    exports: [NotificationAreaComponent, NotificationComponent],
})
export class NotificationModule {}
