import { RadialBall } from './../../../../cae-model/supports/bearings/radial-ball';
import { BearingMediator } from './bearing-mediator';
import { ModelElement } from '../../../../cae-model/model-element';
import { BearinxStandardBinder } from '../../../binders/bearinx-standard-binder';

// mediator, used for the convergence of RadialBall
export class RadialBallMediator extends BearingMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_RADIAL_BALL_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new RadialBall();
    }

    private _createBinder(): void {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'idlWithPreloadSpring', right: 'IDL_WITH_PRELOAD_SPRING' },
            { left: 'idlPreloadRing', right: 'IDL_PRELOADED_RING' },
            { left: 'idlPreloadDirection', right: 'IDL_PRELOAD_DIRECTION' },
        ];
        this.addBinder(binder);
    }
}
