import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Vg2ExportData } from './vg2.export.data';
import { Observable } from 'rxjs';
import { DataProviderService } from './data.provider.service';
import { ExportOptions } from './export.options';
import { BaseExporter } from './base.exporter';
import { ExportDataType } from './data-types/export.data.type';
import { ExportData } from './data-types/export.data';

@Injectable({
    providedIn: 'root',
    deps: [DataProviderService],
})
export class Vg2ModelExporter extends BaseExporter {
    /**
     * Identifier for VG2 format
     */
    static readonly vg2: string = 'vg2';

    /**
     *  C'tor
     * @param dataProviderService DataProviderService instance
     * @param datePipe Date pipe instance
     */
    constructor(dataProviderService: DataProviderService, datePipe: DatePipe) {
        super(dataProviderService, datePipe, Vg2ModelExporter.vg2, Vg2ModelExporter._createExportOptions());
    }

    private static _createExportOptions(): ExportOptions[] {
        return <ExportOptions[]>[];
    }

    /**
     * Returns the default extension of the uncompressed export file
     * @returns Default extension without dot
     */
    protected getExtension(): string {
        return Vg2ModelExporter.vg2;
    }

    /**
     * Creates the export data from given data
     * @param data Data to be exported
     * @returns A single data object or a list of data objects with name to be stored in a ZIP file
     */
    protected createExportData(data: any): Observable<ExportDataType> {
        return new Observable<any>(observer => {
            const exportData = <Vg2ExportData>data;
            if (exportData && exportData.data && exportData.data.type) {
                const vg2Data = exportData.data;
                const mimeType = vg2Data.type;
                const filename = exportData.name;

                observer.next(<ExportData>{ fileName: filename, data: vg2Data, mimeType: mimeType });
                observer.complete();
            }

            // TODO: User error message
            observer.error('No data provided!');
            observer.complete();
        });
    }
}
