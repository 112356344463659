/**
 * Support
 */

import { ModelElement } from '../model-element';

export class Support extends ModelElement {
    idSupportedObjectInner = '';
    idSupportedObjectOuter = '';
    constructor() {
        super();
    }
}
