import { ShaftSegment } from './shaft-segment';

export class ConeSegment extends ShaftSegment {
    // ConeSegmentData in bx-online

    private _outerDiameterLeft = 0;
    private _innerDiameterLeft = 0;
    private _outerDiameterRight = 0;
    private _innerDiameterRight = 0;

    get outerDiameterLeft(): number {
        return this._outerDiameterLeft;
    }

    set outerDiameterLeft(val: number) {
        this._outerDiameterLeft = Math.max(val, this._innerDiameterLeft);
    }

    get innerDiameterLeft(): number {
        return this._innerDiameterLeft;
    }

    set innerDiameterLeft(val: number) {
        this._innerDiameterLeft = Math.max(val, 0);
    }

    get outerDiameterRight(): number {
        return this._outerDiameterRight;
    }

    set outerDiameterRight(val: number) {
        this._outerDiameterRight = Math.max(val, this._innerDiameterRight);
    }

    get innerDiameterRight(): number {
        return this._innerDiameterRight;
    }

    set innerDiameterRight(val: number) {
        this._innerDiameterRight = Math.max(val, 0);
    }

    protected doGetCurrentOuterRadius(x: number): number {
        const leftCoef = (x - this.x) / this.length;
        const diameter = this.outerDiameterLeft * (1 - leftCoef) + this.outerDiameterRight * leftCoef;
        return diameter / 2;
    }

    protected doGetCurrentInnerRadius(x: number): number {
        const leftCoef = (x - this.x) / this.length;
        const diameter = this.innerDiameterLeft * (1 - leftCoef) + this.innerDiameterRight * leftCoef;
        return diameter / 2;
    }
}
