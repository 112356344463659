import { ModelElement } from '../../cae-model/model-element';
import { CaeModelProviderService } from '../../views-foundation/services/cae-model-provider.service';
import { Injectable } from '@angular/core';
import { hierarchicalFindFirst } from '../../bearinx-mediator/functions/utils';

@Injectable()
export class ModelElementFinder {
    constructor(private _caeModelProviderService: CaeModelProviderService) {}

    findElement(id: string): ModelElement {
        const model = this._caeModelProviderService.getModelValue();
        if (model == null) {
            throw new Error('[ModelElementFinder]: _model is required');
        }
        const hasId = (node: any) => node.id === id;
        const nodeFound = hierarchicalFindFirst([model], hasId);
        return nodeFound as ModelElement;
    }
}
