<nav>
    <section class="bx__nav-group" *ngIf="model$ | async as model">
        <header>
            <h1>
                {{ 'SHARED.NAVIGATION.CURRENT_MODEL' | translate }}
            </h1>
        </header>
        <ul>
            <li>
                <button (click)="closeModel()">
                    <i class="material-icons">close</i>
                    <span>{{ 'GLOBALS.CLOSE' | translate }}</span>
                </button>
            </li>
            <li>
                <button (click)="go('editor', model.tenantId, model.groupId, model.id, 'metadata')">
                    <i class="material-icons">border_color</i>
                    <span> {{ 'SHARED.NAVIGATION.EDIT_METADATA' | translate }}</span>
                </button>
            </li>
            <li *bxIfRoles="[Roles.ModelRead]">
                <button (click)="go('editor', model.tenantId, model.groupId, model.id, 'copy')">
                    <i class="material-icons">content_copy</i>
                    <span>{{ 'GLOBALS.COPY' | translate }}</span>
                </button>
            </li>
            <li *bxIfRoles="['model:export:*']">
                <button (click)="go('editor', model.tenantId, model.groupId, model.id, 'export')">
                    <i class="material-icons">vertical_align_bottom</i>
                    <span>{{ 'GLOBALS.EXPORT' | translate }}</span>
                </button>
            </li>
            <li *bxIfRoles="[Roles.ModelDelete]">
                <button (click)="go('editor', model.tenantId, model.groupId, model.id, 'delete')">
                    <i class="material-icons">delete</i>
                    <span>{{ 'GLOBALS.DELETE' | translate }}</span>
                </button>
            </li>
            <li>
                <button (click)="shareModel()">
                    <i class="material-icons">share</i>
                    <span>{{ 'GLOBALS.SHARE' | translate }}</span>
                </button>
            </li>
        </ul>
    </section>
    <section class="bx__nav-group">
        <header>
            <h1>
                {{ 'SHARED.NAVIGATION.GENERAL' | translate }}
            </h1>
        </header>
        <ul>
            <li>
                <button (click)="go('/')">
                    <i class="material-icons">home</i>
                    <span>{{ 'GLOBALS.HOME' | translate }}</span>
                </button>
            </li>
            <li *bxIfRoles="[Roles.ModelWrite]">
                <button (click)="go('/models/new')">
                    <i class="material-icons">add</i>
                    <span>{{ 'GLOBALS.NEW' | translate }}</span>
                </button>
            </li>
            <li *bxIfRoles="[Roles.ProjectWrite]">
                <button (click)="go('/project/create')">
                    <i class="material-icons">add</i>
                    <span>{{ 'GLOBALS.NEW_PROJECT' | translate }}</span>
                </button>
            </li>
            <li *bxIfRoles="[Roles.ModelWrite]">
                <button (click)="go('/models/import')">
                    <i class="material-icons">vertical_align_top</i>
                    <span>{{ 'GLOBALS.IMPORT' | translate }}</span>
                </button>
            </li>
            <li>
                <button (click)="go('/models/library')">
                    <i class="material-icons">folder_open</i>
                    <span>{{ 'GLOBALS.OPEN' | translate }}</span>
                </button>
            </li>
            <li>
                <button (click)="go('/settings')">
                    <i class="material-icons">settings</i>
                    <span>{{ 'SHARED.NAVIGATION.SETTINGS' | translate }}</span>
                </button>
            </li>
            <li>
                <button (click)="showAbout()">
                    <i class="material-icons">info</i>
                    <span>{{ 'SHARED.NAVIGATION.ABOUT' | translate }}</span>
                </button>
            </li>
            <li>
                <button (click)="showImprint()">
                    <i class="material-icons">info</i>
                    <span>{{ 'SHARED.NAVIGATION.IMPRINT' | translate }}</span>
                </button>
            </li>
            <li class="cookie-settings">
                <!-- OneTrust Cookies Settings button start -->
                <i class="material-icons">info</i>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">
                    <span>{{ 'SHARED.NAVIGATION.DATA_PRIVACY' | translate }}</span>
                </button>
                <!-- OneTrust Cookies Settings button end -->
            </li>
            <li>
                <button (click)="logout()">
                    <i class="material-icons">
                        exit_to_app
                    </i>
                    <span>{{ 'SHARED.NAVIGATION.LOGOUT' | translate }}</span>
                </button>
            </li>
        </ul>
    </section>
    <section class="bx__nav-group" *ngIf="(bookmarks$ | async)?.length > 0">
        <header>
            <h1>
                {{ 'SHARED.NAVIGATION.BOOKMARKS' | translate }}
            </h1>
        </header>
        <bx-bookmark-list></bx-bookmark-list>
    </section>
</nav>
<bx-user></bx-user>
