import { Component, forwardRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Project } from '../project.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'bx-project-form',
    templateUrl: 'project-form.component.html',
    styleUrls: ['project-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => ProjectFormComponent),
            multi: true,
        },
    ],
})
export class ProjectFormComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @Input() model: Project;
    @Input() cancelLabel: string;
    @Input() hideButtons: boolean;

    public form: FormGroup;
    public name = new FormControl('', [Validators.required, Validators.minLength(1)]);

    private _onTouch = () => {};
    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _fb: FormBuilder) {}

    public ngOnInit() {
        this.form = this._fb.group({
            name: this.name,
        });

        if (this.model) {
            this.form.patchValue(this.model, { emitEvent: false });
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    registerOnChange(fn: (value: any) => void) {
        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(fn);
    }

    registerOnTouched(_fn: any): void {
        this._onTouch = _fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable();
        } else {
            this._enableForm();
        }
    }

    writeValue(obj: Project): void {
        this.model = obj;
        this.ngOnInit();
    }

    private _enableForm() {
        this.form.enable();
    }

    emitTouched() {
        this._onTouch();
        if (this.model) {
            this.model.name = this.form.value.name;
        }
    }
}
