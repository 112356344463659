import { BEARING_POSITION_REFERENCE, RadialTaperedRoller } from '../../cae-model/supports/bearings/radial-tapered-roller';
import { SupportGeometry } from '../geometries/support-geometry';

export function setRadialTaperedRollerGeometry(roller: RadialTaperedRoller, geometry: SupportGeometry): void {
    switch (roller.positionReference) {
        case BEARING_POSITION_REFERENCE.LB_INNER_RING: {
            if (!geometry.iDir) {
                geometry.sX = roller.xLockingSurface + geometry.sB / 2.0;
            } else {
                geometry.sX = roller.xLockingSurface - geometry.sB / 2.0;
            }
            break;
        }
        case BEARING_POSITION_REFERENCE.LB_OUTER_RING: {
            if (!geometry.iDir) {
                geometry.sX = roller.xLockingSurface - geometry.sB / 2.0;
            } else {
                geometry.sX = roller.xLockingSurface + geometry.sB / 2.0;
            }
            break;
        }
    }
}
