import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DrawerModule } from '../../../modules/drawer/drawer.module';
import { NavigationModule } from '../../../modules/navigation/navigation.module';
import { NotificationModule } from '../../../modules/notification/notification.module';
import { SearchModule } from '../../../modules/search/search.module';
import { TitleBarModule } from '../../../modules/title-bar/title-bar.module';
import { TooltipModule } from '../../../modules/tooltip/tooltip.module';
import { AppBarComponent } from './app-bar.component';
import { AppContainerComponent } from './app-container.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        TitleBarModule,
        SearchModule,
        NotificationModule,
        NavigationModule,
        DrawerModule,
        RouterModule,
        TooltipModule,
    ],
    declarations: [AppContainerComponent, AppBarComponent],
})
export class AppContainerModule {}
