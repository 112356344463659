import { BearinxClipboardWrapper } from './bearinx-clipboard.wrapper';
import { Injectable } from '@angular/core';
import { concat, fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { PromptResult } from '../prompt/prompt.model';
import { PromptService } from '../prompt/prompt.service';
import { TextPrompt } from '../prompt/text.prompt';

const BX_CLIPBOARD_ACTION = 'bx-model-action';
const BX_CLIPBOARD_ACTION_SEPARATOR = '::';

export enum BearinxClipboardAction {
    ObjectCopy = 'object-copy',
    ObjectCut = 'object-cut',
}

export interface BearinxClipboardContent<T = unknown> {
    action: BearinxClipboardAction;
    payload: T;
}

@Injectable({ providedIn: 'root' })
export class BearinxClipboardService {
    private skipTutorial = false;
    private clipboardWrapper = new BearinxClipboardWrapper();

    private readonly copy$ = new Subject<void>();

    constructor(private prompts: PromptService) {}

    permissionTest(options?: { showTutorial?: boolean }): void {
        const { showTutorial } = { showTutorial: false, ...(options ?? {}) };
        this.clipboardWrapper.permissionTest().catch(() => {
            console.warn('[Clipboard] No permission.');

            if (showTutorial && !this.skipTutorial) {
                const prompt$ = this.prompts.displayPrompt(TextPrompt, false, {
                    titleKey: 'PROMPTS.COPY.TITLE',
                    textKey: 'PROMPTS.COPY.NO_PERMISSION',
                    buttons: [
                        { captionKey: 'GLOBALS.DONT_ASK', position: 'left', color: 'secondary', result: PromptResult.Confirm },
                        { captionKey: 'GLOBALS.OK', position: 'right', color: 'primary', result: PromptResult.Decline },
                    ],
                });

                prompt$.toPromise().then(result => {
                    if (result === PromptResult.Confirm) {
                        this.skipTutorial = true;
                    }
                });
            }
        });
    }

    copy(action: BearinxClipboardAction, payload: object): void {
        this.writeToClipboard(this.encodeClipboardAction(action, payload));
    }

    read<T = unknown>(actions?: BearinxClipboardAction[], silent = false): Observable<BearinxClipboardContent<T> | null> {
        return this.clipboardWrapper.readText(silent).pipe(
            map(clipboard => this.decodeClipboardAction<T>(clipboard, silent)),
            map(clipboard => (actions && clipboard && actions.includes(clipboard.action) ? clipboard : null)),
        );
    }

    watch<T = unknown>(actions?: BearinxClipboardAction[]): Observable<BearinxClipboardContent<T> | null> {
        const copy$ = fromEvent(window, 'copy');
        const cut$ = fromEvent(window, 'cut');
        const focus$ = fromEvent(window, 'focus');
        const visible$ = fromEvent(window, 'visibilitychange');

        const read$ = this.read<T>(actions, true);

        return concat(read$, merge(copy$, cut$, focus$, visible$, this.copy$)).pipe(exhaustMap(() => read$));
    }

    hasBearinxAction(): Observable<boolean> {
        return this.clipboardWrapper.readText(true).pipe(
            map(clipboard => this.isBearinxAction(clipboard)),
            catchError(() => of(false)),
        );
    }

    clear(): void {
        this.writeToClipboard('');
    }

    private writeToClipboard(text: string): void {
        this.clipboardWrapper.writeText(text);
        this.copy$.next();
    }

    private isBearinxAction(clipboard: string): boolean {
        return clipboard.substring(0, BX_CLIPBOARD_ACTION.length) !== BX_CLIPBOARD_ACTION;
    }

    private encodeClipboardAction(action: BearinxClipboardAction, payload: object): string {
        const sep = BX_CLIPBOARD_ACTION_SEPARATOR;
        return `${BX_CLIPBOARD_ACTION}${sep}${action}${sep}${JSON.stringify(payload)}`;
    }

    private decodeClipboardAction<T = unknown>(clipboard: string, silent = false): BearinxClipboardContent<T> | null {
        if (this.isBearinxAction(clipboard)) {
            return null;
        }

        const [_, rawAction, rawPayload] = clipboard.split(BX_CLIPBOARD_ACTION_SEPARATOR, 3);
        const action = rawAction as BearinxClipboardAction;
        if (!Object.values(BearinxClipboardAction).includes(action) || !rawPayload) {
            return null;
        }

        let payload: T;
        try {
            payload = JSON.parse(rawPayload);
        } catch (_) {
            if (!silent) {
                console.warn('Could not restore object from Clipboard');
            }
            return null;
        }

        return { action, payload };
    }
}
