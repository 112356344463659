import { Box3, Object3D, OrthographicCamera, Vector2, Vector3 } from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { getAllCornerPointFromBoundingBox, getProjectionPointOnPlane } from './utils-3d';
import { View3DCamera } from '../services/camera.service';
import { ZOOM_ORTHOGRAPHIC_OFFSET } from '../settings/view-3d-constants';

function getDirectionVector(pointA: Vector3, pointB: Vector3): Vector3 {
    const directionVector: Vector3 = new Vector3();
    directionVector.subVectors(pointA, pointB).normalize();

    return directionVector;
}

function getDelta(directionalVector: Vector3, rootPoint: Vector3, originalPoint: Vector3): number {
    return (
        (directionalVector.x * (originalPoint.x - rootPoint.x) +
            directionalVector.y * (originalPoint.y - rootPoint.y) +
            directionalVector.z * (originalPoint.z - rootPoint.z)) /
        (Math.pow(directionalVector.x, 2) + Math.pow(directionalVector.y, 2) + Math.pow(directionalVector.z, 2))
    );
}

function getProjectionPointOnOy(directionalVector: Vector3, rootPoint: Vector3, originalPoint: Vector3): Vector3 {
    const delta: number = getDelta(directionalVector, rootPoint, originalPoint);

    return new Vector3(
        rootPoint.x + directionalVector.x * delta,
        rootPoint.y + directionalVector.y * delta,
        rootPoint.z + directionalVector.z * delta,
    );
}

function getMaxPoint(camera: View3DCamera, projectionPoints: Vector3[]): Vector2 {
    const cameraUp = camera.up.clone().normalize();
    const cameraPosition = camera.position;

    // find the max position (positive value)
    // and because camera position is root origin of the new Plane Oxy so Min Point is opsite (negative value)
    const maxPoint = new Vector2(-Infinity, -Infinity);
    projectionPoints.forEach((point: Vector3) => {
        const projectedPointOnOy: Vector3 = getProjectionPointOnOy(cameraUp, cameraPosition, point);
        const newPoint: Vector2 = new Vector2(point.distanceTo(projectedPointOnOy), cameraPosition.distanceTo(projectedPointOnOy));
        maxPoint.max(newPoint);
    });

    return maxPoint;
}

function getCameraZoom(camera: OrthographicCamera, projectionPoints: Vector3[]): number {
    const maxPoint = getMaxPoint(camera, projectionPoints);

    return Math.min(Math.abs(camera.left) / (2 * maxPoint.x), Math.abs(camera.top) / (2 * maxPoint.y)) * 2 * ZOOM_ORTHOGRAPHIC_OFFSET;
}

// TODO: This function is not working perfectly in any angle, we need to fix it
export function zoomToFitForOrthographicCamera(camera: OrthographicCamera, controls: TrackballControls, object3D: Object3D) {
    const cameraPosition: Vector3 = camera.position;
    const controlsTarget: Vector3 = controls.target;

    const directionVector: Vector3 = getDirectionVector(cameraPosition, controlsTarget);

    // get the viewport plane
    // here is the function of the camera plane:
    // f(x,y,z): directionVector.x * (x - cameraPosition.x) + directionVector.y * (y - cameraPosition.y) +
    //  directionVector.z * (z - cameraPosition.z) === 0;

    // get the bounding box of object
    const bbox = new Box3();
    bbox.setFromObject(object3D);

    const center: Vector3 = new Vector3();
    bbox.getCenter(center);

    const target: Vector3 = new Vector3(center.x, center.y, center.z);
    controls.target.copy(target);

    const newCameraPosition: Vector3 = getProjectionPointOnPlane(target, cameraPosition, directionVector);
    camera.position.copy(newCameraPosition);

    // get the projection points of bouding box points on the viewport plane
    const projectionPoints: Vector3[] = getAllCornerPointFromBoundingBox(bbox).map<Vector3>((originPoint: Vector3) =>
        getProjectionPointOnPlane(originPoint, cameraPosition, directionVector),
    );

    camera.zoom = getCameraZoom(camera, projectionPoints);
}
