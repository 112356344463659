import { NgModule } from '@angular/core';
import { AuthModule } from '../auth/auth.module';
import { GroupSelectorComponent } from './group-selector/group-selector.component';
import { FormControlModule } from '../form-control/form-control.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { GroupNamePipe } from './group-name.pipe';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GroupInterceptor } from './group.interceptor';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, AuthModule, FormControlModule, TranslateModule],
    declarations: [GroupSelectorComponent, GroupNamePipe],
    providers: [{ provide: HTTP_INTERCEPTORS, multi: true, useClass: GroupInterceptor }],
    exports: [GroupNamePipe, GroupSelectorComponent],
})
export class GroupModule {}
