import { Injectable, OnDestroy } from '@angular/core';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PromptResult } from '../prompt/prompt.model';
import { PromptService } from '../prompt/prompt.service';
import { WindowRef } from '../util/window-ref';
import { ModelMismatchPrompt } from './model-mismatch/model-mismatch.prompt';
import { TextPrompt } from '../prompt/text.prompt';

@Injectable({ providedIn: 'root' })
export class ModelHashService implements OnDestroy {
    private _hash: string | null = null;
    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _windowRef: WindowRef, private readonly _promptService: PromptService) {}

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    public get hash(): string | null {
        return this._hash;
    }

    public set hash(value: string | null) {
        this._hash = value;
    }

    public reload(): void {
        this._promptService
            .displayPrompt(ModelMismatchPrompt, true)
            .pipe(
                switchMap(result => {
                    if (result === PromptResult.Confirm) {
                        this._windowRef.nativeWindow.location.reload();
                    }
                    return of(null);
                }),
                takeUntil(this._destroy$),
            )
            .subscribe();
        this.hash = null;
    }

    public showSlowInternetMessage() {
        this._promptService
            .displayPrompt(TextPrompt, false, {
                titleKey: 'PROMPTS.SLOW_INTERNET_CONNECTION.TITLE',
                textKey: 'PROMPTS.SLOW_INTERNET_CONNECTION.TEXT',
                buttons: [{ captionKey: 'GLOBALS.OK', position: 'right', color: 'primary', result: PromptResult.Cancel }],
            })
            .subscribe(result => {
                if (result === PromptResult.Cancel) {
                    this._promptService.close();
                }
            });
    }
}
