import { UserLevelModule } from './../userlevel/userlevel.module';
import { NgModule } from '@angular/core';
import { UserFullNamePipe } from './user-full-name.pipe';
import { UserComponent } from './user/user.component';
import { CommonModule } from '@angular/common';
import { TenantModule } from '../tenant/tenant.module';
import { AuthModule } from '../auth/auth.module';
import { GroupModule } from '../group/group.module';

@NgModule({
    imports: [CommonModule, TenantModule, AuthModule, GroupModule, UserLevelModule],
    declarations: [UserComponent, UserFullNamePipe],
    exports: [UserComponent, UserFullNamePipe],
})
export class UserModule {}
