/* eslint-disable max-len */
import { WMFImage } from './wmf-image';

export class WMF_RadialSphericalPlainBearing extends WMFImage {
    constructor() {
        super(1001, 1001);
    }

    protected doPaint() {
        const gc = this.gc;

        gc.moveTo(0, 0);
        gc.lineTo(999, 0);
        gc.lineTo(999, 999);
        gc.lineTo(0, 999);
        gc.lineTo(0, 0);

        gc.moveTo(0, 350);
        gc.lineTo(89, 336);
        gc.lineTo(291, 309);
        gc.lineTo(499, 299);
        gc.lineTo(707, 309);
        gc.lineTo(909, 336);
        gc.lineTo(999, 350);
    }
}
