import { BearinxBinder } from './bearinx-binder';
import { BindingProperties, BindingType } from './binding-properties';

export class ParametersDataBinder extends BearinxBinder {
    constructor() {
        super();
        this.leftSetter = this._setLeftProperty;
    }

    private _setLeftProperty(prop: BindingProperties): void {
        if (prop.bindingType === BindingType.ToRight) {
            return;
        }
        this.proveLeftProperty(prop.left);
        const rightValue = this.objectRight[prop.right];
        this.objectLeft[prop.left] = rightValue;
    }
}
