import Konva from 'konva';
import {
    BACKGROUND_COLOR_2D,
    CIRCLE_TIMES_NAME,
    CIRCLE_NAME,
    FIRST_CROSS_LINE,
    SECOND_CROSS_LINE,
} from '../elements-view/view-2d-constants';
import { CircleTimesInput2DInterface } from './circle-times-input-2d-interface';

export class CircleTimes {
    private _group: Konva.Group;
    private _circle: Konva.Circle;
    private _firstCrossover: Konva.Line;
    private _secondCrossover: Konva.Line;

    constructor(circleTimesInput: CircleTimesInput2DInterface) {
        this._group = this._createShapes(circleTimesInput);
    }

    get group(): Konva.Group {
        return this._group;
    }

    public updateShapes(circleTimesInput: CircleTimesInput2DInterface) {
        const { stroke, strokeWidth, opacity, radius, positionX } = circleTimesInput;

        [this._circle, this._firstCrossover, this._secondCrossover].forEach(shapeItem => {
            shapeItem.x(positionX || 0);
            shapeItem.stroke(stroke);
            shapeItem.strokeWidth(strokeWidth);
            shapeItem.strokeScaleEnabled(false);
            shapeItem.opacity(opacity);
        });

        this._circle.radius(radius);

        const y = Math.sin(45 * (Math.PI / 180)) * radius;
        this._firstCrossover.points([-y, -y, y, y]);
        this._secondCrossover.points([y, -y, -y, y]);
    }

    private _createShapes(circleTimesInput: CircleTimesInput2DInterface): Konva.Group {
        const group = new Konva.Group();
        group.name(`${circleTimesInput.name ? circleTimesInput.name : CIRCLE_TIMES_NAME}`);

        this._circle = this._createCircle();
        this._firstCrossover = new Konva.Line({ name: FIRST_CROSS_LINE });
        this._secondCrossover = new Konva.Line({ name: SECOND_CROSS_LINE });

        this.updateShapes(circleTimesInput);

        group.add(this._circle);
        group.add(this._firstCrossover);
        group.add(this._secondCrossover);

        return group;
    }

    private _createCircle(): Konva.Circle {
        const circle = new Konva.Circle();
        circle.name(CIRCLE_NAME);
        circle.fill(BACKGROUND_COLOR_2D);
        circle.strokeScaleEnabled(false);
        return circle;
    }
}
