import { ShaftSystem } from '../../cae-model/shaft-system';
import { CoordinateSystem } from '../primitives/coordinate-system';
import { SymmetryLine } from '../primitives/symmetry-line';
import { ElementView2D } from './element-view-2d';
import { COORDINATE_SYSTEM_STROKE_COLOR_2D, OPACITY_SHAFT_SYSTEM_CS } from './view-2d-constants';

export class ShaftSystemView2D extends ElementView2D {
    constructor(shaftSystem: ShaftSystem) {
        super(shaftSystem);
    }

    protected get draggable() {
        return false;
    }

    protected get isStandardSelector() {
        return true;
    }

    protected updateGroupWithoutChildren(): void {
        this.groupWithoutChildren.add(new SymmetryLine().group);
        this.groupWithoutChildren.add(
            new CoordinateSystem(COORDINATE_SYSTEM_STROKE_COLOR_2D, OPACITY_SHAFT_SYSTEM_CS, this.input.unitScaleFactor).group,
        );
        this.groupWithoutChildren.moveToTop();
    }
}
