import { RadialRoller } from './../../../../cae-model/supports/bearings/radial-roller';
import { BearingMediator } from './bearing-mediator';
import { ModelElement } from '../../../../cae-model/model-element';
import { BearinxStandardBinder } from '../../../binders/bearinx-standard-binder';

// mediator, used for the convergence of RadialRoller
export class RadialRollerMediator extends BearingMediator {
    constructor() {
        super();
        this._createBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_RADIAL_ROLLER_BEARING'];
    }

    protected createModelElement(): ModelElement {
        return new RadialRoller();
    }

    private _createBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [{ left: 'shoulderArrangement', right: 'IDL_SHOULDER_ARRANGEMENT' }];
        this.addBinder(binder);
    }
}
