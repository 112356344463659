import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '../icon/icon.module';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { ToastComponent } from './toast/toast.component';

@NgModule({
    imports: [TranslateModule, CommonModule, IconModule, OverlayModule],
    exports: [ToastContainerComponent, ToastComponent],
    declarations: [ToastContainerComponent, ToastComponent],
})
export class ToastModule {}
