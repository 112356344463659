export enum ModelErrorType {
    WrongId,
    WrongTenantId,
    WrongGroupId,
}

export class ModelError extends Error {
    constructor(public type: ModelErrorType) {
        super();
    }
}
