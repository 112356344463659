import { Component } from '@angular/core';

@Component({
    selector: 'bx-server-error',
    templateUrl: './server-error.component.html',
    styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent {
    reload() {
        window.location.reload();
    }
}
