/* eslint-disable no-shadow */
export enum BindingType {
    BothSides,
    ToLeft,
    ToRight,
}
export interface BindingProperties {
    left: string;
    right: string;
    bindingType?: BindingType;
    dimension?: number;
}
