import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';

export class ErrorHandlerBaseService {
    handleError(error: any): Observable<any> {
        if (error instanceof HttpErrorResponse) {
            return this._handleServerSideError(error);
        } else if (error.error instanceof ErrorEvent) {
            return this._handleClientSideError(error);
        }
        return this._handleOtherError(error);
    }

    protected _handleServerSideError(error: HttpErrorResponse): Observable<any> {
        return throwError(error);
    }

    protected _handleClientSideError(error: ErrorEvent): Observable<any> {
        return throwError(error);
    }

    protected _handleOtherError(_: any): Observable<any> {
        return EMPTY;
    }
}
