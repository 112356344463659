import { IRect } from 'konva/lib/types';
import { ElementView2D } from '../../elements-view/element-view-2d';
import { Dimensions2D } from '../dimensions-2d';
import { HorizontalDimensionsInterface, VerticalDimensionsInterface } from '../dimensions-utils';

export abstract class StandardNotchDimensions2D extends Dimensions2D {
    constructor(elementView2D: ElementView2D, modelViewClientRect: IRect, fractionDigits: number) {
        super(elementView2D, modelViewClientRect, fractionDigits);
    }

    protected abstract getNotchWidth(): number;

    protected doCreateHorizontalDimensionsInputs(): HorizontalDimensionsInterface[] {
        const width = this.getNotchWidth();
        const elementRect = this.getGroupWithoutChildrenClientRect();
        const objectRadius = elementRect.y + elementRect.height;

        const leftPart: HorizontalDimensionsInterface = {
            objectRadius,
            objectPositionX: this.getCoordinatesAbsX(),
        };

        const rightPart: HorizontalDimensionsInterface = {
            objectRadius,
            objectPositionX: this.getCoordinatesAbsX() + width,
        };
        return [leftPart, rightPart];
    }

    protected doCreateVerticalDimensionsInput(): VerticalDimensionsInterface[] {
        return [];
    }
}
