import Konva from 'konva';
import { ElementView2D } from '../element-view-2d';
import { PointLoadForce } from '../../primitives/point-load-force';
import { PointLoadTorque } from '../../primitives/point-load-torque';
import { PointLoad } from '../../../cae-model/loads/point-load';
import { createLoadDashLineFullLength } from '../../functions/loads-utils';
import { BOUNDING_BOX, TRANSPARENT } from '../view-2d-constants';

export class PointLoadView2D extends ElementView2D {
    private _group: Konva.Group;

    constructor(private _pointLoad: PointLoad) {
        super(_pointLoad);
    }

    protected get draggable() {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    private _createShapes(pointLoad: PointLoad): Konva.Group {
        const group = new Konva.Group({
            name: 'point-load-view-2d-group',
            transformable: true,
        });
        group.add(createLoadDashLineFullLength(pointLoad.getCurrentDiameter(pointLoad.x) / 2));

        if (this._isForceExist(pointLoad)) {
            group.add(new PointLoadForce(pointLoad, this.input.unitScaleFactor).group);
        }
        if (this._isTorqueExist(pointLoad)) {
            group.add(new PointLoadTorque(pointLoad, this.input.unitScaleFactor).group);
        }

        return group;
    }

    protected updateGroupWithoutChildren(): void {
        // override basis class

        this._group = this._createShapes(this._pointLoad);

        const boundingRectForUTSelection = new Konva.Rect({
            ...this._group.getClientRect({
                skipStroke: true,
            }),
            fill: TRANSPARENT,
            name: BOUNDING_BOX,
        });
        this._group.add(boundingRectForUTSelection);

        this.groupWithoutChildren.add(this._group);
    }

    private _isForceExist(load: PointLoad): boolean {
        return load.forceX !== 0 || load.forceY !== 0 || load.forceZ !== 0;
    }

    private _isTorqueExist(load: PointLoad): boolean {
        return load.torqueX !== 0 || load.torqueY !== 0 || load.torqueZ !== 0;
    }
}
