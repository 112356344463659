import { Group, Mesh } from 'three';
import { ModelElement } from '../../cae-model/model-element';
import { ElementView } from '../../views-foundation/element-view';
import { getUnitSetScaleValue } from '../functions/utils-3d';
import { clearObject } from '../functions/utils-3d';
import { createPreselectedSelectionBox, createSelectedSelectionBox } from '../helper/selection-box';
import { ElementView3DInput } from '../settings';
import {
    PRESELECTED_SELECTION_BOX,
    SELECTED_SELECTION_BOX,
    USERDATA_ELEMENT_3D_SELECTABLE,
    USERDATA_MODEL_ELEMENT_ID,
} from '../settings/view-3d-constants';
import { takeUntil } from 'rxjs/operators';

export abstract class ElementView3D extends ElementView {
    private _groupWithChildren: Group;
    private _selectionBoxGroup: Group;
    private _children: ElementView3D[];
    protected groupWithoutChildren: Group;
    protected groupCreated = false; // should be protected, elseway get error
    protected input: ElementView3DInput;
    protected mesh: Mesh;
    private _isSelected = false;
    private _isPreselected = false;

    constructor(modelElement: ModelElement) {
        super(modelElement);
        this._children = [];
        this._createGroups();
        this._setPosition();
    }

    get children(): ElementView3D[] {
        return this._children;
    }

    get groupWithChildren(): Group {
        return this._groupWithChildren;
    }

    get selectionBoxGroup(): Group {
        return this._selectionBoxGroup;
    }

    getGroupWithoutChildren(): Readonly<Group> {
        return this.groupWithoutChildren;
    }

    get selectableGroup(): Group | null {
        return this.groupWithoutChildren;
    }

    setSelectableProperty() {
        const selectableGroup = this.selectableGroup;
        if (selectableGroup == null) {
            return;
        }
        selectableGroup.userData[USERDATA_ELEMENT_3D_SELECTABLE] = true;
        selectableGroup.userData[USERDATA_MODEL_ELEMENT_ID] = this.modelElement.id;
    }

    createGroupWithChildren(input: ElementView3DInput): Group {
        this.groupCreated = true;
        this.input = input;
        this.updateGroups();

        return this.groupWithChildren;
    }

    updateWhenAllViewsGenerated() {
        this.input
            .getSelectedElement()
            .pipe(takeUntil(this.destroy$))
            .subscribe(selectedInterface => {
                this._updateIsSelected(selectedInterface.elementID);
            });
        this.input
            .getPreselectedElement()
            .pipe(takeUntil(this.destroy$))
            .subscribe(selectedInterface => this._updateIsPreselected(selectedInterface.elementID));
    }

    add(child: ElementView3D) {
        this._children.push(child);
        this.doAdd(child);
    }

    protected onDestroy(): void {
        if (this.mesh != null) {
            clearObject(this.mesh);
        }
        clearObject(this.groupWithChildren);
        clearObject(this.groupWithoutChildren);

        this.destroy$.next();
        this.destroy$.complete();
    }

    protected updateGroups(): void {
        // override basis class
        if (!this.groupCreated) {
            return;
        }
        this.updateGroupWithoutChildren();
        this._setPosition();
    }

    protected abstract updateGroupWithoutChildren(): void;

    private _createGroups() {
        this._groupWithChildren = new Group();
        this._groupWithChildren.name = this.modelElement.name;
        this.groupWithoutChildren = new Group();
        this.groupWithoutChildren.name = this.modelElement.name + ':groupWithoutChildren';
        this._groupWithChildren.add(this.groupWithoutChildren);
        this._selectionBoxGroup = new Group();
        this._selectionBoxGroup.name = this.modelElement.name + ':selectionBoxGroup';
    }

    private _setPosition() {
        const relCoordinates = this.modelElement.coordinatesRel;
        this._groupWithChildren.position.x = relCoordinates.x;
        this._groupWithChildren.position.y = relCoordinates.y;
        this._groupWithChildren.position.z = relCoordinates.z;
    }

    protected doAdd(_child: ElementView3D) {}

    private _updateIsSelected(selectedElementId: string): void {
        const isSelected = this.modelElement && this.modelElement.id === selectedElementId;
        if (isSelected === this._isSelected) {
            return; // nothing to do
        }
        this._isSelected = isSelected;

        this._clearSelectionBox([SELECTED_SELECTION_BOX, PRESELECTED_SELECTION_BOX]);

        if (this._isSelected) {
            const maxAnchorSize = this._maxAnchorSize;
            if (maxAnchorSize > 0) {
                this.selectionBoxGroup.add(
                    createSelectedSelectionBox(
                        this.groupWithChildren,
                        maxAnchorSize,
                        this.input.getModelMaxDimension(),
                        getUnitSetScaleValue(this.input.settings.unitSet),
                    ),
                );
            }
        }
    }

    private _clearSelectionBox(selectionBoxNames: string[]): void {
        selectionBoxNames.forEach((selectionBoxName: string) => {
            const selectionBox = this.selectionBoxGroup.getObjectByName(selectionBoxName);
            if (selectionBox != null) {
                this.selectionBoxGroup.remove(selectionBox);
            }
        });
    }

    private _updateIsPreselected(preselectedElementId: string): void {
        const isPreselected = this.modelElement && this.modelElement.id === preselectedElementId;
        if (isPreselected === this._isPreselected || this._isSelected) {
            return; // nothing to do
        }
        this._isPreselected = isPreselected;

        this._clearSelectionBox([PRESELECTED_SELECTION_BOX]);

        if (this._isPreselected) {
            const maxAnchorSize = this._maxAnchorSize;
            if (maxAnchorSize > 0) {
                this.selectionBoxGroup.add(
                    createPreselectedSelectionBox(
                        this.groupWithChildren,
                        maxAnchorSize,
                        this.input.getModelMaxDimension(),
                        getUnitSetScaleValue(this.input.settings.unitSet),
                    ),
                );
            }
        }
    }

    private get _maxAnchorSize(): number {
        return this.input.getModelMaxDimension() * 5 * 0.01;
    }
}
