import { Vector2, Vector3 } from 'three';
import { ClippingPlaneType, ClippingType } from '../settings/view-3d-settings';

export enum CuttingPlaneType {
    Up,
    Down,
}

export enum VType {
    vEnd,
    vStart,
}

export interface CuttingPlaneInputBase {
    width: number;
    offsetDiameter: number;
    clippingPlaneType: ClippingPlaneType;
    flipClippingPlane: boolean;
}

export interface CuttingPlaneInput extends CuttingPlaneInputBase {
    radiusInner: number;
    radiusOuter: number;
    angle: number;
    materialIndex: number;
    cuttingPlaneType: CuttingPlaneType;
}

export interface CuttingPlanesInput extends CuttingPlaneInputBase {
    clippingType: ClippingType;
    di: number;
    da: number;
    openingAngleRad: number;
}

export interface ExtrudeToothProfileInput {
    profiles: Array<Array<number>>;
    width: number;
    rotation: number;
    steps: number;
    materialIndex: number;
    halfToothProfile: Array<Array<number>>;
}

export interface GearTorsoInput {
    radiusBottom: number;
    radiusTop: number;
    helixAngle: number;
    vTextureStart: number;
    vTextureEnd: number;
    materialIndex: number;
    rotation: number;
    slices: number;
}

export interface DataOfGear {
    slices: number;
    isRingGear: boolean;
    openingAngleRad: number;
    nPoints: number;
    x: number;
    alpha: number;
    dk: number;
    df: number;
    dt: number;
    s: number;
    da: number;
    halfToothProfile: Array<Array<number>>;
    vStart: number;
    vEnd: number;
}

interface FaceBaseInput {
    top: boolean;
}

interface GenerateFaceBaseInput {
    innerRadius: number;
    outerRadius: number;
    uvScale: number;
    halfToothProfile: Array<Array<number>>;
}

export interface GenerateFaceInput extends FaceBaseInput, GenerateFaceBaseInput {
    materialIndex: number;
    rotation: number;
}

export interface FacePropertiesBaseInput extends FaceBaseInput {
    phiSegments: number;
    thetaSegments: number;
}

export interface FacePropertiesInput extends FacePropertiesBaseInput {
    rotation: number;
    faceWidth: number;
    vertex: Vector3;
    radius: number;
    uv: Vector2;
    verticesIdx: Array<number>;
    uvScale: number;
    vertices: number[];
    radiusStep: number;
}

export interface FaceIndicesInput extends FacePropertiesBaseInput {
    idxOffset: number;
    groupCount: number;
}

export interface GenerateFacesInput extends GenerateFaceBaseInput {
    rotX: number;
    offsetDiameter: number;
}

export interface SegmentData {
    a: number;
    b: number;
    c: number;
    d: number;
}

export interface VIdxInterface {
    vIdx: number;
    vIdxPre: number;
    vIdxPost: number;
}

export interface ToothWidthInterface {
    dy: number;
    s: number;
    d: number;
    alpha: number;
    betaRad: number;
}

export interface ToothProfileInterface {
    isRingGear: boolean;
    nPoints: number;
    df: number;
    dk: number;
    vStart: number;
    vEnd: number;
    s: number;
    dt: number;
    alpha: number;
    helixAngleReferenceDiameter: number;
    numberOfTeeth: number;
    halfToothProfile: number[][];
}
