import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ToastData } from './toast.model';
import { BehaviorSubject, asyncScheduler } from 'rxjs';
import { groupBy } from '../util/util';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private _counter = 0;
    private _toasts: ToastData[] = [];

    private readonly _toasts$ = new BehaviorSubject<ToastData[]>(this._toasts);
    public readonly toasts$ = this._toasts$.asObservable();

    public readonly groupedToasts$ = this.toasts$.pipe(
        map(toasts => groupBy(toasts, toast => toast.groupId!)),
        map(groups =>
            Object.keys(groups)
                .map(key => groups[key])
                .reduce(
                    (acc, toasts) => [
                        ...acc,
                        {
                            ...toasts[0],
                            times: toasts.length > 1 ? toasts.length : undefined,
                        },
                    ],
                    [],
                ),
        ),
    );

    public static _createGroupId(toast: ToastData): string {
        return `${toast.icon}.${toast.label}.${toast.closeAutomatically || false}`;
    }

    private _createId(): number {
        return ++this._counter;
    }

    public show(toast: ToastData): ToastData {
        const toastData = {
            closeAutomatically: true,
            ...toast,
            id: this._createId(),
            groupId: ToastService._createGroupId(toast),
        };

        if (toastData.closeAutomatically) {
            asyncScheduler.schedule(() => this.hide(toastData.id), 15000);
        }

        this._toasts.push(toastData);
        this._toasts$.next(this._toasts);

        return toastData;
    }

    public hide(itemId: string | number) {
        let property: 'id' | 'groupId' | undefined;

        if (typeof itemId === 'number') {
            property = 'id';
        } else if (typeof itemId === 'string') {
            property = 'groupId';
        }

        if (property) {
            this._toasts = this._toasts.filter(toast => toast[property!] !== itemId);
            this._toasts$.next(this._toasts);
        }
    }

    public hideAll(): void {
        this._toasts = [];
        this._toasts$.next(this._toasts);
    }
}
