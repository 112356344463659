<div class="user-content" [class.open]="expanded">
    <div class="header-bar" (click)="expand()" *ngIf="user$ | async as user">
        <figure class="user-image">
            <img *ngIf="user.picture; else noAvatar" [attr.src]="user.picture" [attr.alt]="user | userFullName" />
        </figure>
        <header>
            <h1>
                <a>{{ user | userFullName }}</a>
            </h1>
            <h2>{{ user.company }}</h2>
        </header>
        <button class="expand">
            <i *ngIf="!expanded" class="material-icons">keyboard_arrow_up</i>
            <i *ngIf="expanded" class="material-icons">keyboard_arrow_down</i>
        </button>
    </div>

    <div class="details">
        <bx-tenant-selector [disabled]="disabled$ | async"></bx-tenant-selector>
        <bx-group-selector [disabled]="disabled$ | async"></bx-group-selector>
        <bx-userlevel></bx-userlevel>
    </div>
</div>

<ng-template #noAvatar>
    <i class="material-icons">
        account_circle
    </i>
</ng-template>
