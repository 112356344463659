/* eslint-disable no-shadow */
import { Support } from '../../../cae-model/supports/support';
import { SupportGeometry } from '../../../views-foundation/geometries/support-geometry';
import { STROKE_COLOR_2D, STROKE_WIDTH } from '../view-2d-constants';
import { ElementView2D, PartType } from '../element-view-2d';
import { createBoundingBox, getColor, getSupportDimensions } from '../../functions/utils-2d';
import { Arc } from 'konva/lib/shapes/Arc';
import { Rect } from 'konva/lib/shapes/Rect';
import { Group } from 'konva/lib/Group';
import { Line } from 'konva/lib/shapes/Line';
import { SupportViewInput } from '../../../views-foundation/interfaces/support-view-input-interface';

enum AxType {
    Neg,
    Pos,
    IsNotInnerDiameter,
    IsNotOuterDiameter,
}

export abstract class SupportView2D extends ElementView2D {
    private _supportGroup: Group | null = null;
    public supportViewInput: SupportViewInput;
    protected supportGeometry: SupportGeometry;

    protected abstract getName(): string;

    protected get hasBorder(): boolean {
        return true;
    }

    protected get isStandardSelector() {
        return true;
    }

    protected get draggable() {
        return true;
    }

    protected abstract paintElement(group: Group, geometry: SupportGeometry): void;

    protected updateGroupWithoutChildren(): void {
        this.setSupportGeometry();

        this._supportGroup?.destroy();
        this._supportGroup = this._createShapes();
        this._supportGroup.add(this.paintCommonSupport(this.supportGeometry));
        this._supportGroup.add(createBoundingBox(this._supportGroup));

        this.groupWithoutChildren.add(this._supportGroup);
    }

    protected setSupportGeometry() {
        this.supportGeometry = this.supportViewInput.createGeometry(this._support, this.input.unitScaleFactor);
    }

    private get _support(): Support {
        return this.modelElement as Support;
    }

    protected paintCommonSupport(geometry: SupportGeometry): Group {
        const group: Group = new Group();
        const { sDI, sDO, sX, sB, sAxNeg, sAxPos, sRad, sRot, outerSupported, innerSupported, color } = geometry;
        const innerDiameterFactory: number = sDI / 2.0;
        const outerDiameterFactory: number = sDO / 2.0;
        const width: number = sX + sB / 2.0 - (sX - sB / 2.0);
        const innerOuterGap: number = sDO - sDI;

        this.paintElement(group, geometry);

        if (this.hasBorder) {
            const outerRect = new Rect();
            const innerRect = new Rect();
            [outerRect, innerRect].forEach(rect => {
                rect.width(sB);
                rect.height(innerOuterGap / 2.0);
                rect.stroke(getColor(color));
                rect.strokeWidth(STROKE_WIDTH);
                rect.strokeScaleEnabled(false);
                rect.name('border-support');
                group.add(rect);
            });
            outerRect.y(-outerDiameterFactory);
            innerRect.y(innerDiameterFactory);
        }

        let lines: number[][] = [];

        const axInputs: { width: number; axType: AxType }[] = [];
        if (sAxNeg) {
            axInputs.push({
                width: 0,
                axType: AxType.Neg,
            });
        }
        if (sAxPos) {
            axInputs.push({
                width: width,
                axType: AxType.Pos,
            });
        }
        axInputs.forEach(axInput => {
            const widthGap: number = this._getWidthGap(axInput.width, sB, axInput.axType);
            const axLines = this._getLinesDrawingData(widthGap, axInput.width, outerDiameterFactory);
            lines.push(...axLines);
        });

        if (sRad) {
            const x1 = 0;
            const x2 = width;
            const y1 = outerDiameterFactory;
            const y2 = (-sDO - innerOuterGap / 4.0) / 2.0;
            const y3 = (sDO + innerOuterGap / 4.0) / 2.0;

            lines.push([x1, -y1, x1, y2]);
            lines.push([x2, -y1, x2, y2]);
            lines.push([x2, y1, x2, y3]);
            lines.push([x1, y1, x1, y3]);
        }

        group.add(this._createLines(lines, getColor(color)));
        lines = [];

        if (sRot) {
            const outerArc = new Arc();
            const innerArc = new Arc();
            const arcWidth = sB;
            const arcHeight = innerOuterGap / 4.0;
            const arcRadius: number = Math.max(arcWidth, arcHeight) / 2;

            [outerArc, innerArc].forEach(arc => {
                arc.x(arcWidth / 2);
                arc.outerRadius(arcRadius);
                arc.innerRadius(arcRadius);
                arc.angle(180.0);
                arc.stroke(getColor(color));
                arc.strokeWidth(STROKE_WIDTH);
                arc.strokeScaleEnabled(false);
                if (arcHeight < arcWidth) {
                    arc.scaleY(arcHeight / arcWidth);
                } else if (arcHeight > arcWidth) {
                    arc.scaleX(arcWidth / arcHeight);
                }
            });
            outerArc.y(-sDO / 2.0 - innerOuterGap / 8.0 + innerOuterGap / 4.0 / 2);
            outerArc.rotation(180.0);
            group.add(outerArc);
            innerArc.y(sDO / 2.0 - innerOuterGap / 8.0 + innerOuterGap / 4.0 / 2);
            group.add(innerArc);
        }

        group.add(this._createLines(lines, getColor(color)));
        lines = [];

        const diameterFactories: number[] = [];
        if (!outerSupported) {
            diameterFactories.push(outerDiameterFactory);
        }
        if (!innerSupported) {
            diameterFactories.push(innerDiameterFactory);
        }
        diameterFactories.forEach(diameterFactory => {
            const isNotSupportedLines: number[][] = this._getLinesDrawingData(0, width, diameterFactory);
            lines.push(...isNotSupportedLines);
        });

        group.add(this._createLines(lines));

        group.x(-width / 2);

        return group;
    }

    private _createLine(linePointInput: number[], stroke: string = STROKE_COLOR_2D): Line {
        return new Line({
            points: linePointInput,
            stroke: stroke,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
        });
    }

    private _createLines(lines: number[][], color: string = STROKE_COLOR_2D): Group {
        const group = new Group();
        lines.forEach(line => {
            group.add(this._createLine(line, color));
        });
        return group;
    }

    private _getWidthGap(width: number, sB: number, axType: AxType): number {
        return axType === AxType.Neg ? width - sB / 4.0 : width + sB / 4.0;
    }

    private _getLinesDrawingData(width1: number, width2: number, diameterFactory: number): number[][] {
        const x1: number = width1;
        const x2: number = width2;
        const y: number = diameterFactory;
        const line1Points: number[] = [x1, -y, x2, -y];
        const line2Points: number[] = [x1, y, x2, y];
        return [line1Points, line2Points];
    }

    private _createShapes(): Group {
        return new Group({
            name: this.getName(),
            transformable: true,
        });
    }

    protected getSupportGroups(konvaGroup: Group, geometry: SupportGeometry, xInverse: boolean): Group[] {
        const { sDO, sDI } = geometry;
        const { supportHeight: bearingHeight, supportWidth: bearingWidth } = getSupportDimensions(geometry);
        konvaGroup.width(bearingWidth);
        konvaGroup.height(bearingHeight);

        return [PartType.Up, PartType.Down].map(bearingPartType => {
            const y = this._isSupportPartUp(bearingPartType) ? -sDO / 2 : sDI / 2;
            const group = this._isSupportPartUp(bearingPartType) ? konvaGroup : konvaGroup.clone();
            group.y(y);
            this._flipSupportGroup(group, bearingPartType, xInverse);
            return group;
        });
    }

    private _flipSupportGroup(konvaGroup: Group, partType: PartType, xInverse: boolean): void {
        if (partType !== PartType.Up) {
            konvaGroup.scaleY(-1); // Vertical flip
            konvaGroup.offsetY(konvaGroup.height());
        }
        if (xInverse) {
            konvaGroup.scaleX(-1); // Horizontal flip
            konvaGroup.offsetX(konvaGroup.width());
        }
    }

    private _isSupportPartUp(supportPartType: PartType) {
        return supportPartType === PartType.Up;
    }
}
