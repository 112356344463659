import { Injectable, OnDestroy } from '@angular/core';
import { ModelElement } from '../../cae-model/model-element';
import { ElementView2D } from '../elements-view/element-view-2d';

@Injectable()
export class ModelElementFinder2DService implements OnDestroy {
    private _viewModelMap: Map<ElementView2D, ModelElement>;
    private _modelViewMap: Map<ModelElement, ElementView2D>;

    constructor() {
        this._viewModelMap = new Map<ElementView2D, ModelElement>();
        this._modelViewMap = new Map<ModelElement, ElementView2D>();
    }

    ngOnDestroy(): void {
        this.clearViews();
    }

    addView(modelElement: ModelElement, view: ElementView2D) {
        this._viewModelMap.set(view, modelElement);
        this._modelViewMap.set(modelElement, view);
    }

    clearViews(): void {
        this._viewModelMap.clear();
        this._modelViewMap.clear();
    }

    // return the list of views of all elements
    get views(): ElementView2D[] {
        return Array.from(this._viewModelMap.keys());
    }

    getView(id: string): ElementView2D | null {
        const modelElement = Array.from(this._modelViewMap.keys()).find((modelElement: ModelElement) => modelElement.id === id);

        if (modelElement) {
            return this._modelViewMap.get(modelElement)!;
        }

        return null;
    }
}
