import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { from, throwError } from 'rxjs';
import { catchError, mergeMap, tap, timeout } from 'rxjs/operators';
import { CalculationService } from '../calculation/calculation.service';
import { PromptModule } from '../prompt/prompt.module';
import { SettingsService } from '../settings/settings.service';
import { AuthenticationService } from './authentication.service';
import { ConfirmLogoutPrompt } from './confirm-logout/confirm-logout.prompt';
import { ConfirmReLoginPrompt } from './confirm-re-login/confirm-re-login.prompt';
import { AwaitLoginPrompt } from './login-wait-blocker/await-login.prompt';

const WAIT_FOR_CONNECTION = 20_000;

export class AppInitializationError extends Error {
    constructor(message?: string) {
        super(message ?? 'App Initialization Error');
        Object.setPrototypeOf(this, AppInitializationError.prototype);
    }
}

export function AuthenticationAppInitializer(
    authService: AuthenticationService,
    calculationService: CalculationService,
    settingService: SettingsService,
): () => Promise<any> {
    return () =>
        from(authService.authenticate())
            .pipe(
                mergeMap(() => authService.setupSilentRefresh()),
                mergeMap(() => Promise.all([authService.loadUser(), settingService.prefetch()])),
                tap(() => {
                    settingService.start();
                    calculationService.start();
                }),
                timeout(WAIT_FOR_CONNECTION),
                catchError(err => throwError(new AppInitializationError(err.message ?? err))),
            )
            .toPromise();
}

@NgModule({
    imports: [CommonModule, TranslateModule, PromptModule],
    declarations: [ConfirmReLoginPrompt, AwaitLoginPrompt, ConfirmLogoutPrompt],
})
export class AuthModule {
    public static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: AuthenticationAppInitializer,
                    deps: [AuthenticationService, CalculationService, SettingsService],
                },
            ],
        };
    }
}
