import { ModelElement } from './model-element';
import { NaturalFrequency } from './natural-frequency';

export class DynamicSimulationData extends ModelElement {
    private _naturalFrequency: NaturalFrequency;

    constructor() {
        super();
        this._naturalFrequency = new NaturalFrequency();
    }

    protected doAdd(child: ModelElement) {
        if (child instanceof NaturalFrequency) {
            this._addNaturalFrequency(child);
        }
    }

    private _addNaturalFrequency(naturalFrequency: NaturalFrequency) {
        this._naturalFrequency = naturalFrequency;
    }

    public get naturalFrequency(): NaturalFrequency {
        return this._naturalFrequency;
    }

    public set naturalFrequency(naturalFrequency: NaturalFrequency) {
        this._naturalFrequency = naturalFrequency;
    }
}
