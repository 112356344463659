import Konva from 'konva';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { UGroove } from '../../../cae-model/notches/u-groove';
import { BACKGROUND_COLOR_2D, DISPLAYRED, STROKE_WIDTH } from '../view-2d-constants';
import { NotchView2D } from './notch-view-2d';
import { TINY_RADIUS } from '../../../views-foundation/views-foundation-constants';

export class UGrooveView2D extends NotchView2D {
    constructor(uGroove: UGroove) {
        super(uGroove);
    }

    private get _uGroove(): UGroove {
        return this.modelElement as UGroove;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('u-groove-view-2d-group');
    }

    protected createNotchView(): Konva.Group {
        const group = new Konva.Group();
        const { radius, xInShaftCS } = this._uGroove;
        const uGroove: Konva.Shape[] = [];
        const scale = this.input.unitScaleFactor;

        const arc = new Konva.Arc({
            y: -this.outerRadius,
            width: radius * 2.0,
            height: radius * 2.0,
            angle: 180,
            innerRadius: radius,
            outerRadius: 0,
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: BACKGROUND_COLOR_2D,
        });
        uGroove.push(arc);

        // line to erase the border line of Shaft/Arc
        const line = new Konva.Line({
            points: [STROKE_WIDTH / scale, -this.outerRadius, radius * 2.0 - STROKE_WIDTH / scale, -this.outerRadius],
            stroke: BACKGROUND_COLOR_2D,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            offsetX: radius,
        });
        uGroove.push(line);

        uGroove.forEach(part => {
            group.add(generateUpperAndLowerParts(part, 'uGroovePart'));
        });

        const innerRadius = this._uGroove.getCurrentInnerRadius(xInShaftCS);
        if (innerRadius < TINY_RADIUS) {
            [-radius, radius].forEach(x => {
                group.add(
                    new Konva.Line({
                        x: x,
                        points: [0, -this.outerRadius, 0, this.outerRadius],
                        stroke: DISPLAYRED,
                        strokeWidth: STROKE_WIDTH,
                        strokeScaleEnabled: false,
                        name: 'inner-notch-line',
                    }),
                );
            });
        }

        return group;
    }
}
