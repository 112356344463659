/**
 * Abstract class TransmissionElement
 */

import { ModelElement } from '../model-element';

export class TransmissionElement extends ModelElement {
    constructor() {
        super();
    }

    // returns the current outer diameter of the segment, corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getShaftCurrentOuterDiameter: (x: number) => number;

    // returns the current inner diameter of the segment, corresponding to this x where x is the x-coordinate in the shaft coordinate system
    getShaftCurrentInnerDiameter: (x: number) => number;
}
