import { ModelElement } from '../../cae-model/model-element';
import { BasicMediator } from './basic-mediator';
import { Unbalance } from '../../cae-model/loads/unbalance';
import { BearinxStandardBinder } from '../binders/bearinx-standard-binder';
import { BearinxLoadDependentBinder } from '../binders/bearinx-load-dependent-binder';

export class UnbalanceMediator extends BasicMediator {
    constructor() {
        super();
        this._createStandardBinder();
        this._createLoadDependentBinder();
    }

    get bearinxObjectTypes(): string[] {
        return ['IDO_OUT_OF_BALANCE'];
    }

    protected createModelElement(): ModelElement {
        return new Unbalance();
    }

    private _createStandardBinder() {
        const binder = new BearinxStandardBinder();
        binder.properties = [
            { left: 'x', right: 'IDCO_X' },
            { left: 'radialMissalignment', right: 'ID_OUT_OF_BALANCE_INPUT_RADIAL_MISALIGNMENT' },
            { left: 'axialMissalignment', right: 'ID_OUT_OF_BALANCE_INPUT_AXIAL_MISALIGNMENT' },
            { left: 'unbalanceType', right: 'ID_OUT_OF_BALANCE_INPUT_OUT_OF_BALANCE_DESCRIPTION' },
        ];
        this.addBinder(binder);
    }

    private _createLoadDependentBinder() {
        const binder = new BearinxLoadDependentBinder();
        binder.properties = [{ left: 'startAngleAboutX', right: 'ID_OUT_OF_BALANCE_INPUT_START_ANGLE' }];
        this.addBinder(binder);
    }
}
