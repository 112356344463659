import { ModelElement } from '../../cae-model/model-element';
import { ShaftSystem } from '../../cae-model/shaft-system';
import { IDO_SHAFT_SYSTEM } from '../bearinx-ids';
import { BasicMediator } from './basic-mediator';

export class ShaftSystemMediator extends BasicMediator {
    get bearinxObjectTypes(): string[] {
        return [IDO_SHAFT_SYSTEM];
    }

    protected createModelElement(): ModelElement {
        return new ShaftSystem();
    }
}
