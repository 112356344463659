import { SlewingBall } from '../../../../cae-model/supports/bearings/slewing-ball';
import { WMF_RadialBallBearing } from './wmf-images/wmf-radial-ball-bearing';
/* eslint-disable max-len */
import { SupportGeometry } from '../../../../views-foundation/geometries/support-geometry';
import { BearingView2D } from './bearing-view-2d';
import { WMFImage } from './wmf-images/wmf-image';

export class SlewingBallView2D extends BearingView2D {
    constructor(slewingBall: SlewingBall) {
        super(slewingBall);
    }

    private get _slewingBall(): SlewingBall {
        return this.modelElement as SlewingBall;
    }

    // Create image manually. Overrides basic class.
    protected createWMFImage(geometry: SupportGeometry): WMFImage {
        const image = new WMF_RadialBallBearing();
        image.withoutInnerRing_ = false;
        image.numberOfRows_ = this._slewingBall.nbRows;
        image.dy_ = (geometry.sDO - geometry.sDI) / 2;
        image.dx_ = geometry.sB;
        image.gapDistance_ = 0.2;
        return image;
    }
}
