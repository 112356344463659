import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, map, takeUntil } from 'rxjs/operators';
import { GroupService } from '../group.service';

@Component({
    selector: 'bx-group-selector',
    templateUrl: './group-selector.component.html',
    styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public disabled: boolean;

    public readonly groupCtrl = new FormControl();
    public readonly groupItems$ = this._groupService.groups$.pipe(
        delay(0),
        map(groups => groups.map(({ id, name: caption }) => ({ id, caption }))),
    );

    private readonly _destroy$ = new Subject<void>();

    constructor(private readonly _groupService: GroupService) {}

    ngOnInit(): void {
        this.groupCtrl.valueChanges
            .pipe(
                takeUntil(this._destroy$),
                map(groupId => this._groupService.getGroup(groupId)),
            )
            .subscribe(group => {
                this._groupService.setGroup(group);
            });

        this._groupService.group$.pipe(takeUntil(this._destroy$)).subscribe(group => {
            this.groupCtrl.setValue(group ? group.id : null, { emitEvent: false });
        });
    }

    public ngOnChanges(): void {
        if (this.disabled) {
            this.groupCtrl.disable();
        } else {
            this.groupCtrl.enable();
        }
    }

    public ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
