import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class StaticFileLoadersService {
    constructor(private _http: HttpClient) {}

    getSVGImageString(url: string): Observable<string> {
        const headers = new HttpHeaders();
        headers.set('Accept', 'image/svg+xml');
        return this._http.get(url, { headers, responseType: 'text' });
    }
}
