import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconPipe } from './icon.pipe';
import { IconComponent } from './icon/icon.component';

@NgModule({
    imports: [CommonModule],
    declarations: [IconComponent, IconPipe],
    exports: [IconComponent, IconPipe],
})
export class IconModule {}
