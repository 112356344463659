<div class="window" role="document" overlayDialogBoundary>
    <bx-title-bar
        ><h4 caption>{{ context.title | translate }}</h4></bx-title-bar
    >

    <bx-content-dialog></bx-content-dialog>
    <footer *ngIf="leftButtons.length || rightButtons.length">
        <div class="button-bar">
            <div class="left">
                <button *ngFor="let button of leftButtons" [ngClass]="button.cssClass" (click)="buttonClicked(button, $event)">
                    {{ button.text | translate }}
                </button>
            </div>
            <div class="right">
                <button
                    *ngFor="let button of rightButtons"
                    [ngClass]="button.cssClass"
                    (click)="buttonClicked(button, $event)"
                    [disabled]="button.disabled"
                >
                    {{ button.text | translate }}
                </button>
            </div>
        </div>
    </footer>
</div>
