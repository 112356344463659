import { BevelGear } from '../../cae-model/transmission-elements/bevel-gear';
import { InternalBevelGear } from '../../cae-model/transmission-elements/internal-bevel-gear';
import { CalculateBevelGearGeometryInterface } from '../interfaces/calculate-bevel-gear-geometry-interface';
import { STANDARD_GEAR_WIDTH, TINY_DIAMETER } from '../views-foundation-constants';

export enum BevelGearDiameterType {
    Inner,
    Outer,
}

export abstract class BevelGearBaseGeometry {
    protected abstract setGeometry(): void;

    protected _tB = 0;
    protected _tDM = 0;
    protected _tDO = 0;
    protected _tDI = 0;
    protected _tForm = '';
    protected _tTooth = 0;
    protected _tBore = 0;
    protected _isOnShaft = false;

    public get tB(): number {
        return this._tB;
    }

    public get tDM(): number {
        return this._tDM;
    }

    public get tDO(): number {
        return this._tDO;
    }

    public get tDI(): number {
        return this._tDI;
    }

    public get tForm(): string {
        return this._tForm;
    }

    public get tTooth(): number {
        return this._tTooth;
    }

    public get tBore(): number {
        return this._tBore;
    }

    public get isOnShaft(): boolean {
        return this._isOnShaft;
    }

    constructor(protected bevelGear: BevelGear | InternalBevelGear, protected unitScale: number) {
        this.setGeometry();
    }

    protected getDef(tB: number, tDI: number, tDO: number): { ddef: boolean; didef: boolean; bdef: boolean } {
        const tinyDiameter = TINY_DIAMETER / this.unitScale;
        const ddef = tDO < tinyDiameter ? true : false;
        const didef = tDI < tinyDiameter ? true : false;
        const bdef = tB < tinyDiameter ? true : false;
        return { ddef, didef, bdef };
    }

    protected calculateBevelWidthByDiameter(diameter: number): number {
        const gearWith = STANDARD_GEAR_WIDTH / this.unitScale;
        let tB = diameter < gearWith ? 0.5 * diameter : 0.25 * (diameter - gearWith) + gearWith / 2;
        if (tB > gearWith) {
            tB = gearWith;
        }
        return tB;
    }

    protected calculateBevelWidthByDelta(calculateInput: CalculateBevelGearGeometryInterface): number {
        const { tDI, tDO, delta, mediumDia, ddef, didef } = calculateInput;
        let { tB } = calculateInput;

        if (ddef === false && didef === false) {
            tB = (tDO - tDI) / (2.0 * Math.tan(delta * (Math.PI / 180.0)));
        } else {
            if (ddef === true) {
                tB = (mediumDia - tDI) / Math.tan(delta * (Math.PI / 180.0));
            }
            if (didef === true) {
                tB = (tDO - mediumDia) / Math.tan(delta * (Math.PI / 180.0));
            }
        }
        return tB;
    }

    protected calculateDelta(delta: number): number {
        const tinyDiameter = TINY_DIAMETER / this.unitScale;
        return delta < tinyDiameter ? (delta = 45.0) : delta;
    }
}
