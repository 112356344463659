import Konva from 'konva';
import { generateUpperAndLowerParts } from '../../functions/utils-2d';
import { UnderCut } from '../../../cae-model/notches/under-cut';
import { BACKGROUND_COLOR_2D, DISPLAYRED, SEGMENT_COLOR_2D, STROKE_COLOR_2D, STROKE_WIDTH, TRANSPARENT } from '../view-2d-constants';
import { NotchView2D } from './notch-view-2d';

const SIN15 = 0.2588190451025;

export class UnderCutView2D extends NotchView2D {
    constructor(underCut: UnderCut) {
        super(underCut);
    }

    private get _underCut(): UnderCut {
        return this.modelElement as UnderCut;
    }

    private get _tmpWidth2(): number {
        return this._underCut.depth / SIN15;
    }

    private get _tmpWidth(): number {
        return Math.max(this._underCut.width - this._underCut.radius - this._tmpWidth2, 0);
    }

    private get _tmpYRadius(): number {
        return -this._underCut.radius;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('under-cut-view-2d-group');
    }

    protected createNotchView(): Konva.Group {
        const group = new Konva.Group();

        const underCut: Konva.Shape[] = [
            this._createShapeToFillGapBetweenCurveAndShaft(),
            this._createdExtendedShaftBorderLine(),
            this._createCurveShape(),
            this._createTriangleTail(),
            this._createCurveRedLine(),
            this._createRedTailLine(),
        ];
        underCut.forEach(part => {
            part.y(-this.outerRadius);
            group.add(generateUpperAndLowerParts(part, part.name()));
        });

        group.add(this._createRedVerticalLine1());
        group.add(this._createRedVerticalLine2());

        return group;
    }

    private get _segmentColorConfigs(): { stroke: string; strokeWidth: number; strokeScaleEnabled: boolean; fill: string } {
        return {
            stroke: SEGMENT_COLOR_2D,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: SEGMENT_COLOR_2D,
        };
    }

    private get _backgroundColorConfigs(): { stroke: string; strokeWidth: number; strokeScaleEnabled: boolean; fill: string } {
        return {
            stroke: BACKGROUND_COLOR_2D,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            fill: BACKGROUND_COLOR_2D,
        };
    }

    private get _redStrokeConfigs(): { stroke: string; strokeWidth: number; strokeScaleEnabled: boolean } {
        return {
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
        };
    }

    private _createShapeToFillGapBetweenCurveAndShaft(): Konva.Shape {
        const { depth, radius } = this._underCut;

        return new Konva.Shape({
            ...this._segmentColorConfigs,
            strokeScaleEnabled: false,
            name: 'shape-to-fill-gap-between-curve-and-shaft',
            // Fill segment color for the gap between curve and shaft
            sceneFunc: (context, shape) => {
                context.beginPath();

                context.moveTo(0, this._tmpYRadius + depth);
                context.quadraticCurveTo(0, depth, radius + this._tmpWidth, depth);
                context.lineTo(0, depth);
                context.lineTo(0, this._tmpYRadius + depth);

                context.fillStrokeShape(shape);
            },
        });
    }

    private _createdExtendedShaftBorderLine(): Konva.Line {
        const { depth } = this._underCut;

        return new Konva.Line({
            name: 'extended-shaft-border-line',
            points: [0, depth + STROKE_WIDTH / (2.0 * this.input.unitScaleFactor), 0, this._tmpYRadius + depth],
            stroke: STROKE_COLOR_2D,
            strokeWidth: STROKE_WIDTH / 2.0,
            strokeScaleEnabled: false,
        });
    }

    private _createCurveShape(): Konva.Shape {
        const { depth, radius } = this._underCut;
        return new Konva.Shape({
            ...this._backgroundColorConfigs,
            name: 'curve-shape',
            sceneFunc: (context, shape) => {
                context.beginPath();

                context.moveTo(0, this._tmpYRadius + depth);
                context.quadraticCurveTo(0, depth, radius + this._tmpWidth, depth);
                context.lineTo(radius + this._tmpWidth, this._tmpYRadius + depth);

                context.fillStrokeShape(shape);
            },
        });
    }

    private _createTriangleTail(): Konva.Shape {
        const { depth, radius } = this._underCut;
        return new Konva.Line({
            ...this._backgroundColorConfigs,
            name: 'triangle-tail',
            points: [radius + this._tmpWidth, 0, radius + this._tmpWidth, depth, radius + this._tmpWidth + this._tmpWidth2, 0],
            closed: true,
        });
    }

    private _createCurveRedLine(): Konva.Shape {
        const { depth, radius } = this._underCut;

        return new Konva.Shape({
            ...this._redStrokeConfigs,
            fill: TRANSPARENT,
            name: 'curve-line',
            sceneFunc: (context, shape) => {
                context.beginPath();

                context.moveTo(0, this._tmpYRadius + depth);
                context.quadraticCurveTo(0, depth, radius + this._tmpWidth, depth);

                context.fillStrokeShape(shape);
            },
        });
    }

    private _createRedTailLine(): Konva.Line {
        const { depth, radius } = this._underCut;

        return new Konva.Line({
            ...this._redStrokeConfigs,
            name: 'red-tail-line',
            points: [radius + this._tmpWidth, depth, radius + this._tmpWidth + this._tmpWidth2, 0],
        });
    }

    private _createRedVerticalLine1(): Konva.Line {
        const { radius } = this._underCut;
        return new Konva.Line({
            points: [
                radius + this._tmpWidth + this._tmpWidth2,
                -this.outerRadius,
                radius + this._tmpWidth + this._tmpWidth2,
                this.outerRadius,
            ],
            strokeScaleEnabled: false,
            name: 'redVerticalLine1',
        });
    }

    private _createRedVerticalLine2(): Konva.Line {
        const { depth, radius } = this._underCut;

        return new Konva.Line({
            ...this._redStrokeConfigs,
            points: [radius + this._tmpWidth, -this.outerRadius + depth, radius + this._tmpWidth, this.outerRadius - depth],
            name: 'redVerticalLine2',
        });
    }
}
