import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HotkeyModule } from '../hotkey/hotkey.module';
import { SearchComponent } from './search.component';

@NgModule({
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, HotkeyModule],
    declarations: [SearchComponent],
    exports: [SearchComponent],
})
export class SearchModule {}
