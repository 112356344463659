export const DEFAULT_SEGMENT_DIAMETER = 25.0;
export const TINY_RADIUS = 0.0005;
export const TINY_DIAMETER = TINY_RADIUS * 2;
export const STANDARD_GEAR_WIDTH = 100.0;
export const INNER_OUTER_DIAMETER_COMPARATOR = 0.01;
export const FPS_UNIT_SCALE = 25.4;
export const SI_UNIT_SCALE = 1;

// Hammer touch event
export enum HammerTouchEvent {
    TAP = 'tap',
    PRESS = 'press',
    DOUBLE_TAP = 'doubletap',
}
