import { BACKGROUND_COLOR_2D, DASH_STROKE_NOTCH, NO_DASH, DISPLAYRED, STROKE_WIDTH } from '../view-2d-constants';
import Konva from 'konva';
import { NotchView2D } from './notch-view-2d';
import { KeySeat } from '../../../cae-model/notches/key-seat';
import { TINY_RADIUS } from '../../../views-foundation/views-foundation-constants';

export class KeySeatView2D extends NotchView2D {
    constructor(keySeat: KeySeat) {
        super(keySeat);
    }

    private get _keySeat(): KeySeat {
        return this.modelElement as KeySeat;
    }

    protected updateGroupWithoutChildren(): void {
        super.rerenderViewWithoutChildren('key-seat-view-2d-group');
    }

    protected createNotchView(): Konva.Group {
        const group = new Konva.Group();
        const { xInShaftCS, width } = this._keySeat;
        const outerRadius = this._keySeat.getCurrentOuterRadius(xInShaftCS);

        const isInnerHoleExist = this._innerRadius > TINY_RADIUS / this.input.unitScaleFactor;
        const rect = new Konva.Rect({
            x: 0,
            y: -outerRadius + this._keySeat.depth - this._keySeat.height,
            width: width,
            height: this._keySeat.height,
            stroke: DISPLAYRED,
            strokeWidth: STROKE_WIDTH,
            strokeScaleEnabled: false,
            dash: isInnerHoleExist ? NO_DASH : DASH_STROKE_NOTCH,
            fill: isInnerHoleExist ? DISPLAYRED : BACKGROUND_COLOR_2D,
            name: 'key-seat',
        });
        group.add(rect);

        return group;
    }

    get _innerRadius(): number {
        const { xInShaftCS, width } = this._keySeat;
        const innenRadiusLeft = this._keySeat.getCurrentInnerRadius(xInShaftCS);
        const innerRadiusRight = this._keySeat.getCurrentInnerRadius(xInShaftCS + width);
        return Math.max(innenRadiusLeft, innerRadiusRight);
    }
}
