import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { ModelHttpResponseTranslatorService } from './model-http-response-translator.service';
import { ErrorHandlerBaseService, ErrorMessagesService, HttpStatusCode, NotFoundResolverService } from '../../error-handling';
import { ModelError, ModelErrorType } from './model-error.model';
import { ModelErrorTranslatorService } from './model-error-translator.service';

@Injectable({
    providedIn: 'root',
})
export class ModelErrorHandlerService extends ErrorHandlerBaseService {
    constructor(
        private readonly _modelHttpResponseTranslator: ModelHttpResponseTranslatorService,
        private readonly _modelErrorTranslator: ModelErrorTranslatorService,
        private readonly _notFoundResolverService: NotFoundResolverService,
        private readonly _router: Router,
        private readonly _errorMessagesService: ErrorMessagesService,
    ) {
        super();
    }

    protected _handleServerSideError(error: HttpErrorResponse): Observable<any> {
        switch (error.status) {
            case HttpStatusCode.NotFound:
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.Forbidden:
                return this._redirectToErrorPage(error);
                break;
            case HttpStatusCode.InternalServerError:
                this._errorMessagesService.displayErrorMessage(error.error);
                return EMPTY;
                break;
        }

        return throwError(error);
    }

    protected _handleOtherError(error: any): Observable<any> {
        switch ((error as ModelError).type) {
            case ModelErrorType.WrongTenantId:
            case ModelErrorType.WrongGroupId:
            case ModelErrorType.WrongId:
                return this._redirectToErrorPage(error);
                break;
        }
        return EMPTY;
    }

    private _redirectToErrorPage(error: Error): Observable<any> {
        let message = '';
        if (error instanceof HttpErrorResponse) {
            message = this._modelHttpResponseTranslator.translate(error);
        } else if (error instanceof ModelError) {
            message = this._modelErrorTranslator.translate(error);
        }
        this._notFoundResolverService.setData({ message });
        this._router.navigate(['/not-found']);
        return EMPTY;
    }
}
